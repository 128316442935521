import React from 'react'

export default function CheckBox({ checked, onChange, label }) {
  return (
    <div
      className="mf-checkbox"
      style={{
        display: 'flex',
        alignItems: 'center',
        padding: '1px'
      }}
    >
      <div
        className="mf-checkbox-border"
        style={{
          border: '2px solid gray',
          borderRadius: '3px',
          width: '20px',
          height: '20px',
          marginRight: '8px'
        }}
        onClick={() => onChange(!checked)}
      >
        <div
          className="mf-checkbox-indicator"
          style={{
            position: 'absolute',
            // zIndex: 1,
            background: checked ? 'rgba(20, 20, 20, 0.87)' : '',
            transform: checked ? 'scale(1)' : 'scale(0)',
            transition: 'transform 100ms',
            width: '12px',
            height: '12px',
            margin: '2px'
          }}
        />
      </div>

      <div
        className="mf-checkbox-label"
        style={{
          position: 'reltive',
          top: '2px'
        }}
      >
        {label}
      </div>
    </div>
  )
}
