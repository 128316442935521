import React from 'react'

function GenericBlock(props) {
  return (
    <div>
      <div className="iron-sec-heading-wrap text-center mb-40"></div>
      <div dangerouslySetInnerHTML={{ __html: props.content.child_content.content }}></div>
    </div>
  )
}

export default GenericBlock
