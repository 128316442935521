import { FETCH_INVENTORY } from '../actions/types'

export default (state = [], action) => {
  switch (action.type) {
    case 'FETCH_STOCK_COUNT':
      return { ...state, [action.sku]: action.payload }
    case FETCH_INVENTORY:
      return { ...state, ...action.payload.data }
    case 'CLEAR_PRODUCT': {
      return []
    }
    default:
      return state
  }
}
