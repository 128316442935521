import { addressLabel } from '../../util/utils'
import { connect } from 'react-redux'
import './AddressList.css'
import List from 'devextreme-react/list'
import { Row, Col, Button } from 'reactstrap'

// Single address block
const AddressBlock = ({ address, onDelete, onEdit }) => {
  return (
    <Row>
      <Col xs={8} style={{ paddingRight: 0 }}>
        {addressLabel(address)}
      </Col>
      <Col xs={4} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
        <Button className="mf-outline-sm-btn" onClick={() => onEdit(address)}>
          Edit
        </Button>
        &nbsp;&nbsp;
        <Button className="mf-outline-danger-sm-btn" onClick={() => onDelete(address.id, 'delivery')}>
          Delete
        </Button>
      </Col>
    </Row>
  )
}

// List of addresses with edit and delete buttons
const AddressList = ({ addresses, onDelete, onEdit, currentUser }) => {
  return (
    <div style={{ padding: '.75em' }}>
      <List
        items={addresses}
        height={'auto'}
        itemRender={address => <AddressBlock address={address} onDelete={onDelete} onEdit={onEdit} />}
        noDataText="No Saved Addresses To Display"
      />
    </div>
  )
}

const mapStateToProps = state => {
  return {
    currentUser: state.currentUser,
    addresses: Object.values(state.delivery_addresses).filter(address => address.address_type === 'delivery')
  }
}

export default connect(mapStateToProps, {})(AddressList)
