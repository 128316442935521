import axios from 'axios'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'

let token = process.env.REACT_APP_PORTAL_TOKEN
let baseUrl = process.env.REACT_APP_BACKEND_API_URL

Bugsnag.start({
  apiKey: '1a18d3f5d697b88195c073171a7c9b13',
  plugins: [new BugsnagPluginReact()],
  autoDetectErrors: false,
  autoTrackSessions: false,
  appType: 'mf-store',
  enabledReleaseStages: ['production', 'staging']
})

export default axios.create({
  baseURL: baseUrl,
  headers: {
    'x-apikey': '59a7ad19f5a9fa0808f11931',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    'X-TOKEN': token,
    Accept: '*/*',
    'X-Auth-Token': localStorage.authToken || '',
    'Cache-Control': 'no-store',
    AppVersion: localStorage.appVersion || '0.0.0'
  }
})
