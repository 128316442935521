import { FETCH_SMART_DELIVERY_ADDRESSES } from '../actions/types'

const initialState = {
  epcid: null,
  data: { Doors: [], Hub: [] },
  doors: [],
  hubs: [],
  doors_hubs: []
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SMART_DELIVERY_ADDRESSES:
      if (action.payload === null) return state
      return {
        epcid: action.payload.epcid,
        data: action.payload.data,
        doors: action.payload.doors,
        hubs: action.payload.hubs,
        doors_hubs: action.payload.doors_hubs || []
      }
    default:
      return state
  }
}
