import _ from 'lodash'
import { connect } from 'react-redux'

const CheckoutErrorsPanel = ({ errors }) => {
  // filter out errors that don't have a value
  const errorsPresent = _(errors).omitBy(_.isNull).value()

  if (Object.values(errorsPresent).length > 0) {
    return (
      <div className="alert alert-danger p-md" role="alert">
        <div style={{ fontWeight: 700, marginBottom: '10px' }}>
          We encountered one or more errors while processing your order for checkout. Please review them below and reach
          out to our support team if you need assistance.
        </div>
        {Object.entries(errorsPresent).map(([key, val], i) => {
          if (val) {
            // if error is present
            return (
              <div style={{ marginBottom: '15px' }} key={i}>
                {errors[key]?.message ?? errors[key]}
              </div>
            )
          }
        })}
      </div>
    )
  } else {
    return null
  }
}

const mapStateToProps = state => {
  return {
    errors: state.errors,
    checkoutErrors: state.checkout.errors
  }
}

export default connect(mapStateToProps)(CheckoutErrorsPanel)
