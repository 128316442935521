import supportedCountries from '../../configs/supportedCountries'
import { useState, useEffect } from 'react'
import Select from 'react-select'
import { connect } from 'react-redux'

const CountrySelect = ({ countryCode, handleChange, portal }) => {
  const [selectedCountry, setSelectedCountry] = useState({})
  const [countries, setCountries] = useState([])

  useEffect(() => {
    setCountries(supportedCountries(portal.supported_countries))
  }, [portal])

  useEffect(() => {
    const country = countries.find(country => country.country_code === countryCode)
    if (country) {
      setSelectedCountry(country)
    }
  }, [countryCode, countries])

  useEffect(() => {
    handleChange(selectedCountry)
  }, [selectedCountry])

  const customStyles = {
    singleValue: (provided, state) => {
      const overflow = 'none'
      return { ...provided, overflow }
    }
  }

  return (
    <Select
      defaultInputValue={selectedCountry.name}
      value={selectedCountry}
      onChange={setSelectedCountry}
      options={countries}
      getOptionLabel={option => option.name}
      getOptionValue={option => option.country_code}
      styles={customStyles}
    />
  )
}

const mapStateToProps = state => {
  return {
    portal: state.portal
  }
}

export default connect(mapStateToProps)(CountrySelect)
