import { SAVE_SEARCH_QUERY } from '../actions/types'

export default (state = '', action) => {
  switch (action.type) {
    case SAVE_SEARCH_QUERY:
      return action.payload
    default:
      return state
  }
}
