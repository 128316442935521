import _ from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchInventory } from '../actions'

/**
 * @typedef {Object} AvailableOptions
 * @property {Set<string>} [variantId] - set of available options for a given variant
 */

/**
 * @typedef {Array<string>} MatchedSkus
 */

let _enqueuedSkus = new Set()

/**
 * __normalizeObject - normalizes object by sorting arrays and converting sets to arrays
 * @param {Object} obj object to be normalized
 * @returns {Object} normalized object
 */
const __normalizeObject = obj => _.mapValues(obj, value => (value instanceof Set ? Array.from(value).sort() : value))

/**
 * _isEqual
 *
 * Compares two objects by stringifying them
 * @param {Object} a - object to compare
 * @param {Object} b - object to compare
 * @returns {boolean} - true if objects are equal
 */
const _isEqual = (a, b) => {
  const normalizedA = __normalizeObject(a)
  const normalizedB = __normalizeObject(b)
  return _.isEqual(normalizedA, normalizedB)
}

/**
 * makeUniqueSkus
 *
 * @param {Array<Array<SkuId, Sku>>} skus - array of arrays containing sku id and sku [[1, 'TRF-PRD-001-RED-XS'], [2, 'TRF-PRD-001-RED-S']]
 * @returns unique skus
 */
const makeUniqueSkus = skus => {
  const uniqueSkus = {}
  skus.forEach(sku => (uniqueSkus[sku[0]] = sku))
  return Object.values(uniqueSkus)
}

/**
 * filterAvailableOptions
 *
 * Filters available options for a given product based on currently selected variants
 *
 * @param {Object<VariantId, Option>} selectedOptions - object of currently selected options
 * @param {Array<string>} variantIds - array of variant ids sorted by variant sort_order
 * @param {Array<string>} availableSkus - array of available skus for a given product
 * @param {string} productSku - the product sku
 * @returns {{availableOptions: AvailableOptions, matchedSkus: MatchedSkus}} - object with availableOptions and matchedSkus
 */
const filterAvailableOptions = (selectedOptions, variantIds, availableSkus, productSku) => {
  const availableOpts = {}
  const matchedSkuParts = []
  const matchedSkus = []
  const selectedOptionsValues = Object.values(selectedOptions)
  // initialize available options for each variant
  for (let i = 0; i < variantIds.length; i++) {
    const variantId = variantIds[i]
    availableOpts[variantId] = new Set()
  }

  // iterate through all available skus and save the skus matching user selection so far
  for (let i = 0; i < availableSkus.length; i++) {
    const sku = availableSkus[i]
    const skuParts = sku.replace(`${productSku}-`, '').split('-') // TRF-PRD-001-RED-XS becomes ['RED', 'XS']

    // save all skus if no selection has been made
    if (selectedOptionsValues.length === 0) {
      matchedSkus.push(availableSkus[i])
      matchedSkuParts.push(skuParts)
      continue
    }

    let allMatch = true
    for (let j = 0; j < selectedOptionsValues.length; j++) {
      const selectedOpt = selectedOptionsValues[j]
      const isMatch = skuParts[selectedOpt.idx] === selectedOpt.sku_code
      if (!isMatch) {
        allMatch = false
        break
      }
    }

    if (allMatch) {
      matchedSkus.push(availableSkus[i])
      matchedSkuParts.push(skuParts)
    }
  }

  // go through all matches and save the available options for each variant.
  // ['RED', 'XS] means it would add RED to the available options for the first variant
  // and XS to the available options for the second variant ({ <variant id>: Set(['RED']), <variant id>: Set(['XS']) })
  for (let i = 0; i < matchedSkuParts.length; i++) {
    const skuParts = matchedSkuParts[i]
    for (let j = 0; j < skuParts.length; j++) {
      const part = skuParts[j]
      const variantIdx = j
      const variantId = variantIds[variantIdx]
      availableOpts[variantId].add(part)
    }
  }

  return { availableOpts, matchedSkus }
}

/**
 * isAllOptionsVisible
 *
 * Checks if any part of the sku is hidden and returns false so that this sku is marked as not matched
 *
 * @param {Array<string>} skuParts - array of sku parts
 * @param {Array<Variant>} sortedVariants - array of sorted variants
 * @returns {boolean} - true if all parts of the sku are visible
 */
const isAllOptionsVisible = (skuParts, sortedVariants) => {
  try {
    for (let i = 0; i < skuParts.length; i++) {
      const part = skuParts[i]
      if (!sortedVariants[i].nested_data.find(opt => opt.sku_code === part)) {
        return false
      }
    }
  } catch (e) {
    return false
  }
  return true
}

/**
 * useProductAvailableOptions
 *
 * Custom hook to get available options for a given product based on currently selected options
 *
 * @param {Object} product - product object. Could be different depending if used in configurable product page or bundle product
 * @param {Object<VariantId, ConfigurableOption>} selectedVariants  - object of currently selected options
 * @returns {AvailableOptions} - object of available options for each variant
 */
export const useProductAvailableOptions = (product, selectedVariants) => {
  const stocks = useSelector(state => state.stocks)
  const dispatch = useDispatch()

  const [availableOptions, setAvailableOptions] = useState(null)
  const [allMatchedSkus, setAllMatchedSkus] = useState([])
  const [remainingSkus, setRemainingSkus] = useState([])

  const allAvailable = useMemo(() => ({ allAvailable: true }), [])

  const sortedVariantIds = useMemo(
    () =>
      [...(product.variants || product.nested_data || [])]
        .sort((a, b) => a.sort_order - b.sort_order)
        .map(v => v.variant_id),
    [product]
  )

  const sortedVariants = useMemo(
    () => [...(product.variants || product.nested_data || [])].sort((a, b) => a.sort_order - b.sort_order),
    [product]
  )

  /**
   * filter product skus based on stock availability and if the option is visible
   */
  const filteredProductSkus = useMemo(() => {
    if (!product.available_skus) return []
    return product.available_skus.filter(sku => {
      const productSku = product.sku || product.product_sku
      const skuParts = sku === productSku ? [] : sku.replace(`${productSku}-`, '').split('-')
      const allOptionsVisible = isAllOptionsVisible(skuParts, sortedVariants)
      if (!allOptionsVisible) {
        return false
      }
      if (sku in stocks) {
        return stocks[sku] > 0
      }
      return true
    })
  }, [product, stocks, sortedVariants])

  const updateAvailableOptions = useCallback(() => {
    if (!product || !product.available_skus) {
      return
    }
    const newAvailableOptions = {}
    sortedVariantIds.forEach(variantId => (newAvailableOptions[variantId] = new Set()))

    const _allMatchedSkus = []
    const _remainingSkus = []
    // Go through each selection and see what is available according to the other selections
    sortedVariantIds.forEach(variantId => {
      const selectedOptionsCopy = { ...selectedVariants }
      delete selectedOptionsCopy[variantId] // remove the current selection so we can see what is available according to the other selections
      const { availableOpts: newAvailableOptionsWithSelf, matchedSkus } = filterAvailableOptions(
        selectedOptionsCopy,
        sortedVariantIds,
        filteredProductSkus,
        product.sku || product.product_sku
      )
      newAvailableOptions[variantId] = new Set([
        ...newAvailableOptions[variantId],
        ...newAvailableOptionsWithSelf[variantId]
      ])
      _allMatchedSkus.push(...matchedSkus)

      // // if the current variant has not been selected by user save the remaining posibilities
      if (!(variantId in (selectedVariants || {}))) {
        _remainingSkus.push(...matchedSkus)
      }
    })
    const uniqueMatchedSkus = makeUniqueSkus(_allMatchedSkus)
    const uniqueRemainingSkus = makeUniqueSkus(_remainingSkus)

    if (
      _isEqual(newAvailableOptions, availableOptions) &&
      _isEqual(uniqueMatchedSkus, allMatchedSkus) &&
      _isEqual(uniqueRemainingSkus, remainingSkus)
    ) {
      return
    }
    setAvailableOptions(newAvailableOptions)
    setAllMatchedSkus(uniqueMatchedSkus)
    setRemainingSkus(uniqueRemainingSkus)
  }, [
    sortedVariantIds,
    selectedVariants,
    setAvailableOptions,
    product,
    filteredProductSkus,
    stocks,
    availableOptions,
    allMatchedSkus,
    remainingSkus
  ])

  // make _enqueuedSkus the same as updated stocks
  useEffect(() => {
    if (typeof stocks !== 'object') return
    if (Object.keys(stocks).length === _enqueuedSkus.size) {
      _enqueuedSkus = new Set([...Object.keys(stocks)])
    }
  }, [stocks])

  useEffect(() => {
    dispatch(fetchInventory(product.id || product.product_id))
  }, [])

  // update available options for each variant when a selection is made
  useEffect(updateAvailableOptions, [selectedVariants, updateAvailableOptions])

  // reset hidden options when the component unmounts
  useEffect(
    () => () => {
      _enqueuedSkus = new Set()
    },
    []
  )

  if (!product.available_skus) {
    return allAvailable
  }

  return availableOptions || allAvailable
}
