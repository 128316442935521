import { useState, useEffect } from 'react'
import { Alert, Button, Card, CardBody, Col, Row, Spinner, Progress, Container, Table } from 'reactstrap'
import { connect } from 'react-redux'
import dollar_price from '../general/DollarPrice'
import {
  dispatchSweetAlert,
  clearSweetAlert,
  fetchUserCredit,
  fetchUser,
  checkCartStockErrors
} from '../../actions/index'
import branditRails from '../../api/brandit_rails'
import { compose } from 'redux'
import { withRouter } from 'react-router'
import _ from 'lodash'
import { setOrderProcessing } from './checkoutFunctions'
import Bugsnag from '@bugsnag/js'
import { standardizeAddress } from '../../util/utils'
import { Popup } from 'devextreme-react/popup'
import 'devextreme/dist/css/dx.light.css'
import { fetchNetTerm } from '../../actions/index'

const CheckoutConfirm = props => {
  const [loading, setLoading] = useState(false)
  const [addressesStandardized, setAddressesStandardized] = useState({ shipping: {}, billing: {} })
  const [transactionId, setTransactionId] = useState('')
  const [orderSuccess, setOrderSuccess] = useState(false)
  const [orderError, setOrderError] = useState({
    step: '', // one of: token, payment, fallback, order, orderItems
    message: '',
    endpoint: '',
    apiResponse: {},
    control: ''
  })
  const [orderInProgress, setOrderInProgress] = useState(false)
  const [stockCheckLoading, setStockCheckLoading] = useState(false)

  // when an order error appears, dispatch it to SweetAlert to show it to the user
  useEffect(() => {
    let errorObject = {}
    if (orderError.step) {
      switch (orderError.step) {
        case 'order':
          errorObject.message = 'Order creation failure'
          break

        default:
          break
      }
      dispatchErrorAlert(
        true, // paymentSuccess
        orderError,
        process.env.REACT_APP_REACT_ENV === 'production', // notifyBugsnag
        100, // props.checkout.youPay,
        'credit card', // props.checkout.paymentMethod,
        props.portal,
        props.checkout.creditsUsed
      )
      props.fetchNetTerm(props.currentUser.id)
    }
  }, [orderError.step])

  // Prevent refresh on order confirmation
  useEffect(() => {
    if (orderInProgress) {
      window.addEventListener('beforeunload', handleBeforeUnload)
    } else {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [orderInProgress])

  // On order success
  useEffect(() => {
    if (orderSuccess) {
      props.fetchUser(props.portal.default_location).then(() => {
        // Allow progress bar to reach 100% before redirect
        setTimeout(() => {
          setOrderInProgress(false)
          redirectToMyOrders()
        }, 100)
      })
    }
  }, [orderSuccess])

  useEffect(() => {
    setAddressesStandardized({
      shipping: standardizeAddress(props.selectedDeliveryAddress),
      billing: standardizeAddress(props.selectedBillingAddress)
    })
  }, [props.selectedDeliveryAddress, props.selectedBillingAddress])

  const dispatchErrorAlert = (
    paymentSuccessfull = false,
    error = {},
    notifyBugsnag = false,
    orderTotal,
    paymentMethod,
    portal,
    creditsUsed
  ) => {
    if (notifyBugsnag === true) {
      Bugsnag.addMetadata('apiResponse', error)
      Bugsnag.addMetadata('endpoint', error.endpoint)
      Bugsnag.notify(new Error(error.message ? error.message : ''))
    }
    props.dispatchSweetAlert({
      customButtons: errorMessage(paymentSuccessfull, orderTotal, paymentMethod, portal, creditsUsed, error),
      closeOnClickOutside: false
    })
  }

  // Error message component

  // SN_CHECK REMOVE FALBACK ID AND MAKE CONTROL WORK
  const errorMessage = (
    // fallback_id,
    paymentSuccessful,
    paymentTotal,
    paymentMethod,
    portal,
    checkoutCreditsUsed,
    error
  ) => {
    const creditsWereUsedForPayment =
      Object.values(checkoutCreditsUsed).reduce((memo, creditAmountCents) => memo + creditAmountCents, 0) > 0
    let payments = error.apiResponse.payments
    return (
      <div>
        <h3 className="order-error-h3">Order Processing Error</h3>
        <Card>
          <CardBody style={{ paddingTop: '6px', paddingBottom: '6px!important' }}>
            <br />
            <div className="mb-2">
              Error code:{' '}
              {error && error.apiResponse && error.apiResponse.control && error.apiResponse.control.replace('_', '')} |{' '}
              {error.apiResponse.log_tracker ? 'Ref #: ' + error.apiResponse.log_tracker : null}
            </div>
            <Alert color="danger">
              {error.apiResponse.user_message !== 0 ? (
                <div>
                  <b>{error.apiResponse.user_message}</b>
                </div>
              ) : null}
            </Alert>
            <div className="order-error-contact-info">
              {portal.packing_slip_support_email_address} | {portal.packing_slip_support_phone_number}
              <br />
            </div>
          </CardBody>
        </Card>
        <br />
        <Card style={{ backgroundColor: '#E5E4E2', color: '363636' }}>
          <CardBody style={{ paddingTop: '6px', paddingBottom: '1px' }}>
            {payments && payments.total_value == 0 ? (
              <div style={{ paddingTop: '2px', paddingBottom: '2px' }}>
                Your card or any credits you may have used <br />
                <strong>have not been charged</strong>.
              </div>
            ) : (
              <div>
                <div style={{ paddingTop: '2px', paddingBottom: '2px' }}>
                  These transactions <b>have been processed</b>:
                </div>
                <br />
                <Table>
                  <thead>
                    <tr>
                      <th className="fw-right">Payment Method</th>
                      <th className="fw-right">Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {payments && payments.account_credits_value > 0 ? (
                      <tr>
                        <td className="fw-right">{localStorage.getItem('account_credit_def')}</td>
                        <td className="fw-right">{dollar_price(payments.account_credits_value, 2, null, false)}</td>
                      </tr>
                    ) : null}
                    {payments && payments.coop_value > 0 ? (
                      <tr>
                        <td className="fw-right">{localStorage.getItem('coop_def')}</td>
                        <td className="fw-right">{dollar_price(payments.coop_value, 2, null, false)}</td>
                      </tr>
                    ) : null}
                    {payments && payments.credit_card_value > 0 && payments.net_30_value == 0 ? (
                      <tr>
                        <td className="fw-right">Credit Card</td>
                        <td className="fw-right">{dollar_price(payments.credit_card_value, 2, null, false)}</td>
                      </tr>
                    ) : null}
                    {payments && payments.net_30_value > 0 ? (
                      <tr>
                        <td className="fw-right">Net 30</td>
                        <td className="fw-right">${payments.net_30_value}</td>
                      </tr>
                    ) : null}
                    {payments && payments.gift_card_value > 0 ? (
                      <tr>
                        <td className="fw-right">Gift Card</td>
                        <td className="fw-right">{dollar_price(payments.gift_card_value, 2, null, false)}</td>
                      </tr>
                    ) : null}
                    {payments && payments.points_value > 0 ? (
                      <tr>
                        <td className="fw-right">Points</td>
                        <td className="fw-right">{payments.points_value * props.currentLocation.conversion_ratio}</td>
                      </tr>
                    ) : null}
                    <tr>
                      <td className="fw-right">
                        <b>Total</b>
                      </td>
                      <td className="fw-right">
                        <b>
                          {payments && payments.points_value
                            ? payments.points_value * props.currentLocation.conversion_ratio
                            : dollar_price(payments && payments.total_value, 2, null, false)}
                        </b>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            )}
          </CardBody>
        </Card>

        <br />
        {error && error.apiResponse && error.apiResponse.control === '_ERR1' ? (
          <>
            <Button className="mf-primary-btn" style={{ marginRight: '10px' }} onClick={handleViewMyAccount} size="lg">
              View My Account
            </Button>
            <Button className="mf-outline-btn" onClick={handleTryAgain} size="lg">
              Try Again
            </Button>
          </>
        ) : (
          <Button className="mf-primary-btn" onClick={handleConfirmErrorModal} size="lg">
            OK
          </Button>
        )}
      </div>
    )
  }

  const handleViewMyAccount = () => {
    props.clearSweetAlert()
    props.history.push('/customer/account/dashboard')
  }

  const handleTryAgain = () => {
    props.clearSweetAlert()
    props.hideModal()
    props.history.go(0)
  }

  const handleConfirmErrorModal = () => {
    props.clearSweetAlert()
    props.hideModal()
    initializeOrderProgress()
  }

  const redirectToMyOrders = () => {
    props.history.push({
      pathname: '/customer/account/dashboard',
      tabSelected: 'My Orders',
      confirmationMessage: `Your order has been placed successfully!`
    })
  }

  // Prevent page refresh on order confirmation
  const handleBeforeUnload = event => {
    event.preventDefault()
    event.returnValue = ''
  }

  // On Confirm. Check Stock, Then Create Order
  const handleOrderConfirm = () => {
    setLoading(true)
    setOrderInProgress(true)
    setStockCheckLoading(true)

    props.checkCartStockErrors(props.checkout.userCartId).then(resp => {
      setStockCheckLoading(false)
      if (resp?.cart_items?.length > 0) {
        setLoading(false)
        setOrderInProgress(false)
        props.hideModal()
      } else {
        createOrder()
      }
    })
  }

  const createOrder = () => {
    // Deductions
    let coopDeduction = props.checkout.creditsUsed.coopCredits
    let creditDeduction = props.checkout.creditsUsed.accountCredits
    let giftDeduction = props.checkout.creditsUsed.giftCard
    let lmsPointsDeduction = props.checkout.creditsUsed.lmsPoints
    let creditCardPayment = props.checkout.youPay
    const userId = props.currentUser.id
    let formData = new FormData()
    formData.append('cart_json', JSON.stringify(props.cartItems))
    formData.append('user_id', userId)
    formData.append('user_cart_id', props.checkout.userCartId)
    formData.append('portal_id', props.portal.id)
    formData.append('location_id', props.currentLocation.id)
    formData.append('total_cents', creditCardPayment)
    formData.append('payment_coop', coopDeduction)
    formData.append('payment_credit_note', creditDeduction)
    formData.append('payment_gift', giftDeduction)
    formData.append('payment_lms_points', lmsPointsDeduction)
    if (transactionId) {
      formData.append('transaction_id', transactionId === -1 ? null : transactionId)
      formData.append('billing_status', 'paid')
    } else if (props.checkout.youPay === 0) {
      formData.append('billing_status', 'paid')
    } else {
      formData.append('billing_status', 'net_unpaid')
      formData.append('net30', true)
    }
    formData.append('tax', props.checkout.taxQuote)
    formData.append('tax_info', props.tax['tax_info'])
    formData.append('applied_promotions', JSON.stringify(Object.values(props.applied_promotions)))
    formData.append('shipment_in_cents', props.checkout.shippingQuote)
    formData.append('items_discount_in_cents', props.checkout.subtotalReduction + props.checkout.productReduction)
    formData.append('shipping_discount_in_cents', props.checkout.shippingReduction)
    formData.append('coop_discount_in_cents', coopDeduction)
    formData.append('subtotal_in_cents', props.checkout.subtotal)
    formData.append('custom_checkout_fee', props.checkout.customCheckoutFee)
    formData.append('is_payment_total_zero', props.checkout.youPay === 0)
    formData.append('po_number', props.checkout.poNumber)
    formData.append('customer_notes', props.checkout.customerNotes)
    formData.append('token', props.token)
    formData.append('payment_method', props.checkout.paymentMethod)
    formData.append('net_30_confirmed', props.checkout.net30Confirmed)
    if (props.checkoutFormData && props.checkoutFormData.length > 0) {
      formData.append('checkout_form', JSON.stringify(props.checkoutFormData))
    }

    if (props.portal.multiple_address_shipping) {
      formData.append('multi_address_selection', JSON.stringify(props.checkout.multiAddressSelection))
    }

    // if portal does not have smart addresses enabled OR
    // if it DOES but there are no doors_hubs
    if (
      !props.portal.smart_address_book ||
      (props.portal.smart_address_book && !props.smart_delivery_addresses.doors_hubs)
    ) {
      // if selected delivery address is present
      if (props.selectedDeliveryAddress.id) {
        formData.append('delivery_address_id', props.selectedDeliveryAddress.id)
      }
      // if selected delivery address is not present but not required
      else if (
        !props.selectedDeliveryAddress.id &&
        props.cartItems.every(ci => ci.shippable_type === 'Digital Delivery')
      ) {
        // formData.append('delivery_address_id', null);
      }
      // if selected delivery address is not present but required - error
      else {
        console.error('Shipping Address Required')
        return
      }

      !props.checkout.youPay === 0 && formData.append('billing_address_id', props.selectedBillingAddress.id)
    }

    // if smart address, append appropriate keys for address & phone
    if (!_.isEmpty(props.selectedDeliveryAddress) && props.selectedDeliveryAddress.address1) {
      formData.append('smart_delivery_address', JSON.stringify(props.selectedDeliveryAddress))
      formData.append('delivery_phone_number', props.selectedDeliveryAddress.phone)
    } else {
      // else use phone from address
      formData.append('delivery_address', JSON.stringify(props.selectedDeliveryAddress))
      formData.append('delivery_phone_number', props.selectedDeliveryAddress.address_phone)
    }
    props.checkout.youPay !== 0 && formData.append('billing_address_id', props.selectedBillingAddress.id)
    props.checkout.youPay !== 0 && formData.append('billing_address', JSON.stringify(props.selectedBillingAddress))
    formData.append('is_smart_address', !!props.selectedDeliveryAddress.m_able)
    formData.append('is_smart_billing_address', !!props.selectedBillingAddress.m_able)
    formData.append('epcid_zero', props.smart_delivery_addresses.epcid)
    if (props.portal.use_lms_points && !!props.currentLocation.conversion_ratio) {
      formData.append('conversion_ratio', props.currentLocation.conversion_ratio)
    }

    const { card_number, card_expiry, card_cvc } = props.checkout.ccData
    const { selectedDeliveryAddress, selectedBillingAddress, paymentMethod } = props.checkout
    const trimmedCardNumber = card_number.replace(/\s/g, '')

    formData.append('card_number', trimmedCardNumber)
    formData.append('card_expire_date', card_expiry)
    formData.append('card_security_code', card_cvc)
    formData.append('card_number_two_first_digits', trimmedCardNumber.substr(0, 1))
    formData.append('card_last_four_digit', trimmedCardNumber.substr(-4))
    formData.append('payment_amount', parseFloat(props.checkout.youPay / 100).toFixed(2))
    formData.append('delivery_address', JSON.stringify(selectedDeliveryAddress))
    formData.append('billing_address', JSON.stringify(selectedBillingAddress))
    formData.append('smart_delivery_addresses_epcid', props.smart_delivery_addresses.epcid)
    if (props.portal.use_lms_points && !!props.currentLocation.conversion_ratio) {
      formData.append('conversion_ratio', props.currentLocation.conversion_ratio)
    }

    branditRails
      .post('/orders/create', formData)
      .then(response => {
        props.fetchUserCredit(props.currentLocation.id)
        if (response && response.data.status == 'success') {
          let order_id = response.data.data[0].order.id
          setOrderSuccess(true)
        } else if (response && response.data.status == 'failed') {
          setOrderInProgress(false)
          setLoading(false)
          setOrderError({
            step: 'order',
            message: 'Order creation failure',
            endpoint: `/orders/create`,
            apiResponse: response.data,
            control: response.data.control
          })
          return
        } else {
          setOrderInProgress(false)
          setLoading(false)
          return
        }
      })
      .catch(error => {
        setOrderError({
          step: 'order',
          message: 'Order creation failure',
          endpoint: `/orders/create`,
          formData: formData,
          apiResponse: error,
          control: null
        })
        setOrderInProgress(false)
        setLoading(false)
        return
      })
  }

  // ** END ORDER FLOW

  // this function conforms data structure of new product variants to old 'selectedOptions' for API
  const mapVariantsToSelectedOptions = variants => {
    return variants.map(variant => {
      return {
        name: variant.name, // technically the option name
        variant: {
          name: variant.configurable_variant_name,
          display_name: variant.configurable_variant_display_name
        },
        id: variant.id,
        configurable_option_id: variant.id,
        configurable_variant_id: variant.configurable_variant_id,
        sku_code: variant.sku_code,
        sort_order: variant.sort_order
      }
    })
  }

  // this creates the structure to be nested under digital_proofing_form
  // ** amended to accept a bundle product
  const conformDigitalProofingData = (cartItem, isChildOfBundle = false) => {
    if ((cartItem.flags && cartItem.flags.is_digital_proofing) || cartItem.is_digital_proofing) {
      const digitalProofing = cartItem.digitalProofing
      let digitalProofingData = {}
      if (!digitalProofing) {
        // this happens for prods inside bundle - need cart item API to include data
        const bundleProductDpData = cartItem.nested_data[0]
        digitalProofingData = bundleProductDpData.nested_data[0]
      } else {
        digitalProofingData =
          digitalProofing.nested_data && digitalProofing.nested_data[0] ? digitalProofing.nested_data[0] : {}
      }

      let result = {}
      // for each possible address, add to result
      const addressNumbers = [1, 2, 3, 4, 5, 6]
      addressNumbers.forEach(num => {
        const attr_name = `address_${num}`
        result[attr_name] = {
          [`${attr_name}_line_1`]: digitalProofingData[`address_${num}_line_1`],
          [`${attr_name}_line_2`]: digitalProofingData[`address_${num}_line_2`],
          [`${attr_name}_city`]: digitalProofingData[`address_${num}_city`],
          [`${attr_name}_state`]: digitalProofingData[`address_${num}_state`],
          [`${attr_name}_country`]: digitalProofingData[`address_${num}_country`],
          [`${attr_name}_zip_code`]: digitalProofingData[`address_${num}_zip_code`]
        }

        // append each phone number as well
        result[`phone_number_${num}`] = digitalProofingData[`phone_number_${num}`]
      })

      // append front address and phone
      result['front_address'] = {
        [`front_address_line_1`]: digitalProofingData[`front_address_line_1`],
        [`front_address_line_2`]: digitalProofingData[`front_address_line_2`],
        [`front_address_city`]: digitalProofingData[`front_address_city`],
        [`front_address_state`]: digitalProofingData[`front_address_state`],
        [`front_address_country`]: digitalProofingData[`front_address_country`],
        [`front_address_zip_code`]: digitalProofingData[`front_address_zip_code`],
        [`front_phone`]: digitalProofingData[`front_phone`]
      }

      // append rest of DP info
      result = {
        ...result,
        approved_date: digitalProofingData.approved_date,
        coupon_expiration_date: digitalProofingData.coupon_expiration_date,
        employee_name: digitalProofingData.employee_name,
        employee_position: digitalProofingData.employee_position,
        employee_email: digitalProofingData.employee_email,
        dealer_name: digitalProofingData.dealer_name
      }
      if (isChildOfBundle) {
        return [result, digitalProofingData ? digitalProofingData.digital_proofing_product_id : null]
      } else {
        return result
      }
    } else {
      if (isChildOfBundle) {
        return [null, null]
      } else {
        return null
      }
    }
  }

  const initializeOrderProgress = () => {
    setOrderSuccess(false)
    setTransactionId('')
    setLoading(false)
    setOrderError({})
    props.setOrderProcessing(false)
  }

  const resolvePaymentMessage = () => {
    if (props.portal.use_lms_points) {
      return <span>Total Points to be Used: {dollar_price(props.checkout.creditsUsed.lmsPoints)}</span>
    }
    switch (props.checkout.paymentMethod) {
      case 'credit_card':
        return <span>Amount to be Charged to Credit Card: {dollar_price(props.checkout.youPay)}</span>
      case 'net30':
        return (
          <span>
            Payment Amount Due ({props.netTerm || 'Net 30'}): {dollar_price(props.checkout.youPay)}
          </span>
        )
      default:
        return <span>Amount to be Charged to Credit Card: {dollar_price(props.checkout.youPay)}</span>
    }
  }

  const paymentMethod = props.checkout.paymentMethod === 'credit_card' ? 'Credit Card' : 'Net30'
  const hidePhysicalShipping = props.cartItems.every(ci => ci.shippable_type === 'Digital Delivery')
  const showDigitalDeliveryMessage = props.cartItems.some(ci => ci.shippable_type === 'Digital Delivery')

  return (
    <Popup
      closeOnOutsideClick={false}
      visible={props.show}
      showCloseButton={false}
      showTitle={false}
      onHiding={props.hideModal}
      dragEnabled={false}
      height={'auto'}
      width={'auto'}
    >
      <Container className="order-confirmation-container">
        <Row>
          <Col>
            <h2>Order Confirmation</h2>
          </Col>
        </Row>
        <Row>
          <Col>
            <p>Please confirm your order. Upon final confirmation your order will be placed.</p>

            {showDigitalDeliveryMessage && (
              <p>
                Some or all items in your cart will be delivered digitally to the email{' '}
                <strong>{props.currentUser.email}</strong>.
              </p>
            )}

            {hidePhysicalShipping ? null : (
              <p>
                <strong>Ship To:</strong> &nbsp;
                {addressesStandardized.shipping.address_line_1}, &nbsp;
                {addressesStandardized.shipping.address_line_2
                  ? `${addressesStandardized.shipping.address_line_2}, `
                  : ''}
                {addressesStandardized.shipping.city}, &nbsp;
                {addressesStandardized.shipping.state}, &nbsp;
                {addressesStandardized.shipping.zip_code}
              </p>
            )}

            {!props.checkout.youPay === 0 ? (
              <span>
                <p>
                  Billing Address: &nbsp;
                  {addressesStandardized.billing.address_line_1}, &nbsp;
                  {addressesStandardized.billing.address_line_2
                    ? `${addressesStandardized.billing.address_line_2}, `
                    : ''}
                  {addressesStandardized.billing.city}, &nbsp;
                  {addressesStandardized.billing.state}, &nbsp;
                  {addressesStandardized.billing.zip_code}
                </p>
                <p>Payment Method: {paymentMethod}</p>
              </span>
            ) : null}

            {props.checkout.creditsUsed.coopCredits ? (
              <div>
                <div>
                  {localStorage.getItem('coop_def')}:&nbsp; -{dollar_price(props.checkout.creditsUsed.coopCredits)}
                </div>
              </div>
            ) : null}

            {props.checkout.creditsUsed.accountCredits ? (
              <div>
                <div>
                  {localStorage.getItem('account_credit_def')} Payment:&nbsp; -
                  {dollar_price(props.checkout.creditsUsed.accountCredits)}
                </div>
              </div>
            ) : null}

            {props.checkout.creditsUsed.giftCard ? (
              <div>
                <div>Gift Card Credit Payment:&nbsp; -{dollar_price(props.checkout.creditsUsed.giftCard)}</div>
              </div>
            ) : null}

            {props.currentLocation.show_price ? <p className="payment-total">{resolvePaymentMessage()}</p> : null}
          </Col>
        </Row>
        <Row>
          <Col>
            <div style={{ margin: 'auto', textAlign: 'center' }}>
              {loading ? (
                <Spinner color="secondary" />
              ) : (
                <Row>
                  <Col xs={6}>
                    <Button
                      style={{ width: '100%', maxWidth: '200px' }}
                      className="mf-outline-btn"
                      onClick={props.hideModal}
                    >
                      Back to Checkout
                    </Button>
                  </Col>
                  <Col xs={6}>
                    <Button
                      style={{ width: '100%', maxWidth: '200px' }}
                      className="mf-primary-btn"
                      disabled={loading || !props.canProceed}
                      onClick={handleOrderConfirm}
                    >
                      Confirm Order
                    </Button>
                  </Col>
                </Row>
              )}
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            {orderInProgress && (
              <div className="mt-4">
                <Progress
                  animated
                  value={stockCheckLoading ? 25 : orderSuccess ? 100 : 50}
                  // value={
                  //   [
                  //     orderSuccess,
                  //   ].reduce((progressPercent, successStatus, i, arr) => {
                  //     return progressPercent + (successStatus ? 100 / arr.length : 0)
                  //   }, 0)
                  // }
                >
                  {stockCheckLoading ? 'Checking Stock' : 'Creating Order'}
                </Progress>
                <p className="mt-3 mb-0">
                  <strong>Order in progress. Please do not navigate away from page. </strong>
                </p>
              </div>
            )}
          </Col>
        </Row>
      </Container>
    </Popup>
  )
}

const mapStateToProps = state => {
  return {
    selectedBillingAddress: state.checkout.selectedBillingAddress,
    selectedDeliveryAddress: state.checkout.selectedDeliveryAddress,
    checkout: state.checkout,
    portal: state.portal,
    token: state.token,
    cartItems: state.cartItems,
    tax: state.tax,
    applied_promotions: state.applied_promotions,
    userCredits: state.userCredits,
    currentLocation: state.currentLocation,
    smart_delivery_addresses: state.smart_delivery_addresses,
    currentLocation: state.currentLocation,
    orderFallback: state.order_fallbacks.fallback,
    locations: state.locations,
    currentUser: state.currentUser,
    checkoutFormData: state.checkout.checkoutFormData,
    netTerm: state.netTerm.netTermDef
  }
}

export default compose(
  withRouter,
  connect(mapStateToProps, {
    dispatchSweetAlert,
    clearSweetAlert,
    fetchUserCredit,
    fetchUser,
    setOrderProcessing,
    fetchNetTerm,
    checkCartStockErrors
  })
)(CheckoutConfirm)
