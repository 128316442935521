export default (
  state = {
    items: [],
    total: 0
  },
  action
) => {
  switch (action.type) {
    case 'SET_MY_DOWNLOADS':
      return {
        items: action.payload,
        total: action.total
      }
    default:
      return state
  }
}
