import React, { useState } from 'react'
import { Modal, ModalFooter } from 'reactstrap'
import Checkbox from '@material-ui/core/Checkbox'
import Close from '@material-ui/icons/Close'
import Cookies from 'universal-cookie'

const cookies = new Cookies()

const SpecialsModal = props => {
  const [modal, setModal] = useState(true)
  const toggle = value => {
    cookies.set('showPromo', value)
    setModal(!modal)
  }
  const promoBg = props.promoBg
  const showPromo = cookies.get('showPromo')

  if (showPromo === 'false' || !promoBg) {
    return <span></span>
  } else {
    return (
      <Modal
        isOpen={modal}
        className="specialsModal"
        style={{ backgroundImage: `url(${promoBg})` }}
        modalTransition={{ timeout: 800 }}
        backdropTransition={{ timeout: 500 }}
      >
        <img src={promoBg} />
        <Close className="modal-close" onClick={() => setModal(!modal)}></Close>
        <ModalFooter className="modal-dont-show">
          <Checkbox onClick={() => toggle('false')} />
          Don't show this popup again
        </ModalFooter>
      </Modal>
    )
  }
}

export default SpecialsModal
