import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Input, Label, Form, FormGroup } from 'reactstrap'
import { setCustomerNotes, setCheckoutFormData, validateCheckoutForm } from './checkoutFunctions'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'

const CheckoutInformation = props => {
  const [customerNote, setCustomerNote] = useState('')
  const [checkoutFormData, setCheckoutFormData] = useState([])
  const [checkoutFormIsValid, setCheckoutFormIsValid] = useState(false)

  //prevent customerNotes from clearing from checkout error
  useEffect(() => {
    props.setCustomerNotes(customerNote)
    props.setCheckoutFormData(checkoutFormData)
  }, [customerNote, checkoutFormData])

  useEffect(() => {
    // Initialize formData with default values
    const initialFormData = JSON.parse(JSON.stringify(props.checkoutForm.filter(form => form.field_type !== 'html')))
    initialFormData.forEach(field => {
      if (field.field_type === 'checkbox' || field.field_type === 'radio') {
        field.options.forEach(option => {
          option.checked = false
        })
      }
      if (field.field_type === 'customer_notes') {
        field.options[0].value = ''
      }
    })
    setCheckoutFormData(initialFormData)
    props.setCheckoutFormData(initialFormData)
  }, [props.checkoutForm])

  // validate checkout form
  useEffect(() => {
    setCheckoutFormIsValid(validateCheckoutForm(props.checkoutFormData))
  }, [props.checkoutFormData])

  const handleInputChange = (field, optionIndex, value) => {
    const newFormData = [...props.checkoutFormData]
    let selectedField = newFormData.find(form => form.id === field.id)

    if (selectedField.field_type === 'radio') {
      selectedField.options.forEach((option, i) => {
        option.checked = i === optionIndex // Set only the selected option to true, all others to false
      })
    } else if (selectedField.field_type === 'checkbox') {
      selectedField.options[optionIndex].checked = value
    } else if (selectedField.field_type === 'customer_notes') {
      selectedField.options[0].value = value
      setCustomerNote(value)
    }
    setCheckoutFormData(newFormData)
    props.setCheckoutFormData(newFormData)
  }

  const renderCommonField = (field, index) => {
    const radioIsRequired = field.field_type === 'radio' && field.options[0].is_required
    return (
      <FormGroup key={index}>
        <Row>
          <Col>
            <h6>
              {field.name} {radioIsRequired && <span className="text-danger">*</span>}
            </h6>
            <p className="ml-2 mb-1">{field.description}</p>
          </Col>
        </Row>
        <Row>
          <Col>{renderFieldOptions(field, index)}</Col>
        </Row>
      </FormGroup>
    )
  }

  const renderFieldOptions = (field, fieldIndex) => {
    return field.options.map((option, optionIndex) => {
      const checkboxRequired = field.field_type === 'checkbox' && option.is_required

      return (
        <FormGroup key={optionIndex} check className="ml-2">
          <Label check>
            <Input
              className="mt-0"
              type={field.field_type}
              name={field.field_type === 'radio' ? `radio-${field.id}` : undefined}
              checked={option.checked}
              onChange={e => handleInputChange(field, optionIndex, e.target.checked)}
            />
            {option.value} {option.description.length > 0 ? ' - ' + option.description : ''}{' '}
            {checkboxRequired && <span className="text-danger ml-1">*</span>}
          </Label>
        </FormGroup>
      )
    })
  }

  const renderCustomerNotes = (field, fieldIndex) => {
    const notesRequired = field.options[0].is_required

    return (
      <FormGroup>
        <Row>
          <Col>
            <h6>Customer Notes {notesRequired && <span className="text-danger">*</span>} </h6>
          </Col>
        </Row>
        <Row>
          <Col>
            <Input
              rows="2"
              type="textarea"
              onChange={e => handleInputChange(field, 0, e.target.value)}
              value={customerNote}
            />
          </Col>
        </Row>
      </FormGroup>
    )
  }

  const renderHtml = field => (
    <FormGroup>
      <Row>
        <Col>
          <div dangerouslySetInnerHTML={{ __html: field.options[0].value }}></div>
        </Col>
      </Row>
    </FormGroup>
  )

  const renderCheckoutForm = () => {
    return props.checkoutForm.map((field, index) => {
      switch (field.field_type) {
        case 'checkbox':
        case 'radio':
          return renderCommonField(field, index)
        case 'customer_notes':
          return renderCustomerNotes(field, index)
        case 'html':
          return renderHtml(field)
        default:
          return null
      }
    })
  }

  const renderCheckoutFormRequiredText = () => {
    return checkoutFormIsValid ? null : <p className="text-danger">* Input/Selection Is Required</p>
  }

  return (
    <div className="box-shadow white-background m-0">
      <div className="ckout-header">
        <h5 className="m-0">Checkout Information</h5>
      </div>
      <Container className="mb-20 mt-20 px-30">
        <Form className="pb-20">
          {renderCheckoutForm()}
          {renderCheckoutFormRequiredText()}
        </Form>
      </Container>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    customerNotes: state.checkout.customerNotes,
    currentLocation: state.currentLocation,
    checkoutForm: state.checkoutForm,
    checkoutFormData: state.checkout.checkoutFormData
  }
}

export default connect(mapStateToProps, {
  setCustomerNotes,
  setCheckoutFormData
})(CheckoutInformation)
