import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { Divider, Card, CardContent, IconButton, CardMedia } from '@material-ui/core'
import { Link } from 'react-router-dom'
import placeholder from './placeholder-image.jpg'
import Slider from 'react-slick'
import { Button } from 'reactstrap'
import { productIsOnSale, productRequiresApproval } from './productPageFunctions'
import { isTablet, isMobile } from 'react-device-detect'
import dollar_price from '../general/DollarPrice'

const settings = {
  dots: false,
  infinite: false,
  arrows: true,
  speed: 800,
  autoplay: false,
  slidesToShow: 5,
  slidesToScroll: 2,
  centerPadding: '5px',
  swipe: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 2
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 1
      }
    }
  ]
}

class ProductCard extends Component {
  hideTierPricesFromThisCustomerGroup = () => {
    const { product, customerGroup } = this.props

    let result = false

    // for each tier price
    product.tier_prices.map(tier_price => {
      // if the tier price has customer groups
      if (tier_price.customer_groups && tier_price.customer_groups.length > 0) {
        // for each of its customer groups
        tier_price.customer_groups.map(customer_group => {
          // if it matches one of the customer groups in props
          if (customerGroup.some(g => g.id === customer_group.id)) {
            result = true
          } else {
            result = true
          }
        })
      } else if (product.tier_prices.length === 0) {
        result = true
      }
    })
    return result
  }

  render() {
    const { product, portal, currentLocation } = this.props
    const location_id = currentLocation.id
    const productUrl = `/product/${product.product_id}/${portal.id}${location_id ? '/' + location_id : ''}`

    let coopPercent = this.props.coopPercent

    const coopPrice = price => {
      let coopDeduction = price * coopPercent
      let prodPrice = price - coopDeduction
      return dollar_price(prodPrice)
    }

    const hasTierPricing = () =>
      product.flags.has_tier_price &&
      product.tier_prices.length > 0 &&
      this.hideTierPricesFromThisCustomerGroup() !== true
    const coopEnabledOnProduct = () => product.coop_enabled
    const coopEnabledOnPortal = () => !!portal.coop_enabled
    const coopEnabledOnLocation = () => !!currentLocation.coop_enable
    const coopEnabled = () => coopEnabledOnProduct() && coopEnabledOnPortal() && coopEnabledOnLocation()

    // Use non-breaking hyphen
    let coopDef = localStorage.getItem('coop_def') ? localStorage.getItem('coop_def').replace('-', '‑') : ''

    // If coop_abbreviation exists, use that instead of coop_def
    if (
      portal.context_dictionary.coop_abbreviation &&
      this.props.portal.context_dictionary.coop_abbreviation.length > 0
    ) {
      coopDef = portal.context_dictionary.coop_abbreviation.replace('-', '‑')
    }

    const standardProductMedia = () => {
      return (
        <Link to={{ pathname: productUrl, product: product, portal: portal }} className="d-block w-100">
          {/* DISPLAY CUSTOM CSS FROM PORTAL TAGS */}
          {product.css_portal_tags &&
            product.css_portal_tags.length > 0 &&
            product.css_portal_tags.map(tag => <div className={tag.name} style={{ zIndex: 1 }}></div>)}
          <img
            className="product-img"
            alt="product-card-image"
            src={product.images && product.images.length > 0 ? product.images[0].medium : placeholder}
          />
        </Link>
      )
    }

    const sliderProductMedia = productData => {
      return (
        <Link to={'/product/' + productData.product_id + '/' + portal.id} className="d-block" style={{ width: '100%' }}>
          <CardMedia
            component="img"
            image={
              productData.product_detail_images && productData.product_detail_images[0] != null
                ? productData.product_detail_images[0].image.file.small.url
                : placeholder
            }
          />
          <img
            alt="product-card-image"
            src={
              productData.product_detail_images && productData.product_detail_images[0] != null
                ? productData.product_detail_images[0].image.file.small.url
                : placeholder
            }
          />
        </Link>
      )
    }

    return (
      <Card className="iron-product-item post-rounded iron-shadow MuiCard-root-category">
        <div className="iron-overlay-wrap overflow-hidden d-flex justify-content-center align-items-center">
          {/* Main Image */}
          {this.props.isSlideProduct && this.props.isSlideProduct === true
            ? sliderProductMedia(product)
            : standardProductMedia()}

          {/* FLAG BOXES (hide if location.show_price === false) */}
          <div className="product-flag-container">
            {this.props.currentLocation.show_price === false ? null : (
              <>
                {/* Sale Flag */}
                {productIsOnSale(product) && (
                  <div className="sale-price-product-card-box">
                    <span>SALE</span>
                  </div>
                )}

                {/* Tier Price Flag */}
                {hasTierPricing() && (
                  <div className="tier-price-product-card-box">
                    <span>Tier Pricing Available</span>
                  </div>
                )}
              </>
            )}

            {/* Qty Increment Flag */}
            {product.enable_qty_increments === true && product.qty_increments > 0 && !product.hide_qty_in_set_text ? (
              <div className="box-set-product-card-box">
                <span>
                  {product.qty_in_set_text} of {product.qty_increments}
                </span>
              </div>
            ) : null}

            {/* Requires Approval Flag */}
            {productRequiresApproval(product, this.props.currentLocation) && (
              <div className="requires-approval-product-card-box">
                <span>Requires Approval</span>
              </div>
            )}
          </div>
        </div>

        <Divider />

        <CardContent className="iron-product-content p-20 pt-30">
          <table
            id="product-card-info"
            className={
              this.props.portal.coop_enabled == true ? 'product-card-info-extended' : 'product-card-info-short'
            }
          >
            {/* Product Title */}
            <thead>
              <tr>
                <td colSpan="2">
                  <h5 className="text-truncate" style={{ textAlign: 'center' }}>
                    <Link to={{ pathname: productUrl, product: product, portal: portal }} className="d-block">
                      {product.name}
                    </Link>
                  </h5>
                </td>
              </tr>
            </thead>

            <tbody className="product-info-theme" style={{ lineHeight: 1 }}>
              {/* Product Price Details */}
              {/* If co-op is disabled on portal, only display primary price td with colspan 2 to eliminate center border */}
              <tr id="product-card-price-row">
                {/* Price 1 (Left Side) */}
                <td id="price-field-primary" colSpan={this.props.portal.coop_enabled ? '1' : '2'}>
                  {/* Don't show price for buildable bundles */}
                  {product.flags.is_buildable_bundle === true ? (
                    <>
                      <div>
                        {/* We need a blank line instead of null so that the center dotted borderline extends to same length as other cards */}
                        <span className="display-price">&nbsp;</span>
                      </div>
                    </>
                  ) : (
                    <>
                      {this.props.currentLocation.show_price && productIsOnSale(product) ? (
                        <div>
                          <span className="display-price">{dollar_price(product.sale_price, 2)}</span>
                          <br />
                          <span id="old-price">
                            <em>Was {dollar_price(product.price, 2)}</em>
                          </span>
                        </div>
                      ) : (
                        <div>
                          <span className="display-price">{dollar_price(product.price, 2)}</span>
                        </div>
                      )}
                    </>
                  )}
                </td>

                {/* Price 2 (Right Side) */}
                {portal.coop_enabled ? (
                  <td id="price-field-secondary">
                    {/* Don't show price for buildable bundles */}
                    {product.flags.is_buildable_bundle === true ? null : (
                      <>
                        {/* Co-Op Price */}
                        {coopEnabled() && !productIsOnSale(product) ? (
                          <div>
                            {coopDef}
                            <br />
                            <span className="display-price"> {coopPrice(product.price)}</span>
                          </div>
                        ) : null}

                        {/* Co-Op enabled on location but not product, display "not available" */}
                        {coopEnabledOnPortal() && coopEnabledOnLocation() && !coopEnabledOnProduct() ? (
                          <span>
                            <span>No {coopDef}</span> Available
                          </span>
                        ) : null}

                        {/* Co-Op Enabled AND On Sale */}
                        {this.props.currentLocation.show_price && coopEnabled() && productIsOnSale(product) ? (
                          <div>
                            <span>
                              {coopDef} <span className="display-price"> {coopPrice(product.sale_price)}</span>
                              <br />
                            </span>
                            <span id="old-price">
                              <em>Was {dollar_price(product.price * (1 - this.props.coopPercent), 2)}</em>
                            </span>
                          </div>
                        ) : null}
                      </>
                    )}
                  </td>
                ) : null}
              </tr>

              {/* Config Option Carousel */}
              <tr>
                <td colSpan="2">
                  <div
                    style={{
                      // Needed to make the carousel works inside tables.
                      overFlow: 'hidden',
                      minWidth: '100%',
                      width: '0'
                    }}
                  >
                    <Slider className="mt-20" {...settings}>
                      {product.variants &&
                        product.variants.map((variant, i) => {
                          if (variant.display_type === 'Swatch') {
                            let sortedOptions = variant.nested_data.sort((a, b) =>
                              a.sort_order > b.sort_order ? 1 : 0
                            )
                            return sortedOptions.map((option, i) => {
                              let padding = isMobile || isTablet ? '1px' : '4px'

                              return (
                                <span>
                                  <Button
                                    key={i}
                                    id={option.id}
                                    style={{
                                      backgroundColor: option.css_hex,
                                      backgroundImage: option.icon.thumb.url ? `url(${option.icon.thumb.url})` : null,
                                      textDecoration: 'none',
                                      pointerEvents: 'none',
                                      borderRadius: '100%',
                                      width: '20px',
                                      height: '20px',
                                      padding: padding,
                                      border: 'solid white 2px',
                                      borderWidth: '1px',
                                      boxShadow: '0 0 0 2px #d7d7d7'
                                    }}
                                  ></Button>
                                </span>
                              )
                            })
                          }
                        })}
                    </Slider>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

          <div className="iron-btn-grp">
            <Fragment>
              <Link to={{ pathname: productUrl, product: product, portal: portal }} className="d-block">
                <IconButton className="btn-wrap">
                  <i className="material-icons">pageview</i>
                </IconButton>
              </Link>
            </Fragment>
          </div>
        </CardContent>
      </Card>
    )
  }
}

const mapStateToProps = state => {
  return {
    portal: state.portal,
    currentLocation: state.currentLocation,
    customerGroup: state.customerGroup
  }
}

export default connect(mapStateToProps)(ProductCard)
