import { PropTypes } from 'prop-types'
import { useState, useEffect, useRef } from 'react'
import { Plus } from 'react-feather'
import PhoneInput from 'react-phone-number-input'
import { connect } from 'react-redux'
import { Input, Label, Col, InputGroup, Button, Form, FormGroup } from 'reactstrap'
import { createCustomerAddressNew, validateAddress } from '../../actions'
import supportedCountries from '../../configs/supportedCountries'
import { validateForm, validateField, createLabels } from '../../util/forms'
import { validateAddressLines } from '../../util/utils'
import CountrySelect from '../addresses/CountrySelect'
import { TextInputField } from '../addresses/EditAddressForm'
import StateProvinceSelect from '../addresses/StateProvinceSelect'
import 'react-phone-number-input/style.css'

const NewAddressForm = ({
  setNewAddressMode = () => {},
  newAddressMode = false,
  portal,
  afterCreateAddress = () => {},
  validateAddress,
  createCustomerAddressNew,
  addressType = 'delivery'
}) => {
  const [showAddress2, setAddress2] = useState(false)
  const [addressData, setAddressData] = useState({
    full_name: '',
    title: '',
    address_line_1: '',
    address_line_2: '',
    city: '',
    state: '',
    zip_code: '',
    address_phone: '',
    country: 'United States',
    company_name: '',
    country_code: 'US',
    default_shipping: false
  })
  const [formErrors, setFormErrors] = useState({
    full_name: null,
    address_line_1: null,
    address_line_2: null,
    company_name: null,
    city: null,
    state: null,
    country: null,
    zip_code: null,
    address_phone: null
  })
  const [phoneInputProps, setPhoneInputProps] = useState({
    name: 'address_phone',
    value: '',
    onChange: () => {},
    international: false,
    countryCallingCodeEditable: false,
    countries: ['US'],
    // countries: portal.international_shipping ? countries.map(country => country.country_code) : ['US'],
    withCountryCallingCode: false,
    defaultCountry: 'US'
  })
  const inputRef = useRef(null)
  const cursorRef = useRef(null)

  useEffect(() => {
    if (cursorRef.current !== null && inputRef.current) {
      inputRef.current.setSelectionRange(cursorRef.current, cursorRef.current)
      cursorRef.current = null
    }
  }, [addressData.address_phone])

  // const handleKeyDown = e => {
  //   const input = e.target
  //   const cursorPos = input.selectionStart
  //   const currentValue = addressData.address_phone
  //   const currentDigits = currentValue.replace(/\D/g, '')

  //   // Count digits before cursor
  //   let digitsBeforeCursor = 0
  //   for (let i = 0; i < cursorPos; i++) {
  //     if (/\d/.test(input.value[i])) {
  //       digitsBeforeCursor++
  //     }
  //   }

  //   // Handle backspace
  //   if (e.key === 'Backspace') {
  //     e.preventDefault()

  //     if (digitsBeforeCursor > 0) {
  //       const newDigits = currentDigits.slice(0, digitsBeforeCursor - 1) + currentDigits.slice(digitsBeforeCursor)

  //       // Calculate new cursor position in formatted string
  //       let newPos = 0
  //       let digitCount = 0
  //       const formattedValue = formatPhoneNumber(newDigits, 'spaces_parentheses')

  //       for (let i = 0; i < formattedValue.length; i++) {
  //         if (digitCount === digitsBeforeCursor - 1) {
  //           newPos = i
  //           break
  //         }
  //         if (/\d/.test(formattedValue[i])) {
  //           digitCount++
  //         }
  //         newPos = i + 1
  //       }

  //       cursorRef.current = newPos
  //       setAddressData(prev => ({
  //         ...prev,
  //         address_phone: newDigits
  //       }))
  //     }
  //   }

  //   // Handle digit input
  //   else if (/^\d$/.test(e.key)) {
  //     e.preventDefault()

  //     if (currentDigits.length < 10) {
  //       const newDigits = currentDigits.slice(0, digitsBeforeCursor) + e.key + currentDigits.slice(digitsBeforeCursor)

  //       // Calculate new cursor position in formatted string
  //       let newPos = 0
  //       let digitCount = 0
  //       const formattedValue = formatPhoneNumber(newDigits, 'spaces_parentheses')

  //       for (let i = 0; i < formattedValue.length; i++) {
  //         if (digitCount === digitsBeforeCursor + 1) {
  //           newPos = i
  //           break
  //         }
  //         if (/\d/.test(formattedValue[i])) {
  //           digitCount++
  //         }
  //         newPos = i + 1
  //       }

  //       cursorRef.current = newPos
  //       setAddressData(prev => ({
  //         ...prev,
  //         address_phone: newDigits
  //       }))
  //     }
  //   }

  //   // Handle delete key
  //   else if (e.key === 'Delete') {
  //     e.preventDefault()

  //     if (digitsBeforeCursor < currentDigits.length) {
  //       const newDigits = currentDigits.slice(0, digitsBeforeCursor) + currentDigits.slice(digitsBeforeCursor + 1)

  //       // Maintain same cursor position since we're deleting after cursor
  //       let newPos = 0
  //       let digitCount = 0
  //       const formattedValue = formatPhoneNumber(newDigits, 'spaces_parentheses')

  //       for (let i = 0; i < formattedValue.length; i++) {
  //         if (digitCount === digitsBeforeCursor) {
  //           newPos = i
  //           break
  //         }
  //         if (/\d/.test(formattedValue[i])) {
  //           digitCount++
  //         }
  //         newPos = i + 1
  //       }

  //       cursorRef.current = newPos
  //       setAddressData(prev => ({
  //         ...prev,
  //         address_phone: newDigits
  //       }))
  //     }
  //   }

  //   // Allow arrow keys, home, end, tab for navigation
  //   else if (!['ArrowLeft', 'ArrowRight', 'Home', 'End', 'Tab'].includes(e.key)) {
  //     e.preventDefault()
  //   }
  // }

  const handleChangePhone = value => {
    setFormErrors(prevErrors => ({
      ...prevErrors,
      address_phone: ''
    }))
    setAddressData(prev => ({ ...prev, address_phone: value }))
    return value
  }

  useEffect(() => {
    const countries = supportedCountries(portal.supported_countries)
    const phoneInputProps = {
      name: 'address_phone',
      limitMaxLength: true,
      type: 'tel'
    }
    if (portal.international_shipping) {
      setPhoneInputProps({
        ...phoneInputProps,
        international: true,
        countryCallingCodeEditable: false,
        countries: portal.international_shipping ? countries.map(country => country.country_code) : ['US'],
        withCountryCallingCode: true,
        defaultCountry: addressData.country_code ? addressData.country_code : 'US',
        onChange: handleChangePhone
      })
    } else {
      setPhoneInputProps({
        ...phoneInputProps,
        onChange: handleChangePhone,
        addInternationalOption: false,
        international: false,
        initialValueFormat: 'national',
        countrySelectComponent: () => null
      })
    }
  }, [portal.international_shipping, addressData.country_code, portal.supported_countries])

  const toggleAddress2 = () => setAddress2(!showAddress2)
  const handleMakeDefault = () => {
    setAddressData({ ...addressData, default_shipping: !addressData.default_shipping })
  }

  const handleChange = event => {
    const label = event.target.labels?.[0]?.textContent.trim() || event.target.name
    const { name, value } = event.target
    if (name === 'address_line_1' || name === 'address_line_2') {
      setAddressData(prevAddressData => ({
        ...prevAddressData,
        [name]: value
      }))

      const newErrors = validateAddressLines(
        name,
        name === 'address_line_1' ? value : addressData.address_line_1,
        name === 'address_line_2' ? value : addressData.address_line_2,
        formErrors
      )

      setFormErrors(newErrors)
      return
    }

    const error = validateField(name, value, portal.international_shipping, label)

    setFormErrors(prevErrors => ({
      ...prevErrors,
      [name]: error
    }))

    setAddressData(prevAddressData => ({
      ...prevAddressData,
      [name]: value
    }))
  }

  const handleChangeCountry = countryObject => {
    setFormErrors(prevErrors => ({
      ...prevErrors,
      city: '',
      zip_code: ''
    }))
    setAddressData({
      ...addressData,
      country: countryObject.name,
      country_code: countryObject.country_code
    })
  }

  const handleSaveAddress = address => {
    address.is_shipping = addressType === 'shipping'
    address.is_billing = addressType === 'billing'
    address.address_type = addressType
    createCustomerAddressNew({
      addressData: address,
      callback: afterCreateAddress
    })
  }

  const handleCreateAddress = () => {
    const labels = createLabels(selectedCountry)
    const isInternationalShipping = portal.international_shipping
    const { errors, isValid } = validateForm(addressData, isInternationalShipping, labels, formErrors)

    if (!isValid) {
      setFormErrors(errors)
      return
    }

    validateAddress({
      addressData,
      onSaveAddress: handleSaveAddress,
      callbackFunction: afterCreateAddress,
      isInternationalShipping: portal.international_shipping
    })
  }

  const countries = supportedCountries(portal.supported_countries)
  const selectedCountry = countries.find(c => c.country_code === addressData.country_code) || {
    labels: {
      postcode: 'Postal Code',
      region: 'State / Region',
      city: 'Town / City'
    }
  }

  return (
    <Form style={{ paddingRight: '15px' }}>
      <br />

      <TextInputField
        fieldName="full_name"
        value={addressData.full_name}
        handleChange={handleChange}
        error={formErrors['full_name'] ?? null}
      />

      <FormGroup row>
        <Label sm={4} className="address-format-label">{`Phone`}</Label>
        <Col sm={8} style={{ alignSelf: 'center' }}>
          <PhoneInput {...phoneInputProps} value={addressData.address_phone} />
          {/* {portal.international_shipping ? (
						<PhoneInput {...phoneInputProps} value={addressData.address_phone} />
          ) : (
            <PhoneInput {...phoneInputProps} onChange={handleChangePhone} value={addressData.address_phone} />
            // <Input
            //   type="tel"
            //   innerRef={inputRef}
            //   {...phoneInputProps}
            //   value={formatPhoneNumber(addressData.address_phone, 'spaces_parentheses')}
            //   onKeyDown={handleKeyDown}
            //   onChange={() => {}}
            // />
          )} */}
          {formErrors.address_phone && <span className="text-danger small">{formErrors.address_phone}</span>}
        </Col>
      </FormGroup>

      {/* Country Select */}
      <FormGroup row>
        <Label sm={4} className="address-format-label" for="country">
          Country
        </Label>
        <Col sm={8} style={{ alignSelf: 'center' }}>
          <CountrySelect handleChange={handleChangeCountry} countryCode={addressData?.country_code} />
        </Col>
      </FormGroup>

      <TextInputField
        fieldName="company_name"
        value={addressData.company_name}
        handleChange={handleChange}
        error={formErrors['company_name'] ?? null}
      />

      <TextInputField
        fieldName="address_line_1"
        value={addressData.address_line_1}
        handleChange={handleChange}
        error={formErrors['address_line_1'] ?? null}
      />

      {showAddress2 ? null : (
        <FormGroup row>
          <Col sm={4}></Col>
          <Col sm={8} style={{ alignSelf: 'center' }}>
            <p onClick={toggleAddress2} style={{ cursor: 'pointer', marginBottom: 0 }}>
              <Plus size={16} />
              &nbsp;&nbsp;<span style={{ textDecoration: 'underline' }}>Add Address Line 2</span>
            </p>
          </Col>
        </FormGroup>
      )}

      {showAddress2 ? (
        <TextInputField
          fieldName="address_line_2"
          value={addressData.address_line_2}
          handleChange={handleChange}
          error={formErrors['address_line_2'] ?? null}
          disable={formErrors['address_line_1'] ? true : false}
        />
      ) : null}

      <TextInputField
        fieldName="zip_code"
        label={selectedCountry.labels.postcode}
        value={addressData.zip_code}
        handleChange={handleChange}
        error={formErrors['zip_code'] ?? null}
      />
      <TextInputField
        fieldName="city"
        label={selectedCountry.labels.city}
        value={addressData.city}
        handleChange={handleChange}
        error={formErrors['city'] ?? null}
      />

      {/* State/Province Select */}
      {selectedCountry.labels.region === 'N/A' ? null : (
        <FormGroup row>
          <Label sm={4} className="address-format-label" for="state">
            {selectedCountry.labels.region}
          </Label>
          <Col sm={8} style={{ alignSelf: 'center' }}>
            <StateProvinceSelect
              countryCode={addressData.country_code}
              handleChangeState={stateProvince => setAddressData({ ...addressData, state: stateProvince.value })}
              stateValue={addressData.state}
            />
          </Col>
        </FormGroup>
      )}

      {addressType === 'delivery' ? (
        <FormGroup row>
          <Col sm={4}></Col>
          <Col sm={8}>
            <InputGroup style={{ float: 'right', width: 'auto' }}>
              <Label check className="address-format-label">
                <Input type="checkbox" onChange={handleMakeDefault} value={addressData.default_shipping} />
                Set as Default Shipping Address
              </Label>
            </InputGroup>
          </Col>
        </FormGroup>
      ) : null}

      <FormGroup row>
        <Col sm={12} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
          <Button className="mf-primary-btn" onClick={handleCreateAddress}>
            Save Address
          </Button>
          {newAddressMode ? (
            <>
              &nbsp;&nbsp;&nbsp;
              <Button className="mf-outline-btn" onClick={() => setNewAddressMode(false)}>
                Cancel
              </Button>
            </>
          ) : null}
        </Col>
      </FormGroup>
    </Form>
  )
}

const mapStateToProps = state => {
  return {
    userHasNoAddresses: state.checkout.userHasNoAddresses,
    portal: state.portal,
    currentUser: state.currentUser
  }
}

// dafult value for addresstype is delivery
NewAddressForm.propTypes = {
  addressType: PropTypes.string,
  newAddressMode: PropTypes.bool,
  portal: PropTypes.shape({
    international_shipping: PropTypes.bool.isRequired,
    supported_countries: PropTypes.array.isRequired
  }).isRequired,
  setNewAddressMode: PropTypes.func,
  afterCreateAddress: PropTypes.func,
  validateAddress: PropTypes.func,
  createCustomerAddressNew: PropTypes.func
}

export default connect(mapStateToProps, { validateAddress, createCustomerAddressNew })(NewAddressForm)
