import React from 'react'
import { Row, Col } from 'reactstrap'
import { Button } from '@material-ui/core'
import { Link } from 'react-router-dom'
import CountDownTimer from './CountDownTimer'
import ContentLoader from '../../components/global/loaders/ContentLoader'
import dollar_price from '../general/DollarPrice'
export default class BestDeal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      previewImage: ''
    }
  }

  componentDidMount() {
    let currDate = new Date()
    let currTimestamp = currDate.getTime()
    if ((new Date(this.props.block.content.product.sale_price_to_date_and_time).getTime() - currTimestamp) / 1000 < 1) {
      this.setState({ time: 1 })
    } else {
      this.setState({
        time: (new Date(this.props.block.content.product.sale_price_to_date_and_time).getTime() - currTimestamp) / 1000
      })
    }

    if (this.props.block.content.product_images[0]) {
      this.setState({ previewImage: this.props.block.content.product_images[0].file.url })
    }
  }

  changePreviewImage(image) {
    this.setState({ previewImage: image })
  }

  render() {
    if (!this.state.time) return <ContentLoader />

    return (
      <div className="iron-best-deal-wrap">
        <Row spacing={10}>
          <Col xs={12} sm={12} md={6} lg={6}>
            <div className="deal-preview-image-wrap">
              <div className="preview-image-item preview-full-image">
                <div>
                  <Link to={'/product/' + this.props.block.content.product.id + '/' + this.props.portal.id}>
                    <img src={this.state.previewImage} alt="Deal Image" />
                  </Link>
                </div>
              </div>
            </div>
          </Col>
          <Col xs={12} sm={12} md={6} lg={6} className="pl-50">
            <div className="detail-content mb-50">
              <h2 className="font-normal">{this.props.block.block.title}</h2>
              <h5 className="font-normal">
                <Link
                  to={'/product/' + this.props.block.content.product.id + '/' + this.props.portal.id}
                  className="d-block"
                >
                  {this.props.block.content.product.name}
                </Link>
              </h5>
              <span className="d-block mb-20 text-14">
                <del className="mr-10">{dollar_price(this.props.block.content.product.price, 2)}</del>
                <span className="active-color">
                  Now Only {dollar_price(this.props.block.content.product.sale_price, 2)}
                </span>
              </span>
              <div
                className="mb-20"
                dangerouslySetInnerHTML={{
                  __html: this.props.block.content.product.description
                }}
              ></div>
              <div className="mb-20">
                <CountDownTimer time={this.state.time}></CountDownTimer>
              </div>
              <Link
                to={'/product/' + this.props.block.content.product.id + '/' + this.props.portal.id}
                className="d-inline-block"
              >
                <Button className="button btn-active">shop Now</Button>
              </Link>
            </div>
            <div className="deal-preview-image-nav">
              {this.props.block.content.product_images.map((productImage, index) => {
                return (
                  <div
                    key={index}
                    className={`preview-image-item ${this.state.previewImage === productImage.file.url ? 'active' : ''}`}
                  >
                    <div>
                      <a href="javascript:void(0)" onClick={() => this.changePreviewImage(productImage.file.url)}>
                        <img src={productImage.file.url} alt="best-deal" />
                      </a>
                    </div>
                  </div>
                )
              })}
            </div>
          </Col>
        </Row>
      </div>
    )
  }
}
