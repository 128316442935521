import _ from 'lodash'
import { PROOFING_PREVIEW } from '../actions/types'

const initialState = {
  full_pdf: {
    url: ''
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case PROOFING_PREVIEW:
      return action.payload
    case 'CLEAR_PROOFING_PREVIEW':
      return initialState
    default:
      return state
  }
}
