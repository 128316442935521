export default (state = {}, action) => {
  switch (action.type) {
    case 'SET_SESSION_STATUS':
      return action.payload
    case 'CLEAR_STATUS':
      return {}
    default:
      return state
  }
}
