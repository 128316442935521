/* eslint-disable */
import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'

const InlineContentLoader2 = () => (
  <div className="iron-progress-bar-inline d-flex justify-content-center align-items-center">
    <CircularProgress size={70} thickness={1.5} />
  </div>
)

export default InlineContentLoader2
