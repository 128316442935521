import { formatSingleProduct } from '../helpers/productPageFormatter'
import { calculateFinalProductPrice } from '../components/Shop/productPageFunctions'
import { resolvePrice, resolveUnitPrice } from '../components/Checkout/checkoutFunctions'
import { coopEnabledOnProductPortalAndLocation, coopDeductionCents } from '../components/Shop/productPageFunctions'

// takes API JSON response (array) and formats to cart items array for redux store
export const formatCartItems = (apiResponse, portal, location) => {
  let anyProductsFiltered = false
  let filteredProducts = []

  const formattedItems = apiResponse.reduce((acc, { data }) => {
    const { flags, nested_data } = data || {}
    const shouldShowOnCart = flags?.show_on_cart

    let bundleProducts
    // For bundles, products may be in bundle groups
    if (data.nested_data && data.nested_data[0] && data.nested_data[0].control === 'c10.1 - bundle group') {
      // grab products from all groups
      bundleProducts = data.nested_data.reduce((acc, group) => {
        return [...acc, ...group.bundle_group_products]
      }, [])
    } else {
      bundleProducts = Array.isArray(nested_data) ? nested_data.filter(i => i.bundle_group_product_id) : []
    }

    if (!shouldShowOnCart || (bundleProducts.length > 0 && bundleProducts.some(prod => prod.show_on_cart === false))) {
      anyProductsFiltered = true
      filteredProducts.push(data)
      return acc
    }

    const formattedProduct = formatSingleProduct(data, { forCart: true })
    return [...acc, formattedProduct]
  }, [])

  const alertFilteredProducts = () => {
    const existingFilteredProducts = localStorage.getItem('filteredCartItems')
      ? JSON.parse(localStorage.getItem('filteredCartItems'))
      : []
    if (!existingFilteredProducts || existingFilteredProducts.length !== filteredProducts.length) {
      localStorage.setItem('filteredCartItems', JSON.stringify(filteredProducts))
      window.alert(
        `${filteredProducts.length} product(s) in your cart are no longer available for purchase. For your convenience they have been automatically removed from your cart. Please contact our customer service team if you have any questions.`
      )
    }
  }

  if (window.location.href.includes('/cart') && anyProductsFiltered) {
    alertFilteredProducts()
  } else {
    localStorage.setItem('filteredCartItems', '')
  }

  return addPriceDataToCartItems(formattedItems, portal, location)
}

// returns cartItems with their priceData added
// maps each cart item to itself + priceData object
const addPriceDataToCartItems = (cartItems, portal, location) => {
  return cartItems.map(cartItem => {
    const thisProductTemplateFieldsWithValues = cartItem.template ? cartItem.template.template_fields : null

    let priceData = {}

    // For breakable bundles, first add price data to all of the child products, then to the BB itself
    if (cartItem.flags.is_bundle && cartItem.flags.bundle_type === 'Breakable') {
      const bundleProductsWithPriceData = addPriceDataToBundleProducts(
        cartItem.bundle.products,
        portal,
        location,
        cartItems,
        cartItem.quantity
      )
      // now that each child product has price data, we cn run price calculation on BB
      priceData = resolveBreakablePriceData(bundleProductsWithPriceData, cartItem, portal, location)
      priceData.subtotal = priceData.unit_price * cartItem.quantity

      cartItem.bundle.products = bundleProductsWithPriceData
    } else {
      priceData = calculateFinalProductPrice(
        cartItem,
        cartItem.quantity,
        {},
        thisProductTemplateFieldsWithValues,
        cartItems,
        portal,
        location,
        null,
        false,
        1,
        true
      )
    }

    return {
      ...cartItem,
      priceData: priceData
    }
  })
}

export const resolveBreakablePriceData = (bundleProducts, bundle, portal, location) => {
  const sumOfAllSubtotals = bundleProducts.reduce(
    (memo, prod) => resolvePrice(prod.priceData, false, false, true).result + memo,
    0
  )

  const sumOfAllSetupCharges = bundleProducts.reduce(
    (memo, prod) => resolvePrice(prod.priceData, false, false, true).setupCharge + memo,
    0
  )
  const finalBundleUnitPrice = sumOfAllSubtotals + (bundle.bundle_price_diff ? bundle.bundle_price_diff : 0)

  let result = {
    unit_price: finalBundleUnitPrice,
    setup_charge: sumOfAllSetupCharges
  }

  // if bundle has coop, include coop totals
  // ** COOP PRICE **
  if (coopEnabledOnProductPortalAndLocation(bundle, portal, location)) {
    let coopPercent = portal.coop_percentage_100

    if (location.coop_overwrite === true) {
      coopPercent = location.coop_percentage_100
    }

    const totalUnitPrice = result.unit_price

    const coop_deduction_cents = coopDeductionCents(totalUnitPrice, coopPercent)
    const coopUnitPrice = totalUnitPrice - coop_deduction_cents

    result = {
      ...result,
      coop_enabled: true,
      coop_unit_price: coopUnitPrice,
      coop_subtotal: coopUnitPrice * bundle.quantity,
      coop_deduction_cents: coop_deduction_cents
    }
  }

  return result
}

// pass in array of products, function returns same array of objects with priceData appended to each
export const addPriceDataToBundleProducts = (
  bundleProducts,
  portal,
  location,
  allCartItems,
  parentBundleQty,
  selectedOptions,
  templateFieldsWithValues,
  forCartItems
) => {
  return bundleProducts.map(bundleProduct => {
    return addPriceDataToBundleProduct(
      bundleProduct,
      portal,
      location,
      allCartItems,
      parentBundleQty,
      selectedOptions,
      templateFieldsWithValues,
      forCartItems
    )
  })
}

const addPriceDataToBundleProduct = (
  bundleProduct,
  portal,
  location,
  allCartItems,
  parentBundleQty,
  selectedOptions,
  templateFieldsWithValues,
  forCartItems = true
) => {
  const priceData = calculateFinalProductPrice(
    bundleProduct,
    bundleProduct.product_quantity,
    selectedOptions ? selectedOptions : {},
    bundleProduct.template &&
      !_.isEmpty(bundleProduct.template) &&
      Object.keys(bundleProduct.template[0]).includes('value')
      ? bundleProduct.template.template_fields
      : templateFieldsWithValues,
    allCartItems,
    portal,
    location,
    parentBundleQty,
    false,
    1,
    forCartItems
  )
  return { ...bundleProduct, priceData }
}

const clearAppliedCredits = cartItems => {
  return cartItems.map(cartItem => {
    return {
      ...cartItem,
      priceData: {
        ...cartItem.priceData,
        appliedCredits: {}
      }
    }
  })
}

// accepts cart items, returns cart items with updated priceData containing applied credits
export const applyCoopCreditsToCartItems = (cartItems, coopCredits) => {
  // first, clear all discounts to start from a clean slate
  cartItems = clearAppliedCredits(cartItems)

  // get the desired credits to be used
  let coopCreditsToBeApplied = coopCredits

  return cartItems.map(cartItem => {
    const newPriceData = {
      ...cartItem.priceData,
      appliedCredits: {}
    }

    // if there is a discount applied to subtotal, caluclate amount to apply to each product
    // (this should always be 0 if on cart page)
    const perProductDiscount = 0

    // * Co-Op *
    // If user wants to apply coop credits and priceData has coop_deduction_cents, (is eligible)
    // apply as many coopcredits as possible
    if (coopCreditsToBeApplied && newPriceData.coop_deduction_cents) {
      // get the total amount possible that can be applied
      let applicableCoopDeduction = newPriceData.coop_deduction_cents

      // If a discount is present, use the adjusted price and coop deduction
      if (perProductDiscount || (cartItem.priceData.discounts && cartItem.priceData.discounts.discount)) {
        applicableCoopDeduction = newPriceData.adjusted_coop_deduction_cents
      }

      applicableCoopDeduction = applicableCoopDeduction * cartItem.quantity
      // if we have enough for the full deduction, apply it
      if (coopCreditsToBeApplied >= applicableCoopDeduction) {
        coopCreditsToBeApplied -= applicableCoopDeduction
        newPriceData.appliedCredits.coop = applicableCoopDeduction
      } else {
        // else apply our remaining credit
        newPriceData.appliedCredits.coop = coopCreditsToBeApplied
        coopCreditsToBeApplied -= coopCreditsToBeApplied
      }
    }

    return {
      ...cartItem,
      priceData: newPriceData
    }
  })
}
