import { productHasError } from '../components/Cart/cartFunctions'
import { calculateFinalProductPrice } from '../components/Shop/productPageFunctions'

const checkFlags = (productData, flags) => {
  const { is_artwork, is_bundle, is_configurable, is_digital_proofing, is_simple } = flags
  // if more than one flag is true, throw error
  if (
    [is_artwork, is_bundle, is_configurable, is_digital_proofing, is_simple].filter(flag => flag === true).length > 1
  ) {
    console.error('Error: Multiple flags on product', productData)
    throw 'error - multiple flags'
  }
}

export const formatSingleProduct = (apiReponse, options = {}) => {
  let thisItemResult = {}

  const productData = apiReponse.product
  const flags = apiReponse.flags
  const nestedData = apiReponse.nested_data

  checkFlags(productData, flags)

  if (flags.is_digital_proofing && flags.is_artwork) {
    throw 'error - multiple flags: is_digital_proofing & is_artwork'
  }

  if (flags.is_simple) {
    thisItemResult = productData
  } else if (flags.is_bundle) {
    const bundleGroups = nestedData
    if (options.forCart === true) {
      // Once in cart, for BB, there will only be one bundle group - extract products
      // For UB, there will be no bundle groups - nestedData is the products
      let bundleGroupProducts
      if (flags.bundle_type !== 'Unbreakable') {
        // grab products from all groups
        bundleGroupProducts = bundleGroups.reduce((acc, group) => {
          return [...acc, ...group.bundle_group_products]
        }, [])
      } else {
        bundleGroupProducts = nestedData
      }
      thisItemResult = formatBundleCartItem(productData, bundleGroupProducts)
    } else {
      thisItemResult = formatBundle(productData, bundleGroups)
    }
  } else if (flags.is_configurable) {
    thisItemResult = formatConfigurableProduct(productData, nestedData)
  } else if (flags.is_digital_proofing) {
    thisItemResult = formatDigitalProofingProduct(productData, nestedData)
  } else if (flags.is_artwork) {
    thisItemResult = formatArtworkProduct(productData, nestedData)
  } else {
    console.info('Error: Unknown Product type')
  }

  // add images
  thisItemResult.images = formatImageData(productData.images)

  // add flags
  thisItemResult.flags = flags

  // add template data if present
  // not necessary for shop page
  if (flags.has_template && options.forShopPage !== true && apiReponse.template.length > 0) {
    thisItemResult = addTemplateData(thisItemResult, apiReponse)
  }

  // add tier price data if present
  if (flags.has_tier_price) {
    thisItemResult = addTierPriceData(thisItemResult, apiReponse)
  }

  const productWithError = productHasError(thisItemResult)

  return productWithError ? productWithError : thisItemResult
}

const formatBundleGroup = bundleGroup => {
  return {
    ...bundleGroup,
    products: formatBundleProducts(bundleGroup.bundle_group_products)
  }
}

// input breakable bundle and its bundle groups
const formatBundle = (productData, bundleGroups) => {
  const breakableBundleResult = {
    ...productData,
    bundle: {
      products: [],
      bundleGroups: []
    }
  }

  // map bundleGroups to themselves with their products, formatted
  const formattedBundleGroups = bundleGroups.map(bundleGroup => {
    return formatBundleGroup(bundleGroup)
  })

  breakableBundleResult.bundle.bundleGroups = formattedBundleGroups

  // add all products from simple groups to bundle's products key
  const simpleGroups = formattedBundleGroups.filter(group => group.bundle_group_type === 'simple_group')
  const productsFromSimpleGroups = simpleGroups.reduce((prods, group) => [...prods, ...group.products], [])

  // set products to that group's products
  breakableBundleResult.bundle.products = productsFromSimpleGroups

  return breakableBundleResult
}

// BB in cart will not have groups
const formatBundleCartItem = (productData, bundleProducts) => {
  return {
    ...productData,
    bundle: {
      products: formatBundleProducts(bundleProducts)
    }
  }
}

const formatBundleProducts = bundleProducts => {
  return bundleProducts.map(bundleProduct => {
    // Check if product has nested_data
    if (bundleProduct.nested_data && bundleProduct.nested_data.length > 0) {
      // if so, categorize the nested data. it could be artwork, configs, templates, etc
      // if (Object.keys(bundleProduct.nested_data[0]).includes('control')) {
      //   // bundle product nested data is config data
      //   return formatConfigurableProduct(bundleProduct, bundleProduct.nested_data[0].nested_data)
      // }
      const productArtworkData = bundleProduct.nested_data.find(el => el.artwork_id)
      const productConfigData = bundleProduct.nested_data.find(el => el.variant_id)
      if (productArtworkData) {
        // product is an artwork
        return formatArtworkProduct(bundleProduct, productArtworkData)
      } else if (productConfigData) {
        // product is a configurable
        return formatConfigurableProduct(bundleProduct, bundleProduct.nested_data)
      } else {
        return bundleProduct
      }
    } else {
      return bundleProduct
    }
  })
}

const formatConfigurableProduct = (productData, configVariants) => {
  return {
    ...productData,
    variants: configVariants
  }
}

const formatDigitalProofingProduct = (productData, nestedData) => {
  return {
    ...productData,
    // assigned this key to make it work with existing components
    // product_artworks: nestedData[0].nested_data,
    digitalProofing: {
      ...nestedData,
      nested_data: nestedData.nested_data,
      product_id: nestedData.product_id
    }
  }
}

const formatArtworkProduct = (productData, nestedData) => {
  return {
    ...productData,
    artwork: nestedData
  }
}

const formatImageData = imagedataArray => {
  return imagedataArray.map(imagedata => {
    const imageObject = imagedata.image
    if (imageObject) {
      return {
        small: imagedata.url,
        medium: imagedata.url,
        original: imagedata.url
      }
    }
  })
}

const addTemplateData = (cartItem, dataPoint) => {
  return {
    ...cartItem,
    template: {
      id: dataPoint.template ? dataPoint.template[0].template_id : null,
      template_fields: dataPoint.template.map(templateFieldData => {
        return {
          ...templateFieldData,
          nested_data: templateFieldData.nested_data ? templateFieldData.nested_data : []
        }
      })
    }
  }
}

const addTierPriceData = (cartItem, dataPoint) => {
  return {
    ...cartItem,
    tier_prices: dataPoint.tier_pricing
  }
}
