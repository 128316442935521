// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pager-container {
  display: flex;
  gap: 2rem;
  align-items: center;
  padding: 0.25rem;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
}

.pager-container nav ul {
  margin: 0;
}

.pager-container nav ul li.page-item button {
  border-radius: 999px !important;
  color: currentColor !important;
  font-size: 0.875em;
  border: none;
  background-color: transparent;
  width: 38px;
  height: 38px;
  white-space: nowrap;
}

.pager-container nav ul li.page-item.disabled button {
  background-color: transparent;
  filter: opacity(0.5);
}

.pager-container nav ul li.page-item.active button {
  background-color: var(--mf-secondary-color);
  color: #fff !important;
  box-shadow:
    0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.pager-container nav ul li button:hover,
.pager-container nav ul li button:focus {
  background-color: #e2e0e0;
  box-shadow: none;
}

.pager-container span.pager-range {
  font-size: 0.875em;
}
`, "",{"version":3,"sources":["webpack://./src/components/Pager/pager.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,SAAS;EACT,mBAAmB;EACnB,gBAAgB;EAChB,yBAAyB;EACzB,sBAAsB;AACxB;;AAEA;EACE,SAAS;AACX;;AAEA;EACE,+BAA+B;EAC/B,8BAA8B;EAC9B,kBAAkB;EAClB,YAAY;EACZ,6BAA6B;EAC7B,WAAW;EACX,YAAY;EACZ,mBAAmB;AACrB;;AAEA;EACE,6BAA6B;EAC7B,oBAAoB;AACtB;;AAEA;EACE,2CAA2C;EAC3C,sBAAsB;EACtB;;;mCAGiC;AACnC;;AAEA;;EAEE,yBAAyB;EACzB,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":[".pager-container {\n  display: flex;\n  gap: 2rem;\n  align-items: center;\n  padding: 0.25rem;\n  border: 1px solid #dee2e6;\n  border-radius: 0.25rem;\n}\n\n.pager-container nav ul {\n  margin: 0;\n}\n\n.pager-container nav ul li.page-item button {\n  border-radius: 999px !important;\n  color: currentColor !important;\n  font-size: 0.875em;\n  border: none;\n  background-color: transparent;\n  width: 38px;\n  height: 38px;\n  white-space: nowrap;\n}\n\n.pager-container nav ul li.page-item.disabled button {\n  background-color: transparent;\n  filter: opacity(0.5);\n}\n\n.pager-container nav ul li.page-item.active button {\n  background-color: var(--mf-secondary-color);\n  color: #fff !important;\n  box-shadow:\n    0 3px 1px -2px rgba(0, 0, 0, 0.2),\n    0 2px 2px 0 rgba(0, 0, 0, 0.14),\n    0 1px 5px 0 rgba(0, 0, 0, 0.12);\n}\n\n.pager-container nav ul li button:hover,\n.pager-container nav ul li button:focus {\n  background-color: #e2e0e0;\n  box-shadow: none;\n}\n\n.pager-container span.pager-range {\n  font-size: 0.875em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
