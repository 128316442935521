import { state_list } from '../components/general/stateList'

// takes a US state full name and returns its abbreviation
export const getAbbreviatedName = stateFullName => {
  let str = stateFullName.split(' ')
  let formattedStateName
  for (let i = 0; i < str.length; i++) {
    str[i] = str[i][0].toUpperCase() + str[i].substr(1)
  }
  formattedStateName = str.join(' ')
  let abbreviated = Object.keys(state_list).filter(key => state_list[key] === formattedStateName)
  return abbreviated[0]
}

export const buildSkuSuffix = (variants, selections) => {
  const sortedVariants = variants //.sort( v => v.order)
  // map each variant to its sku based on the user selection
  const sortedSkuCodes = sortedVariants
    .map(variant => {
      // find the user selection. If user has not made one, return null
      const selectedOption = selections[variant.variant_id]
      if (!selectedOption) {
        return null
      } else {
        const skuCode = selectedOption.sku_code
        return skuCode
      }
    })
    .filter(skuCode => skuCode !== null) // remove null values

  // Build SKU suffix to be appended to base SKU
  // map each code to a formatted string with "-"
  let skuSuffix = sortedSkuCodes.map(skuCode => {
    return `-${skuCode}`
  })

  // concatenate the array of formatted codes to a single string and return
  skuSuffix = skuSuffix.join('')
  return skuSuffix
}
