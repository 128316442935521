import React, { useEffect, useState } from 'react'
import { Input, FormGroup, Row, Col } from 'reactstrap'
import { connect } from 'react-redux'
import dollar_price from '../general/DollarPrice'

const ProductTemplateField = ({
  field,
  templateFieldsSelection,
  updateProductTemplateField,
  updateBundleTemplateSelection,
  dependencyRequirementsMet,
  isBundle,
  bundleProduct,
  currentLocation
}) => {
  const [value, setValue] = useState('')
  const [multiSelection, setMultiSelection] = useState({})
  const [error, setError] = useState(false)
  const [dependenciesMet, setDependenciesMet] = useState(false)
  const [qtyBox, setQtyBox] = useState(1)
  const [optionId, setOptionId] = useState('')
  const [imageClicked, setImageClicked] = useState('')

  // If value changes, update Redux - for single-value
  useEffect(() => {
    const fieldWithValue = { ...field, value }
    if (isBundle) {
      const templateSelectionData = {
        template_field: fieldWithValue,
        bundle_group_product_id: bundleProduct.bundle_group_product_id
      }
      updateBundleTemplateSelection(templateSelectionData)
    } else {
      updateProductTemplateField(fieldWithValue)
    }
  }, [value])

  // If value changes, update Redux - for multi-select
  // (multiselect + checkbox only)
  useEffect(() => {
    const fieldWithValue = {
      ...field,
      value: multiSelection
    }
    if (isBundle) {
      updateBundleTemplateSelection({
        template_field: fieldWithValue,
        bundle_group_product_id: bundleProduct.bundle_group_product_id
      })
    } else {
      updateProductTemplateField(fieldWithValue)
    }
  }, [multiSelection])

  // if any fields change, check if this fields dependencies have been met
  useEffect(() => {
    if (dependencyRequirementsMet(field)) {
      setDependenciesMet(true)
    } else {
      setDependenciesMet(false)
    }
  }, [templateFieldsSelection])

  useEffect(() => {
    if (optionId && qtyBox) {
      setMultiSelection({ ...multiSelection, [optionId]: { ...multiSelection[optionId], qtyBoxNum: qtyBox } })
    }
  }, [qtyBox, optionId])

  // if fields are required and only have one option. auto select
  useEffect(() => {
    const acceptedInputTypes = ['dropdown', 'radio', 'image_selection']

    if (field.is_required && acceptedInputTypes.includes(field.input_type) && field.nested_data.length === 1) {
      handleInput(field.nested_data[0].template_field_option_id, true)
    }
  }, [])

  const validate = () => {
    const input_type = field.input_type

    if (
      input_type === 'input' ||
      input_type === 'textarea' ||
      input_type === 'date' ||
      input_type === 'dropdown' ||
      input_type === 'radio' ||
      input_type === 'image_selection'
    ) {
      !!value === false && value !== '' ? setError(true) : setError(false)
    } else if (input_type === 'multiselect' || input_type === 'checkbox') {
      Object.keys(value).length > 0 && Object.keys(value).map(key => value[key] === true)
        ? setError(true)
        : setError(false)
    }
  }

  const handleInput = (e, isAutoSelected = false) => {
    const input_type = field.input_type

    // required for autoselect of values in useEffect
    let value = isAutoSelected ? e : e.target.value

    if (input_type === 'input' || input_type === 'textarea' || input_type === 'date' || input_type === 'dropdown') {
      setValue(value)
    } else if (input_type === 'image_selection') {
      value = isAutoSelected ? e : e.currentTarget.value
      setValue(value)
      setImageClicked(value)
    } else if (input_type === 'multiselect' || input_type === 'checkbox' || input_type === 'radio') {
      handleMultiSelectInput(value)
    }
  }

  const handleMultiSelectInput = option_id => {
    // for radio buttons, only one value can be true
    if (field.input_type === 'radio') {
      const newMultiSelection = {
        [option_id]: { [option_id]: true }
      }
      setMultiSelection(newMultiSelection)
    } else {
      let ifChecked = multiSelection[option_id] && multiSelection[option_id][option_id] ? true : false
      setMultiSelection({ ...multiSelection, [option_id]: { ...multiSelection[option_id], [option_id]: !ifChecked } })
    }
  }

  // converts to reactstrap Input type to pass to Input component
  const inputType = () => {
    switch (field.input_type) {
      case 'input':
        return 'text'
      case 'dropdown':
      case 'multiselect':
        return 'select'
      case 'date':
        return 'datetime-local'
      default:
        return field.input_type
    }
  }

  const optionLabel = option => {
    let result = (
      <>
        {option.option_title}
        {parseInt(option.price, 10) > 0 ? (
          <>
            {' '}
            - {dollar_price(option.price)} {option.quantity ? `[QTY: ${option.quantity}]` : ''}
          </>
        ) : (
          ''
        )}
      </>
    )

    // if option has additional shipping price, display it
    const shippingFee = parseFloat(option.shipping_fee)
    if (shippingFee > 0) {
      result = (
        <>
          {result} - {(shippingFee / 100).toFixed(2)} Shipping Fee
        </>
      )
    }
    return result
  }

  const handleQtyChange = (e, option) => {
    setOptionId(option.template_field_option_id)
    setQtyBox(e.target.value)
  }

  const InputField = () => {
    // for fields with options (dropdown)
    const renderOptions = () => {
      const fieldOptions = field.nested_data
      const optionsTags = fieldOptions.map((option, i) => {
        return (
          <option key={i} value={option.template_field_option_id}>
            {optionLabel(option)}
          </option>
        )
      })

      optionsTags.unshift(
        <option key={-1} value="-1">
          Please Select
        </option>
      )
      return optionsTags
    }

    return (
      <Input
        onBlur={validate}
        className="mt-10"
        type={inputType()}
        name="field_input"
        value={value}
        onChange={handleInput}
      >
        {inputType() === 'select' ? renderOptions() : null}
      </Input>
    )
  }

  const FieldLabel = () => {
    return (
      <>
        <h5>{field.field_title}</h5>
        {field.description ? <label>{field.description}</label> : null}
      </>
    )
  }

  const ShowQtyBox = option => {
    return (
      <>
        {option.show_qty_box ? (
          <>
            <Input
              className="form-control"
              type="number"
              onChange={e => handleQtyChange(e, option)}
              defaultValue={option.quantity ? option.quantity : 1}
              min={1}
              style={{
                maxWidth: '35%',
                marginLeft: '10px',
                height: '30px',
                fontSize: '0.9rem'
              }}
            />
            <label className="mb-0">&nbsp;Qty</label>
          </>
        ) : null}
      </>
    )
  }

  const SwitchInputField = inputType => {
    const fieldOptions = field.nested_data.sort(
      (a, b) => (b.sort_order != null) - (a.sort_order != null) || a.sort_order - b.sort_order
    )

    switch (inputType) {
      case 'multiselect':
        return (
          <select multiple style={{ width: '100%' }}>
            {fieldOptions.map((option, i) => {
              const currentValue =
                templateFieldsSelection[field.template_field_id] &&
                templateFieldsSelection[field.template_field_id].value[option.template_field_option_id]
              const conditionalStyle = {
                backgroundColor: '#B4D5FE'
              }

              return (
                <option
                  className="noselect"
                  key={i}
                  value={option.template_field_option_id}
                  style={!!currentValue ? conditionalStyle : {}}
                  selected={!!currentValue}
                  onClick={handleInput}
                >
                  {optionLabel(option)}
                </option>
              )
            })}
          </select>
        )
      case 'image_selection':
        return (
          <>
            {imageSelectionHeader(fieldOptions)}
            <ul className="mt-2" style={{ display: 'flex', flexWrap: 'wrap' }}>
              {fieldOptions.map((option, i) => {
                return (
                  <div
                    key={i}
                    className="mr-2 mb-2"
                    style={{ width: '125px', height: '125px', backgroundColor: 'white', borderRadius: '10%' }}
                  >
                    <button
                      value={option.template_field_option_id}
                      key={i}
                      onClick={handleInput}
                      className={
                        option.template_field_option_id == imageClicked
                          ? 'template-image template-image-selected'
                          : 'template-image template-non-selected-image'
                      }
                      style={{ width: '125px', height: '125px' }}
                    >
                      <img
                        value={option.template_field_option_id}
                        max-width="auto"
                        max-height="auto"
                        src={option.image.initial.url}
                        style={{ borderRadius: '10%' }}
                      />
                    </button>
                  </div>
                )
              })}
            </ul>
          </>
        )
      case 'radio':
      case 'checkbox':
        return fieldOptions.map((option, i) => {
          let isChecked =
            !!multiSelection[option.template_field_option_id] &&
            !!multiSelection[option.template_field_option_id][option.template_field_option_id]
          return (
            <FormGroup
              check
              key={i}
              style={{ margin: '0', marginBottom: '0.5rem', display: 'flex', alignItems: 'center' }}
            >
              <Input
                type={field.input_type}
                name={`input_${field.template_field_id}`}
                onChange={handleInput}
                value={option.template_field_option_id}
                style={{ display: 'flex' }}
                checked={isChecked}
              />
              <div className="form-row center">
                <label className="mb-0">&nbsp;{option.option_title}</label>
                {ShowQtyBox(option)}
              </div>
            </FormGroup>
          )
        })
      default:
        return InputField()
    }
  }

  const formatExtraPrice = cents => {
    const value = dollar_price(cents)
    if (cents > 0) {
      return <> + {value}</>
    } else if (cents < 0) {
      return <> - {value}</>
    } else {
      return ''
    }
  }

  const imageSelectionHeader = fieldOptions => {
    const selectedValue = value ? fieldOptions.find(opt => opt.template_field_option_id == value) : null
    const extraPrice = value ? formatExtraPrice(selectedValue.price) : ''

    return (
      <>
        {!imageClicked ? (
          <b>Please Select An Image</b>
        ) : (
          <>
            <b>Image: </b>
            {selectedValue.option_title}
            {currentLocation.show_price ? extraPrice : null}
          </>
        )}
      </>
    )
  }

  return (
    <FormGroup style={{ margin: '0' }}>
      {dependenciesMet === true ? (
        <>
          {FieldLabel()}
          {SwitchInputField(field.input_type)}
        </>
      ) : null}
      {error ? <p className="text-danger mt-2 mb-0">Invalid input</p> : null}
    </FormGroup>
  )
}

;<hr className="w-100 ml-0" />

const mapDispatchToProps = dispatch => {
  return {
    updateProductTemplateField: data => dispatch({ type: 'UPDATE_PRODUCT_TEMPLATE_FIELD', payload: data }),
    updateBundleTemplateSelection: data => dispatch({ type: 'UPDATE_BUNDLE_TEMPLATE_FIELD', payload: data })
  }
}

const mapStateToProps = state => {
  return {
    currentLocation: state.currentLocation,
    templateFieldsSelection: state.productPage.templateFields
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductTemplateField)
