import { FETCH_ORDER_DETAILS } from '../actions/types'

export default (state = [], action) => {
  switch (action.type) {
    case FETCH_ORDER_DETAILS:
      return { ...state, [action.id]: action.payload }
    default:
      return state
  }
}
