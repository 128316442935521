import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { fetchPortalPage } from '../../actions'
import BestDeal from '../../components/widgets/BestDeal'
import GenericBlock from './GenericBlock'
import FaqBlock from './FaqBlock'
import BannerSliderThree from '../../components/widgets/BannerSliderThree'
import BannerSliderFour from '../../components/widgets/BannerSliderFour'
import CallToAction from '../../components/widgets/CallToAction'
import FeaturesV2 from '../../components/widgets/FeaturesV2'
import ContentLoader from '../../components/global/loaders/ContentLoader'
import _ from 'lodash'

const PortalPage = props => {
  const [coopPercent, setCoopPercent] = useState(0)

  useEffect(() => {
    const locationId = props.currentLocation.id
    if (props.location.state && props.location.state.page_id) {
      props.fetchPortalPage(props.location.state.page_id, locationId)
    } else {
      props.portal.pages.forEach(page => {
        if (page.active_url === props.match.path && page.location_id === props.currentLocation.id) {
          props.fetchPortalPage(page.id, locationId)
        }
      })
    }

    let calcCoopPercent = 0
    if (props.userCredits && props.userCredits.coop_credit && props.userCredits.coop_credit.percentage) {
      calcCoopPercent = props.userCredits.coop_credit.percentage / 100
    } else if (
      props.portal.coop_enabled &&
      props.portal.default_location &&
      props.portal.default_location.coop_enable
    ) {
      calcCoopPercent = props.portal.default_location.coop_overwrite
        ? props.portal.default_location.coop_percentage_100 / 100
        : props.portal.coop_percentage_100 / 100
    }
    setCoopPercent(calcCoopPercent)
  }, [])

  if (!props.page) {
    return <ContentLoader />
  }

  const currDate = new Date()
  const currTimestamp = currDate.getTime()

  return (
    <div>
      {props.page.page_content_blocks
        .sort((a, b) => a.order - b.order)
        .map(block => {
          switch (block.content_type) {
            case 'Slider':
              return (
                <div className="iron-home-v2-wrap">
                  <div className="iron-banner-wrapper py-30">
                    <div className="container">
                      <BannerSliderThree blockContent={block.child_content} />
                    </div>
                  </div>
                </div>
              )

            case 'LinkColumn':
              return (
                <div className="iron-home-v2-wrap">
                  <div className="iron-cta-wrapper py-30">
                    <div className="container">
                      <CallToAction CallToActionData={block.child_content.items} />
                    </div>
                  </div>
                </div>
              )

            case 'Feature':
              return (
                <div className="iron-home-v2-wrap">
                  <div className="iron-feature-wrapper py-30">
                    <div className="container">
                      <FeaturesV2 siteFeatures={block.child_content.items} />
                    </div>
                  </div>
                </div>
              )

            case 'Deal':
              if (
                props.customerGroup.id &&
                !props.customerGroup.hide_products &&
                block.child_content.product &&
                !props.customerGroup.hidden_products_list.find(p => p.id == block.child_content.product.id) &&
                (new Date(block.child_content.product.sale_price_to_date_and_time).getTime() - currTimestamp) / 1000 > 1
              ) {
                return (
                  <div className="iron-home-v2-wrap">
                    <div className="iron-banner-wrapper py-30">
                      <div className="container">
                        <BestDeal block={block} portal={props.portal} />
                      </div>
                    </div>
                  </div>
                )
              }
              break

            case 'ProductSlider':
              if (props.customerGroup.id && !props.customerGroup.hide_products) {
                let filtered_product_slider = block.child_content.items.filter(
                  item => item && !props.customerGroup.hidden_products_list.find(p => p.id == item.product.id)
                )
                let filtered_categories = []
                let fully_filtered_categories = []

                if (!block.child_content.hide_category_selector) {
                  filtered_categories = block.child_content.categories.filter(
                    cat =>
                      cat &&
                      !props.customerGroup.hidden_categories_list.find(c => c.id == cat.id) &&
                      cat.portal_id == props.portal.id
                  )
                  filtered_product_slider.forEach(product => {
                    product.categories.forEach(category => {
                      if (filtered_categories.find(c => c.id == category.id)) {
                        fully_filtered_categories.push(category)
                      }
                    })
                  })
                  fully_filtered_categories = _.uniqBy(fully_filtered_categories, 'id')
                }

                if (filtered_product_slider.length !== 0) {
                  return (
                    <div className="iron-home-v2-wrap">
                      <div className="iron-banner-wrapper py-30">
                        <div className="container">
                          <BannerSliderFour
                            title={block.title}
                            sliderData={filtered_product_slider}
                            portal={props.portal}
                            categories={fully_filtered_categories.sort((a, b) => a.name.localeCompare(b.name))}
                            hide_category_selector={block.child_content.hide_category_selector}
                            coopPercent={coopPercent}
                          />
                        </div>
                      </div>
                    </div>
                  )
                }
              }
              break

            case 'Generic':
              return (
                <div className="iron-home-v2-wrap py-30">
                  <GenericBlock content={block} />
                </div>
              )

            case 'FAQ':
              return (
                <div className="iron-home-v2-wrap py-30">
                  <div className="container">
                    <FaqBlock content={block} />
                  </div>
                </div>
              )

            default:
              return null
          }
        })}
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    portal: state.portal,
    customerGroup: state.customerGroup,
    userCredits: state.userCredits,
    currentLocation: state.currentLocation
  }
}

export default connect(mapStateToProps, { fetchPortalPage })(PortalPage)
