import * as Inputs from './form_fields'
import { Row, Col, Button, Container } from 'reactstrap'
import { useState, useEffect, useRef } from 'react'
import { toast } from 'react-toastify'
import { classNames } from 'classnames'
import validator from 'validator'

const initialValueMap = {
  text: '',
  date: '',
  phone: '',
  select: { value: '-1', label: 'Please Select' }
}

const DigitalProofingFormDynamic = props => {
  let artwork_fields = props.artwork.artwork_fields
  const sortedArtworkFields = _
    // .chain(props.artwork.artwork_fields)
    .chain(artwork_fields)
    .orderBy('form_sort_order')
    .uniqBy('name')
    .filter(field => field.is_active && !field.is_hidden)
    .filter(field => !(field.requires_coupon && props.selectedCoupon && props.selectedCoupon.is_no_coupon))
    .value()

  const [values, setValues] = useState({})
  const [errorMessage, setErrorMessage] = useState('Please complete all required fields')
  const [isFormValid, setIsFormValid] = useState(false)
  const [fieldErrors, setFieldErrors] = useState({})

  const validateUrl = value => {
    if (validator.isURL(value)) {
      if (value.length > 24) {
        return {
          valid: false,
          message: (
            <span>
              Sorry, this URL is too long.
              <br />
              Longer URLs generate more data, which can cause pixelation and scanning issues. Please use{' '}
              <a style={{ textDecoration: 'underline' }} href="https://free-url-shortener.rb.gy/" target="_blank">
                this link
              </a>{' '}
              to shorten your QR code.
            </span>
          )
        }
      }
      return { valid: true, message: null }
    } else {
      return { valid: false, message: `The URL ${value} is not a valid one.` }
    }
  }

  // set initial form values for each field
  useEffect(() => {
    const initialValues = {}
    sortedArtworkFields.forEach(field => {
      if (field.html_input_type === 'number' && field.min_value && typeof parseFloat(field.min_value) === 'number') {
        initialValues[field.name] = field.min_value
      } else if (field.html_input_type === 'date' && field.min_value) {
        let minDate = new Date()
        minDate.setDate(minDate.getDate() + field.min_value)
        initialValues[field.name] = minDate
      } else {
        initialValues[field.name] = initialValueMap[field.html_input_type]
      }
    })
    setValues(initialValues)
  }, [props.artwork.id])

  // validate fields
  useEffect(() => {
    let valid = false
    const errorMessages = {}
    // for any field that has a comparison,
    const fieldsWithComparisons = sortedArtworkFields.filter(field => field.comparison_field_name)
    valid = fieldsWithComparisons.every(field => {
      // check the comparison value and invalidate form if necessary
      const comparisonValue =
        typeof values[field.comparison_field_name] === 'object'
          ? values[field.comparison_field_name].value
          : values[field.comparison_field_name]
      const thisValue = typeof values[field.name] === 'object' ? values[field.name].value : values[field.name]
      // if either value is null/unselected, it is ok. wait until selection
      if (
        (!comparisonValue && comparisonValue !== 0) ||
        comparisonValue === '-1' ||
        (!thisValue && thisValue !== 0) ||
        thisValue === '-1'
      ) {
        return true
      }

      const operators = {
        '===': (a, b) => a === b,
        '<=': (a, b) => a <= b,
        '>=': (a, b) => a >= b,
        '<': (a, b) => a < b,
        '>': (a, b) => a > b
      }
      const operatorLabels = {
        '===': 'equal to',
        '<=': 'less than or equal to',
        '>=': 'greater than or equal to',
        '<': 'less than',
        '>': 'greater than'
      }
      const operator = field.comparison_operator
      const comparisonFn = operators[operator]
      const comparisonValid = comparisonFn(thisValue, comparisonValue)

      if (!comparisonValid) {
        const comparisonLabel = sortedArtworkFields.find(f => f.name === field.comparison_field_name).html_label

        errorMessages[field.name] = {
          message: `${field.html_label} must be ${operatorLabels[operator]} ${comparisonLabel}`,
          showInErrorSummary: true
        }

        toast.error(`"${field.html_label}" must be ${operatorLabels[operator]} "${comparisonLabel}"`, {
          toastId: 'comparisonError'
        })
        return false
      }
    })

    // if not valid at this point, set error
    if (!valid) {
      setFieldErrors({
        ...fieldErrors,
        errorMessages
      })
    }

    // check required fields and fields that require custom validation
    let allValid = false
    const fieldsValid = sortedArtworkFields.map(field => {
      const val = values[field.name]
      if (field.is_url && val && val.length > 0) {
        const validation = validateUrl(val)
        if (!validation.valid) {
          setFieldErrors({
            ...fieldErrors,
            [field.name]: {
              message: validation.message,
              showInErrorSummary: false
            }
          })
          return false
        } else {
          setFieldErrors(prevFieldErrors => {
            const { [field.name]: _, ...newFieldErrors } = prevFieldErrors
            return newFieldErrors
          })
          return true
        }
      }
      // Check all required fields have a value
      if (field.is_required) {
        return (val !== '' && val !== '-1') || (typeof val === 'object' && val.value !== '-1')
      }
      return true
    })

    allValid = fieldsValid.every(valid => valid)

    if (allValid) {
      valid = true
    } else if (props.artwork.show_no_fields == true) {
      valid = true
    } else {
      valid = false
    }
    setIsFormValid(valid)

    if (valid) {
      setFieldErrors({})
    }
  }, [values])

  // set error message according for form validity
  useEffect(() => {
    if (!isFormValid) {
      setErrorMessage('Please complete all required fields')
    } else {
      setErrorMessage('')
    }
  }, [isFormValid])

  const inputMap = {
    date: Inputs.ArtworkDateInput,
    text: Inputs.ArtworkTextInput,
    address_1_line_1: Inputs.ArtworkAddressLine1Fields,
    phone: Inputs.ArtworkPhoneInput,
    select: Inputs.ArtworkSelectInput,
    number: Inputs.ArtworkNumberInput
  }

  const handleChange = (value, field) => {
    if (field.max_length && value.toString().length > field.max_length) {
      alert('Maximum length for this field is ' + field.max_length + ' characters')
      return
    }
    let textValue = value
    if (field.is_url) {
      if (value.startsWith('https://')) {
        textValue = value
      } else {
        textValue = 'https://' + value
      }
    }
    setValues({
      ...values,
      [field.name]: textValue
    })
  }

  const handlePreviewProof = fields => {
    // for each named field, append to form data
    const formData = new FormData()
    const formValues = {}
    sortedArtworkFields.forEach(field => {
      formValues[field.name] = values[field.name]
    })

    formData.append('formValues', JSON.stringify(formValues))

    // submit form data
    props.complete(formData)
  }

  // hide fields if needs a coupon and none selected
  if (props.requiresCoupon && props.selectedCouponId === '-1') return null
  if (props.requiresAddOn && props.selectedAddOnId === '-1') return null
  if (props.requiresAddOn2 && props.selectedAddOnId2 === '-1') return null

  // render an input field for each artwork_field
  return (
    <Container>
      <Row>
        {sortedArtworkFields.map(field => {
          if (!field.is_active) return null
          const value = values[field.name] ? values[field.name] : ''
          const InputComponent = inputMap[field.html_input_type]
          // }
          if (InputComponent) {
            // if field is a bullet, don't show unless previous bullet has been filled
            if (field.is_bullet && field.name !== 'bullet_01') {
              const previousBulletNumber = parseInt(field.name.split('_')[1]) - 1
              const previousBulletValue = values[`bullet_0${previousBulletNumber}`]

              // if previous bullet field is empty, return null to hide field.
              if (!previousBulletValue) {
                // additionally, if a value was already entered for this field, shift it up.
                if (value) {
                  setValues({
                    ...values,
                    [`bullet_0${previousBulletNumber}`]: value,
                    [field.name]: ''
                  })
                }
                return null
              }
            }
            return (
              <Col key={field.id} sm={field.form_field_width ? field.form_field_width : 12}>
                {InputComponent(field, value, handleChange, values)}
                {field.is_url === true ? (
                  <div>
                    <p className="small-help">&nbsp;Please enter a valid URL, example: https://www.google.com</p>
                    <p className="red-text">
                      {value.length > 0 && fieldErrors[field.name] && fieldErrors[field.name]['message']}
                    </p>
                  </div>
                ) : (
                  <br></br>
                )}
              </Col>
            )
          } else {
            return null
          }
        })}
      </Row>

      <Row>
        <Col xs={12}>
          <br />
          <Button
            className="button btn-active btn-md mr-15 mb-20 mb-sm-0"
            onClick={handlePreviewProof}
            disabled={
              // button is disabled if there is an error message or if form is not valid
              !!errorMessage || isFormValid === false || Object.values(fieldErrors).length > 0
            }
          >
            Preview Proof
          </Button>
          <p>
            <small>
              While the preview image is set to a lower resolution, the final printable version will be in high
              resolution.
            </small>
          </p>
          <br />
          <p className="red-text">{errorMessage}</p>
          {Object.values(fieldErrors).map(
            (error, index) =>
              error.showInErrorSummary && (
                <p className="red-text" key={index}>
                  {error.message}
                </p>
              )
          )}
        </Col>
      </Row>
    </Container>
  )
}

export default DigitalProofingFormDynamic
