import { useState, useEffect } from 'react'
import { Input, Label, Row, Col, Button } from 'reactstrap'
import { connect } from 'react-redux'
import _ from 'lodash'
import { isArrayUnique } from '../../../util/utils'

const DigitalProofingForm = props => {
  const [numberOfAddresses, setNumberOfAddresses] = useState(4)
  const [addresses, setAddresses] = useState({ 1: '-1', 2: '-1', 3: '-1', 4: '-1' })
  const [phoneNumbers, setPhoneNumbers] = useState({ 1: '', 2: '', 3: '', 4: '' })
  const [couponExpirationDate, setCouponExpirationDate] = useState('')
  const [usedAddressIds, setUsedAddressIds] = useState([])
  const [errors, setErrors] = useState({ address_1: ' ', phone_1: ' ', coupon_expiration_date: ' ' })
  const [errorMessage, setErrorMessage] = useState(' ')
  const [formValues, setFormValues] = useState({})

  // set number of addresses = 2 if coupon is selected,
  // and set to 1 if using T-Mobile Flyer
  useEffect(() => {
    if (props.selectedCouponId !== '-1' && numberOfAddresses !== 2) {
      setNumberOfAddresses(2)
    } else if (props.selectedCouponId === '-1' && numberOfAddresses === 2) {
      setNumberOfAddresses(4)
    } else if (
      props.selectedCouponId === '-1' &&
      numberOfAddresses !== 1 &&
      props.selectedArtwork.digital_proofing_template &&
      props.selectedArtwork.digital_proofing_template.number_of_addresses === 1
    ) {
      setNumberOfAddresses(1)
    }
  })

  // when artwork is cleared, reset fields
  useEffect(() => {
    if (props.selectedArtworkId === '-1' && addresses[1]) {
      setAddresses({ 1: '-1', 2: '-1', 3: '-1', 4: '-1' })
      setPhoneNumbers({ 1: '', 2: '', 3: '', 4: '' })
      setCouponExpirationDate('')
      setErrors({ address_1: ' ', phone_1: ' ', coupon_expiration_date: ' ' })
      setFormValues({})
    }
  }, [props.selectedArtworkId])

  // when selected coupon changes, check for expiration date

  useEffect(() => {
    if (
      props.selectedCouponId !== '-1' &&
      !couponExpirationDate &&
      (!errors['coupon_expiration_date'] || errors['coupon_expiration_date'] === ' ')
    ) {
      setErrors({ ...errors, coupon_expiration_date: 'Coupon Expiration Date is required' })
    } else if (props.selectedCouponId !== '-1' && couponExpirationDate && errors['coupon_expiration_date']) {
      setErrors({ ...errors, coupon_expiration_date: false })
    }
  })

  useEffect(() => {
    if (props.selectedCouponId === '-1' && !!errors['coupon_expiration_date']) {
      setErrors({ ...errors, coupon_expiration_date: false })
    }
  })

  useEffect(() => {
    setErrorMessage(Object.values(errors).find(err => !!err))
  }, [errors])

  // when phone numbers or expiry changes, check if valid & set errors
  useEffect(() => {
    if (props.selectedCouponId !== '-1' && !couponExpirationDate && !errors['coupon_expiration_date']) {
      setError('coupon_expiration_date', 'Expiration date is required')
    } else if (props.selectedCouponId !== '-1' && couponExpirationDate && errors['coupon_expiration_date']) {
      setError('coupon_expiration_date', false)
    }

    // if supported phone numbers === 0, don't set any errors for phone
    if (
      props.selectedArtwork.digital_proofing_template &&
      props.selectedArtwork.digital_proofing_template.number_of_phones === 0
    ) {
      setError('phone_1', false)
    } else {
      if (phoneNumbers[1] === '' && !errors['phone_1']) {
        setError('phone_1', 'Phone 1 is required.')
      } else if (phoneNumbers[1] !== '' && errors['phone_1']) {
        setError('phone_1', false)
      }
    }
  }, [couponExpirationDate, phoneNumbers, props.selectedArtwork])

  // when addresses change, check if addresses are valid & set errors
  useEffect(() => {
    validateDuplicateAddresses()

    // address 1 is required
    if (addresses[1] === '-1' && !errors['address_1']) {
      setError('address_1', 'Address 1 is required.')
    } else if (addresses[1] !== '-1' && errors['address_1']) {
      setError('address_1', false)
    }

    // if at any point a selected address can not be found in props.delivery_addresses,
    Object.entries(addresses).forEach(([addressNum, addressId]) => {
      if (
        addressId !== '-1' &&
        !props.resolvedAddresses.find(address => {
          if (addressId.includes('smart')) {
            return address.id === addressId
          } else {
            return parseInt(address.id) === parseInt(addressId)
          }
        })
      ) {
        // then it has been deleted and we should set that address val to -1
        setAddresses({ ...addresses, [addressNum]: '-1' })
      }
    })
  }, [addresses, props.resolvedAddresses])

  const setError = (key, value) => {
    setErrors({ ...errors, [key]: value })
  }

  function formatPhoneNumber(incomingPhoneNumberFormatted) {
    let value = incomingPhoneNumberFormatted.replaceAll(/\D/g, '') // strip all non-digit chars

    const first = value.substring(0, 3)
    const middle = value.substring(3, 6)
    const last = value.substring(6, 10)

    if (value.length > 6) {
      value = `(${first}) ${middle} - ${last}`
    } else if (value.length > 3) {
      value = `(${first}) ${middle}`
    } else if (value.length > 0) {
      value = `(${first}`
    }

    return value
  }

  const validateDuplicateAddresses = () => {
    // check for duplicate addresses being used
    const addressValues = Object.values(addresses)
    if (!isArrayUnique(addressValues.filter(add => add !== '-1'))) {
      setError('all_addresses', 'You may not use the same address more than once.')
    } else {
      setError('all_addresses', false)
    }
  }

  const handleChangeDate = e => {
    const dateValue = new Date(e.target.value)
    if (errors['coupon_expiration_date'] === ' ') {
      setErrors({ ...errors, coupon_expiration_date: '' })
    }
    setCouponExpirationDate(dateValue)
  }

  const handleChangePhone = (num, value) => {
    if (errors['phone_1'] === ' ') {
      setErrors({ ...errors, phone_1: '' })
    }

    setPhoneNumbers({
      ...phoneNumbers,
      [num]: formatPhoneNumber(value) // store formatted phone in state
    })
  }

  const handleChangeAddress = (num, value) => {
    if (errors['phone_1'] === ' ') {
      setErrors({ ...errors, address_1: '' })
    }
    setAddresses({
      ...addresses,
      [num]: value
    })
  }

  const RenderAllPhoneAddressInputPairs = numberOfPhones => {
    const numberOfPairs = numberOfAddresses
    const renderSingleAddressPair = num => {
      return (
        <div key={num}>
          {AddressInputSelect(num)}
          {numberOfPhones === 0 ? null : PhoneInputField(num)}
          <hr />
        </div>
      )
    }
    return (
      <>
        {Array(numberOfPairs)
          .fill()
          .map((x, i) => i + 1)
          .map(num => {
            return renderSingleAddressPair(num)
          })}
      </>
    )
  }

  const PhoneInputField = (key, label) => {
    return (
      <Row>
        <Col xs="12">
          <Label className="number-format-label">
            {label ? label : key === 'front' ? '* Front Phone' : `${key === 1 ? '* ' : ''}Phone Number ${key}`}
          </Label>
          <Input
            type="phone"
            className="number-format-field"
            value={phoneNumbers[key]}
            name={`address_phone_${key}`}
            id={`address_phone_${key}`}
            onChange={e => handleChangePhone(key, e.target.value)}
          />

          {errors.phone_numbers && errors.phone_numbers[key] ? (
            <>
              <br />
              <span className="address_error">{errors.phone_numbers[key]}</span>
            </>
          ) : null}
        </Col>
      </Row>
    )
  }

  const AddressInputSelect = addressNumber => {
    return (
      <Row>
        <Col xs="12">
          <Label className="number-format-label">
            {addressNumber === 'front' ? 'Front Address' : `${addressNumber === 1 ? '* ' : ''}Address ${addressNumber}`}
          </Label>
          <Input
            type="select"
            value={addresses[addressNumber]}
            onChange={e => handleChangeAddress(addressNumber, e.target.value)}
          >
            <option value="-1">Please Select</option>
            {RenderAddressOptions()}
          </Input>
        </Col>
      </Row>
    )
  }

  const DateSelect = () => {
    return (
      <>
        <p className="number-format-label">* Coupon Expiration Date</p>
        <Input
          type="date"
          name="coupon_expiration_date"
          autoComplete="off"
          onChange={handleChangeDate}
          selected={couponExpirationDate}
          className="form-control mb-2"
        />
      </>
    )
  }

  const handleChange = e => {
    setFormValues({ ...formValues, [e.target.name]: e.target.value })
  }

  const TextInput = (name, label) => {
    return (
      <Row>
        <Col xs="12">
          <Label className="number-format-label">{label}</Label>
          <Input type="text" name={name} value={formValues[name]} onChange={handleChange} />
        </Col>
      </Row>
    )
  }

  const RenderAddressOptions = () => {
    // filter out addresses that have already been used as a selection
    // const availableAddressOptions = props.delivery_addresses.filter(address => !usedAddressIds.includes(address.title+address.address_line_1))
    const availableAddressOptions = props.resolvedAddresses //.filter(address => !usedAddressIds.includes(address.title+address.address_line_1))

    let options = availableAddressOptions.map((address, i) => {
      return (
        <option value={address.id} key={i}>
          {address.title
            ? address.title + ' - ' + (address.full_name ? address.full_name + ', ' : '') + address.address_line_1
            : address.full_name + ', ' + address.address_line_1}
        </option>
      )
    })
    return options
  }

  const handlePreviewProof = () => {
    const formData = new FormData()

    // append addresses and phone numbers
    // addresses and phone numbers have same keys (numbers except for 'front')
    Object.keys(addresses).forEach(num => {
      formData.append(`address_${num}`, addresses[num] && addresses[num] !== '-1' ? addresses[num] : '')
      formData.append(`phone_${num}`, phoneNumbers[num] && phoneNumbers[num] !== '-1' ? phoneNumbers[num] : '')
    })

    // append any other fields applicable
    Object.entries(formValues).forEach(([key, val]) => {
      formData.append(key, val)
    })

    if (props.selectedCouponId && props.selectedCouponId !== '-1') {
      formData.append('coupon_expiration_date', couponExpirationDate)
    }
    // submit form data
    props.complete(formData)
  }

  return (
    <div>
      {props.selectedArtworkId === '-1' ? null : (
        <>
          {props.selectedArtwork.digital_proofing_template &&
          props.selectedArtwork.digital_proofing_template.artwork_sub_type === 'tmobile_welcome_kit'
            ? TextInput('dealer_name', 'Dealer DBA')
            : null}
          {RenderAllPhoneAddressInputPairs(
            props.selectedArtwork.digital_proofing_template
              ? props.selectedArtwork.digital_proofing_template.number_of_phones
              : null
          )}
        </>
      )}
      {props.selectedCouponId !== '-1' && DateSelect()}

      <br />
      <Button
        className="btn-active btn-md mr-15 mb-20 mb-sm-0 mf-primary-btn"
        onClick={handlePreviewProof}
        disabled={!!errorMessage || props.selectedArtworkId === '-1'} // *** check this
      >
        Preview Proof
      </Button>
      {errorMessage ? <sub>{errorMessage}</sub> : null}
      <br />
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    portal: state.portal
  }
}

export default connect(mapStateToProps)(DigitalProofingForm)
