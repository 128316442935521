import { Input, Button, Col, InputGroup } from 'reactstrap'
import { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { fetchCoupon, fetchDiscounts, updateCartItems } from '../../actions'
import { withRouter } from 'react-router'
import { compose } from 'redux'
import {
  setShippingReduction,
  setSubtotalReduction,
  setGrandTotal,
  setAppliedCoupon,
  resolveUnitPrice,
  setProductReduction
} from './checkoutFunctions'
import _ from 'lodash'
import { useMediaQuery } from 'react-responsive'
const PromotionsPanel = props => {
  const [loading, setLoading] = useState(false)
  const [couponCode, setCouponCode] = useState('')

  // if shipping quote changes, re-fetch discounts if we have a promo that discounts shipping
  useEffect(() => {
    if (props.currentLocation.id) {
      if (!props.cartItems || props.cartItems.length === 0) {
        return
      } else {
        fetchDiscounts('discount')
        fetchDiscounts('coupon')
      }
    }
  }, [props.shippingQuote, props.subtotal, props.currentLocation])

  // when applied promotions change, we must update cartItems' priceData with any applicable discounts
  // and also apply any cart-wide discounts for tax, shipping
  useEffect(() => {
    const promotionsArray = Object.values(props.applied_promotions)
    // if there are any promotions
    if (props.applied_promotions && promotionsArray.length > 0) {
      // todo: handle multiple promotions
      const newCartItems = addDiscountsToCartItems(props.cartItems, promotionsArray[0].updated_cart)
      props.updateCartItems(newCartItems)

      // find other discounts
      // if any promotions have a shipping reduction
      const shippingReduction = promotionsArray.reduce((sum, promo) => promo.shipping_reduction + sum, 0)
      props.setShippingReduction(shippingReduction)

      // if any products have a product deduction
      const productReduction = promotionsArray.reduce((sum, promo) => promo.product_reduction_total + sum, 0)
      props.setProductReduction(productReduction)

      // if any promotions have a subtotal reduction
      const subtotalReduction = promotionsArray.reduce((sum, promo) => promo.subtotal_reduction + sum, 0)
      props.setSubtotalReduction(subtotalReduction)
    }
  }, [props.applied_promotions])

  const addDiscountsToCartItems = (cartItems, updatedCart) => {
    let cartItemsWithDiscounts = _.cloneDeep(cartItems)
    return cartItemsWithDiscounts.map(cartItem => {
      // search promotions to see if cartItem has a discount
      let thisCartItemsDiscounts = {}
      const discountsEntryForThisCartItem = updatedCart.find(el => el.cart_item_id === cartItem.cart_item_id)

      let isProductDiscount = false

      // if there is a discount for this cart item
      if (discountsEntryForThisCartItem && discountsEntryForThisCartItem.discount) {
        const thisItemPromoData = discountsEntryForThisCartItem.items[0]

        // so we know if it's a product discount, as it will only apply to a single item
        if (thisItemPromoData.product_discount >= 0) {
          isProductDiscount = true
        }

        thisCartItemsDiscounts = {
          ...thisItemPromoData,
          discount: discountsEntryForThisCartItem.discount
        }
      }

      // will need to adjust coop as well after discounting item
      const coopPercentage = props.currentLocation.coop_percentage_100
        ? props.currentLocation.coop_percentage_100
        : props.portal.coop_percentage_100

      let newSubtotal
      let newUnitPrice
      // if discount is a product discount, the adjusted subtotal should only be reduced by a single item
      // additionally, the unit price should be adjusted to reflect the discount if qty > 1
      if (isProductDiscount === true) {
        newSubtotal = cartItem.priceData.subtotal - thisCartItemsDiscounts.discount
        newUnitPrice = newSubtotal / cartItem.quantity // if product discount, we need to divide by qty
      } else {
        newSubtotal = thisCartItemsDiscounts.discounted_price * cartItem.quantity
        newUnitPrice = thisCartItemsDiscounts.discounted_price
      }

      // if breakable bundle, we need to add the discounts to the child products
      if (cartItem.flags.bundle_type === 'Breakable') {
        // add adjusted_unit_price to each child product
        // to do this we need to calculate the ratio of the parent product's discount to the original price
        // (this is done regardless of the discount being a percentage discount)
        const percentageToDiscountChildProducts = newUnitPrice / cartItem.priceData.unit_price

        cartItem.bundle.products = cartItem.bundle.products.map(bundleProduct => {
          // Todo: may need to adjust coop prices here as well?

          const bundleProductUnitPrice = resolveUnitPrice(bundleProduct.priceData, false)

          bundleProduct.priceData.adjusted_unit_price = bundleProductUnitPrice * percentageToDiscountChildProducts
          bundleProduct.priceData.adjusted_subtotal =
            bundleProduct.priceData.adjusted_unit_price * bundleProduct.product_quantity
          return bundleProduct
        })
      }

      return {
        ...cartItem,
        priceData: {
          ...cartItem.priceData,
          adjusted_unit_price: newUnitPrice,
          adjusted_subtotal: newSubtotal,
          adjusted_coop_deduction_cents: newUnitPrice * (coopPercentage / 100),
          adjusted_coop_deduction_subtotal: newSubtotal * (coopPercentage / 100),
          discounts: thisCartItemsDiscounts
        }
      }
    })
  }

  // Build JSON for Tax API call from cart items
  const explodeBasket = cartItems => {
    return cartItems.map((cartItem, index) => {
      const itemPrice = resolveUnitPrice(cartItem.priceData, false)
      const taxablePrice = cartItem.enable_tax === false ? 0 : cartItem.priceData.subtotal

      return {
        id: index + 1,
        quantity: cartItem.quantity,
        product_id: cartItem.product_id,
        sku: cartItem.sku,
        base_price: cartItem.price,
        name: cartItem.name,
        enable_tax: cartItem.enable_tax,
        cart_item_id: cartItem.cart_item_id,
        items: [
          {
            id: index + 1,
            item_price: itemPrice,
            discounted_price: itemPrice,
            taxable_price: taxablePrice,
            quantity: cartItem.quantity
          }
        ]
      }
    })
  }

  const fetchDiscounts = promoType => {
    const userId = props.currentUser.id
    const location_id = props.currentLocation.id
    const promotionsArray = Object.values(props.applied_promotions)

    // see if any discounts are already applied - return if so
    const numberOfDiscountsApplied = _.filter(props.applied_promotions, promo => promo.type === 'discount').length
    const numberOfCouponsApplied = _.filter(promotionsArray, promo => promo.type === 'coupon').length
    const existingCouponCode =
      numberOfCouponsApplied > 0 ? promotionsArray.find(promo => promo.type === 'coupon').code_used : null

    const cartForForm = explodeBasket(props.cartItems)

    if (promoType === 'discount') {
      let formDataDiscount = new FormData()
      formDataDiscount.append('location_id', location_id)
      formDataDiscount.append('applied_count', numberOfDiscountsApplied)
      formDataDiscount.append('portal_id', props.portal.id)
      formDataDiscount.append('user_id', userId)
      formDataDiscount.append('subtotal', props.subtotal)
      formDataDiscount.append('shipping_cost', props.shippingQuote)
      formDataDiscount.append('cart_items', JSON.stringify(cartForForm))
      setLoading(true)

      props.fetchDiscounts(formDataDiscount).then(() => {
        // re-calculate grand total
        props.setGrandTotal()
        setLoading(false)
      })
    } else {
      if (!existingCouponCode && !couponCode) {
        return
      }
      let formDataCoupon = new FormData()
      formDataCoupon.append('location_id', location_id)
      formDataCoupon.append('applied_count', numberOfDiscountsApplied)
      formDataCoupon.append('portal_id', props.portal.id)
      formDataCoupon.append('user_id', userId)
      formDataCoupon.append('subtotal', props.subtotal)
      formDataCoupon.append('shipping_cost', props.shippingQuote)
      formDataCoupon.append('cart_items', JSON.stringify(cartForForm))
      formDataCoupon.append('coupon_code', existingCouponCode ? existingCouponCode : couponCode)
      setLoading(true)

      props.fetchCoupon(formDataCoupon).then(() => {
        // re-calculate grand total
        props.setGrandTotal()
        setLoading(false)
        props.setAppliedCoupon(existingCouponCode ? existingCouponCode : couponCode)
      })
    }
  }

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 752px)' })

  return (
    <div
      className={
        'promo-custom-css ' +
        (isTabletOrMobile ? 'm125-auto box-shadow white-background' : 'm-0 box-shadow white-background')
      }
    >
      <div className="ckout-header">
        <h5 className="m-0"> Promo Code</h5>
      </div>
      <Col className="p-3 mb-4">
        {Object.values(props.applied_promotions).map((promo, i) => {
          return (
            <div key={i} className="alert-success p-2 mb-3" role="alert">
              <p className="m-0">
                {promo.name}
                {promo.type === 'coupon' ? `: [${promo.code_used}]` : ''}
              </p>
            </div>
          )
        })}

        <InputGroup>
          <Input
            required
            label="Coupon Code"
            margin="normal"
            variant="outlined"
            name="coupon_code"
            id="coupon_code"
            onChange={e => setCouponCode(e.target.value)}
            className="coupon-mobile"
          />
          <Button
            onClick={() => fetchDiscounts('coupon')}
            disabled={loading || props.promoApplied}
            type="submit"
            className="mf-outline-btn pt-0 pb-0 pl-10 pr-10 lh-1"
          >
            Submit
          </Button>
        </InputGroup>
        <sub>Coupon codes are case-sensitive.</sub>
        {props.errors.code_errors ? (
          <>
            <br />
            <sub style={{ color: 'red' }}>{props.errors.code_errors.message}</sub>
          </>
        ) : null}
      </Col>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    selectedDeliveryAddress: state.checkout.selectedDeliveryAddress,
    promoApplied: Object.values(state.applied_promotions).length > 0,
    couponApplied: state.checkout.couponApplied,
    order_shipping_quote: state.order_shipping_quote,
    cartItems: state.cartItems,
    applied_promotions: state.applied_promotions,
    subtotal: state.checkout.subtotal,
    portal: state.portal,
    updated_cart: state.updated_cart,
    shippingQuote: state.checkout.shippingQuote,
    shippingReduction: state.checkout.shippingReduction,
    subtotalReduction: state.checkout.subtotalReduction,
    errors: state.errors,
    checkout: state.checkout,
    order_shipping_quote: state.order_shipping_quote,
    currentLocation: state.currentLocation,
    currentUser: state.currentUser
  }
}

export default compose(
  connect(mapStateToProps, {
    fetchCoupon,
    fetchDiscounts,
    setShippingReduction,
    setSubtotalReduction,
    updateCartItems,
    setGrandTotal,
    setAppliedCoupon,
    setProductReduction
  }),
  withRouter
)(PromotionsPanel)
