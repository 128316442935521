import React from 'react'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import NewAddressForm from '../Checkout/NewAddressForm'

const AddAddressModal = ({ isOpen, toggle, afterCreateAddress }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Add Address</ModalHeader>
      <ModalBody>
        <NewAddressForm afterCreateAddress={afterCreateAddress} />
      </ModalBody>
    </Modal>
  )
}

export default AddAddressModal
