import { FETCH_PORTAL_CATEGORIES } from '../actions/types'

export default (state = [], action) => {
  switch (action.type) {
    case FETCH_PORTAL_CATEGORIES:
      return action.payload
    case 'SET_LOCATION_CATEGORIES':
      return action.payload
    default:
      return state
  }
}
