import { CREATE_UPDATED_CART, CLEAR_UPDATED_CART } from '../actions/types'

export default (state = [], action) => {
  switch (action.type) {
    case CREATE_UPDATED_CART:
      return action.payload
    case CLEAR_UPDATED_CART:
      return []
    default:
      return state
  }
}
