import { CREATE_ORDER_FALLBACK } from '../actions/types'

export default (state = [], action) => {
  switch (action.type) {
    case CREATE_ORDER_FALLBACK:
      return { ...state, ['fallback']: action.payload }
    default:
      return state
  }
}
