import { CREATE_SUCCESS_MESSAGE, CREATE_CODE_SUCCESS, CLEAR_SUCCESS } from '../actions/types'

export default (state = [], action) => {
  switch (action.type) {
    case CREATE_SUCCESS_MESSAGE:
      return action.payload
    case CREATE_CODE_SUCCESS:
      let codeSuccess = {}
      codeSuccess[0] = { message: action.payload }
      return codeSuccess
    case CLEAR_SUCCESS:
      return action.payload
    default:
      return state
  }
}
