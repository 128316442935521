import { legacyLogOut } from '../util/utils'

// Returns boolean indicating presence of user token in storage
export default (state = false, action) => {
  switch (action.type) {
    case 'CREATE_SESSION':
      localStorage.setItem('authToken', action.payload)
      return true
    case 'CLEAR_SESSION':
      legacyLogOut()
      return false
    default:
      return state
  }
}
