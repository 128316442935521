import { CREATE_APPLIED_PROMOTION, CLEAR_PROMOTIONS } from '../actions/types'
import _ from 'lodash'
const initialState = {}

export default (state = initialState, action) => {
  switch (action.type) {
    case CREATE_APPLIED_PROMOTION:
      let parsed_payload = JSON.parse(action.payload)
      return { ...state, [parsed_payload.promotion_id]: parsed_payload }
    // return { ...state, ..._.mapKeys(action.payload, 'promotion_id') };
    case CLEAR_PROMOTIONS:
      return {}
    // return action.payload;
    case 'CLEAR_COUPONS':
      let nonCouponPromotions = _.filter(Object.values(state), promo => promo.type !== 'coupon')
      nonCouponPromotions = _.mapKeys(nonCouponPromotions, 'promotion_id')
      return nonCouponPromotions
    default:
      return state
  }
}
