import { combineReducers } from 'redux'
import tokenReducer from './tokenReducer'
import pagesReducer from './pagesReducer'
import portalReducer from './portalReducer'
import productsReducer from './productsReducer'
import paginatedProductsReducer from './paginatedProductsReducer'
import categoriesReducer from './categoriesReducer'
import featuredCategoriesReducer from './featuredCategoriesReducer'
import locationsReducer from './locationsReducer'
import currentLocationReducer from './currentLocationReducer'
import currentUserReducer from './currentUserReducer'
import errorsReducer from './errorsReducer'
import successReducer from './successReducer'
import statusReducer from './statusReducer'
import sessionReducer from './sessionReducer'
import clientOrdersReducer from './clientOrdersReducer'
import stockReducer from './stockReducer'
import portalPagesReducer from './portalPagesReducer'
import bundleReducer from './bundleReducer'
import deliveryAddressReducer from './deliveryAddressReducer'
import smartDeliveryAddressReducer from './smartDeliveryAddressReducer'
import cartMultipleSmartAddressReducer from './cartMultipleSmartAddressReducer'
import taxReducer from './taxReducer'
import userReducer from './userReducer'
import shippingQuoteReducer from './shippingQuoteReducer'
import appliedPromotionsReducer from './appliedPromotionsReducer'
import orderFallbackReducer from './orderFallbackReducer'
import { reducer as formReducer } from 'redux-form'
import customerGroupsReducer from './customerGroupsReducer'
import orderDetailsReducer from './orderDetailsReducer'
import updatedCartsReducer from './updatedCartsReducer'
import bundleSelectionReducer from './bundleSelectionReducer'
import proofingPreviewReducer from './proofingPreviewReducer'
import creditsReducer from './creditsReducer'
import cartItemsReducer from './cartItemsReducer'
import checkoutReducer from './checkoutReducer'
import productPageReducer from './productPageReducer'
import alertReducer from './alertReducer'
import loadingReducer from './loadingReducer'
import maxQuantitiesReducer from './maxQuantitiesReducer'
import searchQueryReducer from './searchQueryReducer'
import saveProductSuggestionsReducer from './saveProductSuggestionsReducer'
import myDownloadsReducer from './myDownloadsReducer'
import orderInvoiceReducer from './orderInvoiceReducer'
import passwordTokenReducer from './passwordTokenReducer'
import checkoutFormReducer from './checkoutFormReducer'
import netTermReducer from './netTermReducer'

const reducers = combineReducers({
  sweetAlert: alertReducer,
  applied_promotions: appliedPromotionsReducer,
  bundle: bundleReducer,
  bundle_selections: bundleSelectionReducer,
  cartItems: cartItemsReducer,
  cart_multiple_smart_delivery_addresses: cartMultipleSmartAddressReducer,
  categories: categoriesReducer,
  checkout: checkoutReducer,
  checkoutForm: checkoutFormReducer,
  client_orders: clientOrdersReducer,
  currentLocation: currentLocationReducer,
  currentUser: currentUserReducer,
  customerGroup: customerGroupsReducer,
  delivery_addresses: deliveryAddressReducer,
  errors: errorsReducer,
  featuredCategories: featuredCategoriesReducer,
  form: formReducer,
  loading: loadingReducer,
  locations: locationsReducer,
  maxQuantities: maxQuantitiesReducer,
  myDownloads: myDownloadsReducer,
  order_details: orderDetailsReducer,
  order_fallbacks: orderFallbackReducer,
  order_shipping_quote: shippingQuoteReducer,
  order_invoice: orderInvoiceReducer,
  pages: pagesReducer,
  paginated_products: paginatedProductsReducer,
  portal: portalReducer,
  portal_pages: portalPagesReducer,
  products: productsReducer,
  productPage: productPageReducer,
  productSuggestions: saveProductSuggestionsReducer,
  proofing_preview: proofingPreviewReducer,
  session: sessionReducer,
  searchQuery: searchQueryReducer,
  smart_delivery_addresses: smartDeliveryAddressReducer,
  status: statusReducer,
  stocks: stockReducer,
  success_message: successReducer,
  tax: taxReducer,
  token: tokenReducer,
  updated_cart: updatedCartsReducer,
  user: userReducer,
  userCredits: creditsReducer,
  password_token: passwordTokenReducer,
  netTerm: netTermReducer
})

export default reducers
