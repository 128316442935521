import { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import { Table, List, ListInlineItem } from 'reactstrap'
import { fetchStockCount } from '../../../../actions'
import dollar_price from '../../../general/DollarPrice'
import { addFullSkuToBundleProduct, setErrors } from '../../productPageFunctions'
import ProductTemplateFields from '../../ProductTemplateFields'
import BundleProductArtworkSelection from './BundleProductArtworkSelection'
import Configurable from './Configurable'

const BundleProduct = props => {
  const [inStock, setInStock] = useState(false)
  const [fullProductSku, setFullProductSku] = useState('')
  const [configurableError, setConfigurableError] = useState(false)

  useEffect(() => {
    const definedVariants = props.bundleProduct.variants
    if (!definedVariants) return
    // map each variant to its options (there will be only one option for each)
    const configurableOptions = definedVariants.map(variant => variant.nested_data[0])
    // if a variant has no options for a pre-configured product, product can not be purchased. Set error
    if (!configurableOptions.every(option => option)) {
      props.setErrors({
        ...props.productPage.errors,
        configOptions: true
      })
    }
  }, [configurableError])

  // keep stockStatus in sync with inStock state of this bundle product
  // BN commenting this effect - may not be necessary
  useEffect(() => {
    const thisBundleProductId = props.bundleProduct.bundle_group_product_id
    const stockStatusForThisBundleProduct = props.stockStatus[thisBundleProductId]

    if (inStock === false && (!stockStatusForThisBundleProduct || stockStatusForThisBundleProduct === true)) {
      props.setStockStatus(prevStockStatus => ({
        ...prevStockStatus,
        [thisBundleProductId]: false
      }))
    } else if (inStock === true && stockStatusForThisBundleProduct !== true) {
      props.setStockStatus(prevStockStatus => ({
        ...prevStockStatus,
        [thisBundleProductId]: true
      }))
    }
  }, [inStock])

  const countAllConfigurableProducts = (fullSku, allBundleProducts) => {
    // find products with matching sku. this means pre-configured and user configurable.
    // to find user-configurable products, we need to check redux for the selections user has made for each prod.
    const prodsWithMatchingSku = allBundleProducts.filter(prod => {
      if (prod.is_configurable && props.configurableSelection[prod.bundle_group_product_id]) {
        const configProductFullSku =
          prod.product_sku +
          buildSkuSuffix(props.configurableSelection[prod.bundle_group_product_id].selected_options, prod)
        return configProductFullSku === fullSku
      } else {
        return prod.product_sku === fullSku
      }
    })
    return (
      prodsWithMatchingSku.reduce((total, prod) => {
        return total + prod.product_quantity
      }, 0) * props.quantity
    ) // quantity for entire product
  }

  // when the fullProductSku is set, fetch the stock count
  useEffect(() => {
    if (fullProductSku) {
      fetchStockCountForBundleProduct()
    }
  }, [fullProductSku])

  useEffect(() => {
    // if artwork, product should always be in stock
    if (props.bundleProduct.is_artwork) {
      if (!inStock) {
        setInStock(true)
      }
      return
    }

    let totalCountOfThisSku = 0
    let thisSku = props.bundleProduct.product_sku

    // Check if it's a configurable product or has been configured
    if (props.bundleProduct.is_configurable || props.bundleProduct.is_configured) {
      // for a user-configurable product, we need the full sku to match against
      if (props.bundleProduct.is_configurable && fullProductSku) {
        totalCountOfThisSku = countAllConfigurableProducts(fullProductSku, props.bundleProducts)
        thisSku = fullProductSku
      } else if (props.bundleProduct.is_configured) {
        // for a pre-configured product, the SKU is already set
        totalCountOfThisSku = countAllConfigurableProducts(thisSku, props.bundleProducts)
      }
    } else {
      totalCountOfThisSku = countAllConfigurableProducts(thisSku, props.bundleProducts)
    }

    // Check stock based on SKU
    const currentStock = props.stocks[thisSku]
    const newInStock = currentStock && currentStock >= totalCountOfThisSku
    if (newInStock !== inStock) {
      setInStock(newInStock)
    }
  }, [props.stocks, props.productPage.product.bundle.products, fullProductSku, inStock, props.bundleProduct])

  const fetchStockCountForBundleProduct = () => {
    const portal_id = props.portal.id
    const bundleProduct = props.bundleProduct
    const baseSku = bundleProduct.product_sku
    const productId = bundleProduct.product_id
    const bundleGroupProductId = bundleProduct.bundle_group_product_id
    const productSkuId = bundleProduct.product_sku_id

    if (fullProductSku) {
      props
        .fetchStockCount(
          fullProductSku,
          portal_id,
          props.currentLocation.id,
          null, //selectedConfigOptionIds,
          productId,
          bundleGroupProductId,
          null // productSkuId
        )
        .then(() => {
          if (props.stocks[fullProductSku] < 1) {
            props.setStockStatus(prevStockStatus => ({
              ...prevStockStatus,
              [bundleGroupProductId]: false
            }))
          } else if (props.stocks[fullProductSku] > 0) {
            props.setStockStatus(prevStockStatus => ({
              ...prevStockStatus,
              [bundleGroupProductId]: true
            }))
          }
        })
      props.addFullSkuToBundleProduct({ bundleProduct, fullProductSku })
    }
  }

  // when config selection changes, if all selections made for this product, adjust the SKU for the new selection
  useEffect(() => {
    const configVariants = props.bundleProduct.nested_data
    // if there are no variants on the product, the full sku is the base sku
    if (!configVariants && !fullProductSku) {
      setFullProductSku(props.bundleProduct.product_sku)
      return
    } else if (configVariants && props.bundleProduct.is_configured) {
      // if product is pre-configured, the full sku is the base sku
      setFullProductSku(props.bundleProduct.product_sku)
      return
    }

    const bundleGroupProductId = props.bundleProduct.bundle_group_product_id
    const configSelectionForThisProduct = props.configurableSelection[bundleGroupProductId] || {}
    const selectedOptions = configSelectionForThisProduct.selected_options

    // if there are variants, but not all selections have been made

    if (
      selectedOptions && // if there are selected options
      Object.values(selectedOptions).length === configVariants.length // if there is a selection for each variant
    ) {
      const baseSku = props.bundleProduct.product_sku
      const suffix = buildSkuSuffix(selectedOptions, props.bundleProduct)
      setFullProductSku(baseSku + suffix)
    }
  }, [props.configurableSelection, props.bundleProduct.bundle_group_product_id])

  const buildSkuSuffix = (selectedOptionsByVariantId, bundleProduct) => {
    const sortedVariants = bundleProduct.variants //.sort( v => v.order)
    // map each variant to its sku based on the user selection
    const sortedSkuCodes = sortedVariants
      .map(variant => {
        // find the user selection. If user has not made one, return null
        const selectedOption = selectedOptionsByVariantId[variant.variant_id]
        if (!selectedOption) {
          return null
        } else {
          const skuCode = selectedOption.sku_code
          return skuCode
        }
      })
      .filter(skuCode => skuCode !== null) // remove null values

    // Build SKU suffix to be appended to base SKU
    // map each code to a formatted string with "-"
    let skuSuffix = sortedSkuCodes.map(skuCode => {
      return `-${skuCode}`
    })

    // concatenate the array of formatted codes to a single string and return
    skuSuffix = skuSuffix.join('')
    return skuSuffix
  }

  const handleShowProductModal = prod => {
    props.setShowProductModal(prod)
  }

  // determines if we need an extra row for displaying:
  // - Artwork Selection for regular products w/ artwork OR digital proofing
  // - Configurables requiring user selection
  // - Pre-configured product information
  const productHasExtraInfo = () => {
    if (
      props.bundleProduct.is_artwork ||
      (props.bundleProduct.nested_data && props.bundleProduct.nested_data.length > 0)
    ) {
      return true
    } else if (props.bundleProduct.is_configured) {
      return true
    } else if (props.bundleProduct.is_digital_proofing) {
      return true
    } else {
      return false
    }
  }

  // This is for user to select options - renders the inputs
  // For configurables AND artworks?
  const ConfigSelections = () => {
    return <Configurable product={props.bundleProduct} />
  }

  const ArtworkSelection = () => {
    return <BundleProductArtworkSelection setInStock={setInStock} bundleProduct={props.bundleProduct} />
  }

  // This is for configurable products that have pre-selected options
  const PreConfiguredData = () => {
    // const definedVariants = props.bundleProduct.variants?.filter(el => !!el.configurable_variant_id || !!el.configurable_option_id)
    const definedVariants = props.bundleProduct.variants
    // map each variant to its options (there will be only one option for each)
    const configurableOptions = definedVariants.map(variant => variant.nested_data[0])
    // if a variant has no options for a pre-configured product, product can not be purchased. Set error
    if (!configurableOptions.every(option => option)) {
      return (
        <span>
          <em>
            <b>Out of Stock</b>
          </em>
        </span>
      )
    }
    return (
      <List type="inline m-0">
        {configurableOptions.map((option, i) => {
          const thisVariant = definedVariants[i]
          return (
            <ListInlineItem key={i}>
              {formatOptionLabel(option, i, configurableOptions.length, thisVariant)}
            </ListInlineItem>
          )
        })}
      </List>
    )
  }

  const formatOptionLabel = (configurableOption, i, totalNumOptions, configurableVariant) => {
    const { name, display_name } = configurableVariant
    const priceModifierObject = configurableOption.price_modifier
    return (
      <span>
        <em>
          <b>{display_name ? display_name : name}</b>: {configurableOption.name}
          {props.currentLocation.show_price && configurableOption.has_price_modifier ? (
            <> + {dollar_price(priceModifierObject.price_modifier_cents)}</>
          ) : null}
        </em>
        {i === totalNumOptions - 1 ? null : ','}
      </span>
    )
  }

  const RenderProductAdditionalInfo = () => {
    switch (true) {
      // if product is configurable and needs selection
      case props.bundleProduct.nested_data &&
        props.bundleProduct.nested_data.length > 0 &&
        !props.bundleProduct.is_configured &&
        !props.bundleProduct.is_digital_proofing &&
        !props.bundleProduct.is_artwork:
        return ConfigSelections()
      // if product is configurable and is pre-configured
      case props.bundleProduct.is_configured:
        return PreConfiguredData()
      // if product is artwork and needs selection
      case props.bundleProduct.is_artwork === true:
        return ArtworkSelection()
      default:
        break
    }
  }

  const RenderTemplateFields = () => {
    // get all the template fields inside nested_data
    const thisProductTemplateFieldsArray =
      props.bundleProduct.template && props.bundleProduct.template.filter(el => el.template_id)
    if (props.bundleProduct && thisProductTemplateFieldsArray) {
      return (
        <ProductTemplateFields
          templateFields={thisProductTemplateFieldsArray}
          isBundle={true}
          bundleProduct={props.bundleProduct}
        />
      )
    }
  }

  const getOutOfStockText = () => {
    if (!(fullProductSku in props.stocks)) {
      return 'Not Available'
    }
    return 'Out of Stock'
  }

  const locationShowStockCount = props.currentLocation.show_stock_count
  let stockCount = null

  locationShowStockCount
    ? props.bundleProduct.is_configurable
      ? (stockCount = props.stocks[fullProductSku])
      : (stockCount = props.stocks[props.bundleProduct['product_sku']])
    : null

  const inStockLabel = (
    <span className="text-14 success-color">
      In Stock: {stockCount ? (stockCount > 100000 ? '100,000+' : stockCount) : null} Available
    </span>
  )
  const outStockLabel = <span className="text-14 danger-color">{getOutOfStockText()}</span>
  const backorderLabel = <span className="text-14 ml-5 danger-color">{props.portal.backorder_stock_text}</span>
  const naLabel = <span className="text-14 danger-color">N/A</span>

  const renderSetupChargeInfo = () => {
    return (
      <div className="setup-charge-container setup-charge-container-bundle">
        <span className="font-bold text-14">Setup Charge:</span>{' '}
        <span className="text-14">{dollar_price(props.bundleProduct.setup_charge)}</span>
        <br />
        <small>** Setup charge will be applied once per line item in cart</small>
      </div>
    )
  }

  const allConfigSelectionsMade =
    props.configurableSelection[props.bundleProduct.bundle_group_product_id] &&
    Object.values(props.configurableSelection[props.bundleProduct.bundle_group_product_id].selected_options).length ===
      props.bundleProduct.variants.length

  const renderStockLabel = (bundleIsBreakable, isArtwork, isConfigurable, showBackorderText) => {
    if (bundleIsBreakable) {
      if (isArtwork) {
        // artwork is always in stock
        return inStockLabel
      } else if (showBackorderText) {
        // if backorder text is enabled, display that
        return backorderLabel
      } else if (isConfigurable) {
        // pre-configured or user-configurable?
        if (props.bundleProduct.is_configured) {
          return inStock ? inStockLabel : outStockLabel
        }
        // for configs that are not pre-configured: are all selections made?
        return allConfigSelectionsMade ? (inStock ? inStockLabel : outStockLabel) : naLabel
      } else {
        // non-configurable product
        return inStock ? inStockLabel : outStockLabel
      }
    }
  }

  return (
    <>
      <Table size="sm" borderless className="m-0">
        <tbody>
          {/* Product Name and Quantity */}
          <tr className={props.bundleProduct.configurable ? '' : 'bundle-simple'} style={{ fontSize: '.9em' }}>
            <td className="p-0">
              <a className="product-link" onClick={() => handleShowProductModal(props.bundleProduct)}>
                {props.bundleProduct.product_name}
              </a>
              <strong>
                &nbsp;[Qty:
                {props.bundleProduct.product_quantity
                  ? props.bundleProduct.product_quantity
                  : props.bundleProduct.quantity}
                ]
              </strong>
            </td>
            <td style={{ textAlign: 'right' }} className="p-0">
              {renderStockLabel(
                props.breakable,
                props.bundleProduct.is_artwork,
                props.bundleProduct.is_configurable,
                props.bundleProduct.show_backorder_text
              )}
            </td>
          </tr>

          {/* Additional Info or User Input */}
          {productHasExtraInfo() ? (
            <tr>
              <td colSpan="2">{RenderProductAdditionalInfo()}</td>
            </tr>
          ) : null}

          {/* Template Fields */}
          {props.bundleProduct.has_template === true ? (
            <tr>
              <td>{RenderTemplateFields()}</td>
            </tr>
          ) : null}

          {/* Setup Charge */}
          {Number.isInteger(props.bundleProduct.setup_charge) && props.bundleProduct.setup_charge > 0 ? (
            <tr>
              <td>{renderSetupChargeInfo()}</td>
            </tr>
          ) : null}
        </tbody>
      </Table>
    </>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    stocks: state.stocks,
    configurableSelection: state.productPage.configurableSelection,
    portal: state.portal,
    stocks: state.stocks,
    currentLocation: state.currentLocation,
    productPage: state.productPage,
    currentUser: state.currentUser
  }
}

export default connect(mapStateToProps, { fetchStockCount, addFullSkuToBundleProduct, setErrors })(BundleProduct)
