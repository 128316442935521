export const clearSweetAlert = data => async dispatch => dispatch({ type: 'CLEAR_SWEETALERT' })
import { clearCart } from '../../actions'
import history from '../../history'

export const stockErrors = cartItems => {
  const result = cartItems.filter(cartItem => cartItem.flags && cartItem.flags.has_inventory === false)

  return result.length > 0 ? result : false
}

// if input product has an error, return itself + error
export const productHasError = product => {
  let errorObject
  // if bundle, run for each child product on each group
  if (product.flags && product.flags.bundle_type === 'Breakable') {
    // if for product page
    if (product.bundle.bundleGroups) {
      const allBundleProductsFromAllGroups = product.bundle.bundleGroups.reduce((allProducts, gp) => {
        return [...allProducts, gp.products]
      }, [])

      // const bundleProductWithError = product.bundle.products.find(bundleProduct => productHasError(bundleProduct))
      const bundleGroupProductWithError = allBundleProductsFromAllGroups.find(bundleProduct =>
        productHasError(bundleProduct)
      )
      // if there is a bundle product with error, return parent product (cart item)
      if (bundleGroupProductWithError) {
        return {
          ...product,
          error: bundleGroupProductWithError.error
        }
      }
    } else {
      // if for cart
      const bundleProductWithError = product.bundle.products.find(bundleProduct => productHasError(bundleProduct))
      // if there is a bundle product with error, return parent product (cart item)
      if (bundleProductWithError) {
        return {
          ...product,
          error: bundleProductWithError.error
        }
      }
      return
    }
  }

  if (product.flags && product.flags.has_errors === true) {
    errorObject = product.flags.errors[0]
  } else if (product.has_errors === true) {
    // for bundle products
    errorObject = product.errors[0]
  } else {
    errorObject = {}
    return false
  }

  if (errorObject) {
    product.error = errorObject
    return { ...product, error: errorObject }
  } else {
    return false
  }
}

export const resolveBundleProductErrorMessage = products => {
  const product = products.find(prod => productHasError(prod))

  let errorObject
  if (product.nested_data) {
    errorObject = product.nested_data.find(el => el.is_error === true)
  }
  if (!errorObject && product.variants) {
    errorObject = product.variants.find(el => el.is_error === true)
  }
  return errorObject.message
}

// Will Check Cart Items To Make Sure All Require Approval Or None. Cannot Have Cart With Both
export const cartItemsRequireCheckForApproval = (product = null, cartItems, location) => {
  const locationRequiresApproval = location.requires_approval
  const allLocationProductsRequireApproval = location.all_products_require_approval
  const cartItemsRequireApproval = cartItems.every(item => item.requires_approval)
  const cartItemsDoNotRequireApproval = cartItems.every(item => !item.requires_approval)
  const productRequiresApproval = product ? product.requires_approval : null
  let requiresCheck

  if (locationRequiresApproval) {
    if (allLocationProductsRequireApproval) {
      requiresCheck = false
    } else if (product && productRequiresApproval && cartItemsRequireApproval) {
      requiresCheck = false
    } else if (product && !productRequiresApproval && cartItemsDoNotRequireApproval) {
      requiresCheck = false
    } else if (!product && (cartItemsRequireApproval || cartItemsDoNotRequireApproval)) {
      requiresCheck = false
    } else {
      requiresCheck = true
    }
  } else {
    requiresCheck = false
  }

  return requiresCheck
}
export const returnCartItemsRequireApprovalError =
  (
    cartItems,
    productAddToCart = null,
    addProductToBasket = null,
    digitalProofingProduct = null,
    quantity = null,
    closePreviewCallback = null
  ) =>
  async dispatch => {
    let baseMessage =
      'To ensure timely shipment, items requiring approval cannot be combined with regular items in your cart. Please choose one type to continue. '
    let warningMessage = ''

    if (productAddToCart) {
      warningMessage = 'Warning: Adding this item will clear your cart. '
    }

    let alertMessage = warningMessage + baseMessage

    if (productAddToCart) {
      dispatch({
        type: 'SHOW_SWEETALERT',
        payload: {
          alertMessage: alertMessage,
          type: 'error',
          showCancel: true,
          confirmBtnText: 'Replace Cart with this Item',
          cancelBtnText: 'Keep Current Cart',
          title: 'Unable to Add to Cart',
          onConfirm: () => {
            if (productAddToCart && addProductToBasket) {
              const locationId = productAddToCart.location_id
              dispatch(clearCart(locationId)).then(() => {
                if (closePreviewCallback) {
                  closePreviewCallback()
                }
                addProductToBasket(productAddToCart, quantity, digitalProofingProduct)
              })
            }
          }
        }
      })
    } else {
      dispatch({
        type: 'SHOW_SWEETALERT',
        payload: {
          alertMessage:
            'The approval status of one or more items in your cart has changed. Please clear your cart and try again.',
          type: 'error',
          showCancel: true,
          showConfirmButton: true,
          confirmBtnText: 'Clear Cart',
          showDenyButton: true,
          cancelBtnText: 'Cancel',
          title: 'Approval Status Changed',
          onConfirm: () => {
            dispatch(clearCart(cartItems[0].location_id))
            history.push('/shop')
          }
        }
      })
    }
  }
