import { FETCH_ORDER_INVOICE } from '../actions/types'

export default (state = {}, action) => {
  switch (action.type) {
    case FETCH_ORDER_INVOICE:
      return {
        ...state,
        [action.id]: action.payload
      }
    default:
      return state
  }
}
