import React from 'react'
import Slider from 'react-slick'
import { Link } from 'react-router-dom'
import { Button } from 'reactstrap'
import { Row, Col } from 'reactstrap'

function BannerSliderThree(props) {
  const settings = {
    dots: false,
    infinite: true,
    arrows: false,
    speed: 1500,
    autoplaySpeed: 7500,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          arrows: false
        }
      }
    ]
  }

  if (props.blockContent.version == '2') {
    const topLinkIsExternal =
      props.blockContent.top_link?.startsWith('http') || props.blockContent.top_link?.startsWith('https')
    const bottomLinkIsExternal =
      props.blockContent.bottom_link?.startsWith('http') || props.blockContent.bottom_link?.startsWith('https')
    return (
      <Row className="my-0 mx-lg-30">
        <Col xs={12} sm={12} md={12} lg={8} xl={8} className="py-0 iron-banner-slider-v3-grid">
          <div className="iron-banner-slider-v3 iron-post-wrap">
            <Slider {...settings}>
              {props.blockContent.items
                .sort((a, b) => a.order - b.order)
                .map((slidedata, index) => {
                  return (
                    <div key={index} className="iron-post-item slider-v2-shadow-border">
                      <div className="iron-overlay-wrap">
                        <div className="iron-thumb-wrap">
                          <a href={slidedata.link}>
                            <img width="100%" src={slidedata.image.url} alt="slide-1" />
                          </a>
                        </div>
                        <div className="iron-overlay-content d-flex justify-content-center align-items-center">
                          <div className="mx-auto text-center">
                            {slidedata.text_link && slidedata.link && (
                              <Button component={Link} to={slidedata.link} className="button btn-base">
                                {slidedata.text_link}
                              </Button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })}
            </Slider>
          </div>
        </Col>
        <Col xs={12} lg={4} className="py-0 d-flex">
          <Row>
            <Col xs={12} sm={6} lg={12} className="mb-30 mb-lg-0">
              {topLinkIsExternal ? (
                <a href={props.blockContent.top_link} target="_blank" rel="noopener noreferrer">
                  <img
                    className="slider-v2-shadow-border"
                    src={props.blockContent.top_image.url}
                    alt="slider-top-image"
                  />
                </a>
              ) : (
                <Link to={props.blockContent.top_link}>
                  <img
                    className="slider-v2-shadow-border"
                    src={props.blockContent.top_image.url}
                    alt="slider-top-image"
                  />
                </Link>
              )}
            </Col>
            <Col xs={12} sm={6} lg={12}>
              {bottomLinkIsExternal ? (
                <a href={props.blockContent.bottom_link} target="_blank" rel="noopener noreferrer">
                  <img
                    className="slider-v2-shadow-border"
                    src={props.blockContent.bottom_image.url}
                    alt="slider-bottom-image"
                  />
                </a>
              ) : (
                <Link to={props.blockContent.bottom_link}>
                  <img
                    className="slider-v2-shadow-border"
                    src={props.blockContent.bottom_image.url}
                    alt="slider-bottom-image"
                  />
                </Link>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    )
  } else {
    return (
      <div className="iron-banner-slider-v3 iron-post-wrap">
        <Slider {...settings}>
          {props.blockContent.items
            .sort((a, b) => a.order - b.order)
            .map((slidedata, index) => {
              return (
                <div key={index} className="iron-post-item">
                  <div className="iron-overlay-wrap">
                    <div className="iron-thumb-wrap">
                      <a href={slidedata.link}>
                        <img width="100%" src={slidedata.image.url} alt="slide-1" />
                      </a>
                    </div>
                    <div className="iron-overlay-content d-flex justify-content-center align-items-center">
                      <div className="mx-auto text-center">
                        {slidedata.text_link && slidedata.link && (
                          <Button component={Link} to={slidedata.link} className="button btn-base">
                            {slidedata.text_link}
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
        </Slider>
      </div>
    )
  }
}

export default BannerSliderThree
