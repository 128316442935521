import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { connect } from 'react-redux'
import { Row } from 'reactstrap'
import { mapDispatchToProps } from '../../productPageFunctions'
import ConfigurableProduct from './ConfigurableProduct'

/**
 * @typedef {Object} ConfigurableProps
 * @property {Object} product - The product object
 * @property {function} updateConfigSelection - The callback that updates selections.
 * @property {Object} configSelection - The current selections.
 * @property {Object} currentLocation - The current location object.
 */

/**
 * The Configurable component used in bundle product show page
 *
 * @param {ConfigurableProps} props - Properties of the component
 * @returns {JSX.Element} The rendered Configurable component
 */
const Configurable = ({ product, updateConfigSelection, configSelection, currentLocation }) => {
  const configurableVariants = product.nested_data.filter(el => !!el.variant_id)

  /* for each variant, render its input field */
  const ListComponent = useMemo(
    () =>
      configurableVariants.map((variant, i) => (
        <ConfigurableProduct
          key={i}
          variant={variant}
          index={i}
          updateConfigSelection={updateConfigSelection}
          currentLocation={currentLocation}
          configSelection={configSelection}
          product={product}
        />
      )),
    [configurableVariants, currentLocation, product, updateConfigSelection, configSelection]
  )

  return <Row>{ListComponent}</Row>
}

Configurable.propTypes = {
  product: PropTypes.object.isRequired,
  updateConfigSelection: PropTypes.func.isRequired,
  configSelection: PropTypes.object.isRequired,
  currentLocation: PropTypes.object.isRequired
}

const mapStateToProps = state => {
  return {
    configSelection: state.productPage.configurableSelection,
    currentLocation: state.currentLocation
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Configurable)
