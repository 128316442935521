import { FETCH_PRODUCT_BUNDLE_SELECTIONS } from '../actions/types'

export default (state = [], action) => {
  switch (action.type) {
    case FETCH_PRODUCT_BUNDLE_SELECTIONS:
      return action.payload
    default:
      return state
  }
}
