import React, { useState, useEffect } from 'react'
import { Button, Input } from 'reactstrap'
import { useHistory } from 'react-router-dom'
import { fetchProductNameSuggestions, saveSearchQuery, saveProductSuggestions } from '../../actions'
import { connect } from 'react-redux'
import { Search } from 'react-feather'

const SearchBar = props => {
  const [searchQuery, setSearchQuery] = useState('')
  const [keyDownUp, setKeyDownUp] = useState(-1)
  let history = useHistory()

  useEffect(() => {
    if (props.products?.length > 0) {
      props.saveProductSuggestions(props.products.map(product => product.name))
    } else {
      props.saveProductSuggestions('')
    }
  }, [props.products])

  useEffect(() => {
    setSearchQuery(props.searchQuery)
  }, [props.searchQuery])

  useEffect(() => {
    if (keyDownUp >= 0 && keyDownUp <= props.productSuggestions.length - 1) {
      props.saveSearchQuery(props.productSuggestions[keyDownUp])
    }
  }, [keyDownUp])

  useEffect(() => {
    if (props.productSuggestions === '') {
      props.fetchProductNameSuggestions('')
      setKeyDownUp(-1)
    }
  }, [props.productSuggestions])

  const onSubmit = () => {
    const searchParams = new URLSearchParams(history.location.search)
    searchParams.set('search', searchQuery.trim())
    props.saveSearchQuery(searchQuery.trim())
    window.scrollTo(0, 0)
    history.push(`/shop?${searchParams.toString()}`)
  }

  const handleSearch = searchInput => {
    props.saveSearchQuery(searchInput)
    if (searchInput.length >= 2) {
      props.fetchProductNameSuggestions(searchInput).then(() => {})
    } else {
      props.saveProductSuggestions('')
    }
  }

  const enterKeyPress = e => {
    if (e.keyCode === 13) {
      e.target.blur()
      onSubmit()
    } else if (e.keyCode === 38 && keyDownUp > -1) {
      setKeyDownUp(keyDownUp - 1)
    } else if (e.keyCode === 40 && keyDownUp < props.productSuggestions.length - 1) {
      setKeyDownUp(keyDownUp + 1)
    } else if (e.keyCode === 27) {
      e.target.blur()
      setKeyDownUp(-1)
    }
  }

  const handleClick = searchValue => {
    const searchParams = new URLSearchParams(window.location.search)
    searchParams.set('search', searchValue.trim())
    searchParams.set('page', 1)
    props.saveSearchQuery(searchValue.trim())
    window.scrollTo(0, 0)
    history.push(`/shop?${searchParams.toString()}`)
  }

  const renderedFromFixedHeader = props.renderFromFixedHeader ? 'search_bar_button_fixed' : ''

  const { productSuggestions, saveProductSuggestions, mobile, buttonWidth } = props

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'stretch',
        width: '100%'
      }}
    >
      <div style={{ position: 'relative', flexGrow: 1 }}>
        <Input
          placeholder="Search For Products"
          type="text"
          defaultValue={searchQuery}
          spellCheck="true"
          onChange={e => handleSearch(e.target.value)}
          onKeyDown={enterKeyPress}
          className="search_bar_input"
          onBlur={() => {
            setTimeout(() => {
              saveProductSuggestions('')
            }, 120)
          }}
          style={{
            borderRadius: '3px 0px 0px 3px',
            position: 'inherit',
            height: '51px'
          }}
        />

        {productSuggestions && (
          <div
            className="suggestions_container"
            style={{
              position: 'absolute',
              top: '100%',
              left: 0,
              right: 0,
              zIndex: 1
            }}
          >
            {productSuggestions.map((value, i) => (
              <p
                key={value}
                className={`suggestions ${keyDownUp === i ? 'suggestions_selected' : null} mt-0 mb-0`}
                onClick={() => handleClick(value)}
              >
                {value}
              </p>
            ))}
          </div>
        )}
      </div>

      <Button
        className={`search_bar_button ${renderedFromFixedHeader}`}
        onClick={onSubmit}
        style={{
          borderRadius: '0px 3px 3px 0px',
          height: '51px',
          width: mobile ? '100%' : 'auto',
          maxWidth: '51px',
          zIndex: 10
        }}
      >
        <Search strokeWidth={3} size={18} style={{ margin: 'auto', display: 'flex', alignItems: 'center' }} />
      </Button>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    products: state.products,
    searchQuery: state.searchQuery,
    productSuggestions: state.productSuggestions
  }
}

export default connect(mapStateToProps, {
  fetchProductNameSuggestions,
  saveSearchQuery,
  saveProductSuggestions
})(SearchBar)
