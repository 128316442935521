import { CART_MULTIPLE_SMART_DELIVERY_ADDRESSES } from '../actions/types'

export default (state = [], action) => {
  switch (action.type) {
    case CART_MULTIPLE_SMART_DELIVERY_ADDRESSES:
      return action.payload
    default:
      return state
  }
}
