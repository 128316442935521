import { Table } from 'reactstrap'
import { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { deleteCartItem, updateCartItem, clearSweetAlert, checkStockCartItem, dispatchSweetAlert } from '../../actions'
import _ from 'lodash'
import { setErrors } from '../Checkout/checkoutFunctions'
import CartItemCard from './CartItemCard'

const HeaderCartItemsList = props => {
  useEffect(() => {
    if (props.errors.stock) {
      props.errors.stock.forEach(cartItem => {
        const cartItemRow = document.getElementById(`cart-item-row-${cartItem.cart_item_id}`)
        if (cartItemRow) {
          cartItemRow.style = 'background:rgba(255, 0, 0, 0.21)'
        }
      })
    }
  }, [props.errors])

  return (
    <Table responsive size="sm" style={{ fontSize: '.9em' }}>
      <tbody>
        {/* CART ITEM LIST */}
        {props.cartItems.map((cartItem, index) => {
          return <CartItemCard key={cartItem.cart_item_id} index={index} cartItem={cartItem} mini={true} {...props} />
        })}
      </tbody>
    </Table>
  )
}

const mapStateToProps = state => {
  return {
    currentLocation: state.currentLocation,
    portal: state.portal,
    cartItems: state.cartItems,
    currentLocation: state.currentLocation,
    cart_items_loading: state.productPage.cart_items_loading,
    maxQuantities: state.maxQuantities,
    errors: state.checkout.errors
  }
}

export default connect(mapStateToProps, {
  clearSweetAlert,
  deleteCartItem,
  updateCartItem,
  checkStockCartItem,
  dispatchSweetAlert,
  setErrors
})(HeaderCartItemsList)
