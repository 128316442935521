import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Input, InputGroup, InputGroupAddon, FormGroup, Label, Form } from 'reactstrap'
import Cards from 'react-credit-cards'
import { setPaymentMethod, setCcData, setPoNumber, setNet30Confirmed } from './checkoutFunctions'
import { connect } from 'react-redux'
import Tabs, { TabPane } from 'rc-tabs'
import '../../assets/scss/custom/react-credit-cards/default.scss'
import { dispatchSweetAlert } from '../../actions'
import { fetchNetTerm, fetchNetTermPoNumber } from '../../actions/index'
import CheckBox from '../general/CheckBox'

const CheckoutCardPanel = props => {
  // Check Promotions for set_payment_to_net_30 and set appropriately
  const [checked, setChecked] = useState({ net30: false })
  const colStyle = { display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }
  const rowStyle = { cursor: 'pointer', borderRadius: '3px' }

  useEffect(() => {
    const user_id = props.currentUser.id
    if (user_id) {
      props.fetchNetTerm(user_id)
      props.fetchNetTermPoNumber(user_id)
    }
    const promoArray = Object.values(props.applied_promotions)
    // if any promotion sets net 30, keep checkout reducer updated
    if (promoArray.some(promo => promo.set_payment_to_net_30 === true)) {
      props.setPaymentMethod('net30')
      props.setNet30Confirmed(true)
    }
  }, [props.applied_promotions])

  // If there is an extra field named po_number, set it in the to extra field value. Otherwise, use props.poNumber
  // const [poNumber, setPoNumber] = useState('')
  useEffect(() => {
    if (props.extraFields) {
      props.extraFields.map((key, value) => {
        if (key.field === 'po_number') {
          props.setPoNumber(key.value)
        }
      })
    } else {
      if (props.poNumber) {
        props.setPoNumber(props.poNumber)
      }
    }
  }, [props.extraFields])

  const handlePoNumberChange = event => {
    props.setPoNumber(event.target.value)
  }

  const handleCheckBox = val => {
    setChecked({ ...checked, [val]: !checked[val] })
    if (val === 'net30') {
      handleChangePaymentMethod(!checked[val] && 'net30')
    }
  }

  const handleChangePaymentMethod = e => {
    props.setPaymentMethod(e)
    if (e === 'net30') {
      confirmNet30()
    } else {
      // remove net 30
      confirmNet30(false)
    }
  }

  const handleChange = e => {
    const { name, value } = e.target
    // expiry needs some specific handling to concatenate MM & YY
    if (name === 'expiry_yy' || name === 'expiry_mm') {
      const expiry = props.ccData.card_expiry
      const mm = expiry.substr(0, 2)
      const yy = expiry.substr(2)
      let newExpiry
      if (name == 'expiry_mm') {
        newExpiry = `${value}${yy}`
      } else if (name == 'expiry_yy') {
        newExpiry = `${mm}${value}`
      }
      props.setCcData({ card_expiry: newExpiry })
    } else {
      // handling for all other fields:
      props.setCcData({ [name]: value })
    }
  }
  const handleInputFocus = e => {
    setFocus(e.target.name)
  }
  const setFocus = fieldName => {
    // send to prop for cards: focused = name|number|expiry|cvc
    let propValue
    if (fieldName === 'card_number') {
      propValue = 'number'
    } else if (fieldName === 'card_name') {
      propValue = 'name'
    } else if (fieldName === 'expiry_mm' || fieldName === 'expiry_yy') {
      propValue = 'expiry'
    } else if (fieldName === 'card_cvc') {
      propValue = 'cvc'
    }

    props.setCcData({ focus: propValue })
  }

  const CcInputField = (name, label, type) => {
    return (
      <Col sm={name === 'card_cvc' ? 4 : 12} className="px-0">
        <Input
          type={type}
          name={name}
          placeholder={label}
          onChange={handleChange}
          onFocus={handleInputFocus}
          value={props.ccData[name]}
        />
      </Col>
    )
  }

  const CcForm = () => {
    return (
      <Form autoComplete="off" className="cc-form-flex mt-3">
        <FormGroup
          row
          style={{
            width: '100%'
          }}
        >
          {CcInputField('card_number', 'Card Number', 'tel', { required: true })}
        </FormGroup>

        <FormGroup
          row
          style={{
            width: '100%'
          }}
        >
          {CcInputField('card_name', 'Full Name', 'text', { required: true })}
        </FormGroup>

        <FormGroup
          row
          style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap'
          }}
        >
          <Col sm={12}>
            <InputGroup style={{ flexWrap: 'nowrap' }}>
              <Input
                type="select"
                name="expiry_mm"
                required
                onChange={handleChange}
                onFocus={handleInputFocus}
                className="cc-exp-date col-4"
              >
                <option value="**">MM</option>
                {[...new Array(12).keys()].map(month => {
                  const mm = `${month + 1}`.padStart(2, '0')
                  return (
                    <option key={month} value={mm}>
                      {mm}
                    </option>
                  )
                })}
              </Input>

              <Input
                type="select"
                name="expiry_yy"
                required
                onChange={handleChange}
                onFocus={handleInputFocus}
                className="cc-exp-date col-4"
                style={{
                  marginRight: '10px',
                  borderRadius: '0 4px 4px 0'
                }}
              >
                <option value="**">YY</option>
                {[...new Array(15).keys()].map(year => {
                  const yyyy = year + new Date().getFullYear()
                  const yy = yyyy.toString().substr(2, 3)
                  return (
                    <option key={year} value={yy}>
                      {yy}
                    </option>
                  )
                })}
              </Input>
              {CcInputField('card_cvc', 'CVC', 'tel', { required: true })}
            </InputGroup>
          </Col>
        </FormGroup>

        {props.errors.length > 0 ? (
          <div className="danger" role="alert">
            <div className="danger-color">{props.errors}</div>
          </div>
        ) : null}
      </Form>
    )
  }

  const confirmNet30 = (confirm = true) => {
    if (confirm) {
      props.dispatchSweetAlert({
        alertMessage:
          'By selecting this payment option I confirm that my order will be billed according to the net-payment terms established for this e-store.',
        showCancel: false,
        confirmBtnCssClass: 'mf-secure-co-btn',
        onConfirm: () => props.setNet30Confirmed(true)
      })
    } else {
      props.setNet30Confirmed(false)
    }
  }

  const shouldShowNet30 = props.customerGroup.id && props.customerGroup.net_30_payments

  // Determine the default tab based on whether the credit card is hidden and net 30 is available
  const defaultActiveKey = shouldShowNet30 && !props.customerGroup.hide_credit_card ? 'credit_card' : 'net30'

  if (props.customerGroup.hide_credit_card === true && shouldShowNet30) {
    return (
      <div className="m-0 box-shadow white-background">
        <div className="ckout-header">
          <h5 className="m-0">Payment Method</h5>
        </div>
        {shouldShowNet30 && (
          <Container className="mb-20">
            <Row>
              <Col xs="12" className="px-30">
                <Row
                  style={rowStyle}
                  className={
                    checked.net30 ? ' alert-success cart-flex pt-3 pb-3 border' : ' cart-flex pt-3 pb-3 border'
                  }
                  onClick={() => handleCheckBox('net30')}
                >
                  <Col sm="10">
                    <h6>{props.netTerm || 'Net 30'}</h6>
                  </Col>
                  <Col sm="2" style={colStyle}>
                    <CheckBox checked={checked.net30} onChange={() => handleCheckBox('net30')} />
                  </Col>
                </Row>
                {checked.net30 && (
                  <Row style={rowStyle} className="cart-flex pt-3 pb-3 border">
                    <Col>
                      <Label for="po_number">
                        <strong>
                          {props.netTermPoNumber ? `${props.netTermPoNumber} (Optional)` : 'P.O. Number (Optional):'}
                        </strong>
                      </Label>
                    </Col>
                    <Col sm={9}>
                      <Input name="po_number" value={props.poNumber} onChange={handlePoNumberChange} maxLength={25} />
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>
          </Container>
        )}
      </div>
    )
  } else {
    if (props.customerGroup.hide_credit_card === true && !shouldShowNet30) {
      return null
    } else {
      return (
        <div className="m-0 box-shadow white-background">
          <div className="ckout-header">
            <h5 className="m-0">Payment Method</h5>
          </div>
          <Tabs
            defaultActiveKey={defaultActiveKey}
            activeKey={props.paymentMethod}
            onChange={handleChangePaymentMethod}
            tabPosition="top"
            tabBarGutter={10}
            className="px-30 pb-30"
          >
            {!props.customerGroup.hide_credit_card && (
              <TabPane tab="Credit Card" key="credit_card" forceRender={true}>
                <Row className="creditcard-payment mx-0">
                  <Col xs={12} md={12} lg={6} className="mt-3 px-0">
                    <Cards
                      name={props.ccData.card_name}
                      cvc={props.ccData.card_cvc}
                      expiry={props.ccData.card_expiry}
                      focused={props.ccData.focus}
                      number={props.ccData.card_number}
                    />
                  </Col>
                  <Col xs={12} md={12} lg={6} className="px-0">
                    {CcForm()}
                  </Col>
                </Row>
              </TabPane>
            )}
            {shouldShowNet30 && (
              <TabPane tab={props.netTerm || 'Net 30'} key="net30">
                <Container>
                  <Row>
                    <Col sm={12} md={9} className="p-0">
                      <Label for="po_number">
                        <strong>
                          {props.netTermPoNumber ? `${props.netTermPoNumber} (Optional)` : 'P.O. Number (Optional):'}
                        </strong>
                      </Label>
                      <Input name="po_number" value={props.poNumber} onChange={handlePoNumberChange} maxLength={25} />
                    </Col>
                  </Row>
                </Container>
              </TabPane>
            )}
          </Tabs>
        </div>
      )
    }
  }
}

const mapStateToProps = state => {
  return {
    paymentMethod: state.checkout.paymentMethod,
    youPay: state.checkout.youPay,
    errors: state.checkout.errors,
    ccData: state.checkout.ccData,
    customerGroup: state.customerGroup,
    poNumber: state.checkout.poNumber,
    applied_promotions: state.applied_promotions,
    currentUser: state.currentUser,
    netTerm: state.netTerm.netTermDef,
    netTermPoNumber: state.netTerm.netTermPoNumber
  }
}

export default connect(mapStateToProps, {
  setPaymentMethod,
  setCcData,
  dispatchSweetAlert,
  setPoNumber,
  setNet30Confirmed,
  fetchNetTerm,
  fetchNetTermPoNumber
})(CheckoutCardPanel)
