import React from 'react'
import { AsyncPaginate as AsyncSelectPaginate } from 'react-select-async-paginate'

const SelectPaginate = props => {
  const loadLocationOptions = async (search, prevOptions, { page }) => {
    const response = await props.fetchData(search, page)

    return {
      options: response[props.data],
      hasMore: response.has_more,
      additional: {
        page: page + 1
      }
    }
  }

  const selectProps = {
    getOptionLabel: o => o.name,
    getOptionValue: o => o.id
  }

  return (
    <AsyncSelectPaginate
      {...props}
      {...selectProps}
      loadOptions={loadLocationOptions}
      additional={{
        page: 1
      }}
      style={{ zIndex: 1 }}
      styles={{
        control: base => ({
          ...base
          // maxWidth: '240px',
          // '@media (max-width: 768px)': {
          //   maxWidth: '170px',
          // },
        }),
        container: base => ({
          ...base
          // maxWidth: '240px',
          // '@media (max-width: 768px)': {
          //   maxWidth: '170px',
          // },
        }),
        menu: base => ({
          ...base,
          zIndex: 11
        }),
        option: base => ({
          ...base,
          color: 'black'
        })
      }}
    />
  )
}

export default SelectPaginate
