import { useState, useEffect } from 'react'
import Select from 'react-select'
import { Country, State, City } from 'country-state-city'

const StateProvinceSelect = ({ countryCode, handleChangeState, stateValue, placeholder }) => {
  const [statesOfCountry, setStatesOfCountry] = useState([])
  const [selectedState, setSelectedState] = useState({
    label: stateValue,
    value: stateValue,
    field: 'state'
  })

  useEffect(() => {
    handleChangeState(selectedState)
  }, [selectedState])

  useEffect(() => {
    setStatesOfCountry(State.getStatesOfCountry(countryCode))
  }, [countryCode])

  const statesOptions = statesOfCountry.map(state => {
    return {
      label: state.name,
      value: state.isoCode,
      field: 'state'
    }
  })

  return (
    <Select
      name="state"
      value={selectedState}
      isClearable={false}
      options={statesOptions}
      onChange={setSelectedState}
      placeholder={placeholder ? placeholder : 'Select...'}
    />
  )
}

export default StateProvinceSelect
