import { formatSingleProduct } from '../helpers/productPageFormatter'

const initialState = {
  products: [],
  page: '1',
  total_pages: '1',
  total_records: 0
}

export default (state = { products: [], page: '1', total_pages: '1' }, action) => {
  switch (action.type) {
    case 'FETCH_PAGINATED_PRODUCTS':
      return {
        products: formatProductsShopPage(action.payload.products),
        page: action.payload.page,
        total_pages: action.payload.total_pages,
        total_records: action.payload.total_records
      }

    case 'CLEAR_PAGINATED_PRODUCTS':
      return initialState

    default:
      return state
  }
}

const formatProductsShopPage = apiResponse => {
  // API response is an array of single-item arrays; map to the actual data

  // now we can pass each product to single-product formatter
  const result = apiResponse.map(productData => {
    return formatSingleProduct(productData, { forShopPage: true })
  })

  return result
}
