const initialState = {
  hidden_categories_list: [],
  hidden_products_list: [],
  name: 'Not logged in',
  id: -1
}

export default (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_CUSTOMER_GROUP':
      return action.payload
    case 'CLEAR_CUSTOMER_GROUP':
      return initialState
    default:
      return state
  }
}
