import { FETCH_CLIENT_ORDERS, UPDATE_ORDER } from '../actions/types'

export default (state = [], action) => {
  switch (action.type) {
    case FETCH_CLIENT_ORDERS:
      return action.payload
    case UPDATE_ORDER:
      return action.payload
    default:
      return state
  }
}
