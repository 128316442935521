import { useEffect } from 'react'
import { createSession, setLoading, showAlert } from '../../actions'
import { connect } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import LoadingSpinner from '../general/LoadingSpinner'

const SSoSignInPage = ({ createSession, currentLocation, setLoading }) => {
  let { sso_id, sso_token } = useParams()
  let history = useHistory()

  // wait for data to load, then create session using SSO ID/token from params
  useEffect(() => {
    if (sso_id && sso_token) {
      localStorage.setItem('authToken', sso_token)
      history.push('/')
      window.location.reload()
      // let sessionFormData = new FormData()
      // sessionFormData.append('sso_id', sso_id)
      // sessionFormData.append('jwt_token', sso_token)
      // createSession(sessionFormData)
      //   .then(resp => {
      //     setLoading('session', false)
      //     history.push('/')
      //   })
      //   .catch(err => {
      //     console.error('Error creating session with SSO ID/token')
      //   })
    }
  }, [sso_id, sso_token])

  return (
    <>
      <LoadingSpinner />
    </>
  )
}

const mapStateToProps = state => {
  return {
    currentLocation: state.currentLocation
  }
}

export default connect(mapStateToProps, {
  createSession,
  showAlert,
  setLoading
})(SSoSignInPage)
export { SSoSignInPage }
