import { Input, FormGroup, Label, ListGroup, ListGroupItem } from 'reactstrap'
import { connect } from 'react-redux'
import { setBundleProduct } from '../productPageFunctions'
import { useState, useEffect } from 'react'

const mapStateToProps = state => {
  return {
    productPage: state.productPage
  }
}

const SimpleGroup = ({ group, setBundleProduct, index }) => {
  // on mount, add these products to the bundle. They should always be there (simple group)
  useEffect(() => {
    setBundleProduct(group, group.products)
  }, [])

  return (
    <>
      {index === 0 ? null : ''}
      <h5>{group.bundle_group_name}</h5>
      <p className="">{group.bundle_group_description}</p>
      <ListGroup>
        {group.products.map(product => {
          return (
            <ListGroupItem key={product.bundle_group_product_id}>
              {product.product_name} (Qty: {product.product_quantity})
            </ListGroupItem>
          )
        })}
      </ListGroup>
    </>
  )
}
const ConnectedSimpleGroup = connect(mapStateToProps, { setBundleProduct })(SimpleGroup)

const SelectGroup = ({ group, setBundleProduct, index }) => {
  const [selectedBundleGroupProducts, setSelectedBundleGroupProducts] = useState({})

  const handleSelect = (bundleGroup, product) => {
    setSelectedBundleGroupProducts({
      ...selectedBundleGroupProducts,
      [product.bundle_group_product_id]: !selectedBundleGroupProducts[product.bundle_group_product_id]
    })
    setBundleProduct(bundleGroup, product)
  }

  // For Select Group in buildable bundle
  return (
    <>
      {index === 0 ? null : ''}
      <h5>
        {group.bundle_group_name} <span className="font-medium text-14">(Choose Any)</span>
      </h5>
      <p>{group.bundle_group_description}</p>
      <ListGroup>
        {group.products.map(product => {
          return (
            <ListGroupItem key={product.bundle_group_product_id} onClick={() => handleSelect(group, product)}>
              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    className="custom-checkbox"
                    checked={selectedBundleGroupProducts[product.bundle_group_product_id]}
                  />{' '}
                  {product.product_name} (Qty: {product.product_quantity})
                </Label>
              </FormGroup>
            </ListGroupItem>
          )
        })}
      </ListGroup>
    </>
  )
}
const ConnectedSelectGroup = connect(mapStateToProps, { setBundleProduct })(SelectGroup)

const RadioGroup = ({ group, setBundleProduct, index }) => {
  const [checked, setChecked] = useState(-1)
  const handleRadioSelect = (bundleGroup, product, fieldName) => {
    setChecked(product.bundle_group_product_id)
    setBundleProduct(bundleGroup, product)
  }

  // For Radio Group in buildable bundle
  return (
    <>
      {index === 0 ? null : ''}
      <h5>
        {group.bundle_group_name} <span className="font-medium text-14">(Choose One)</span>
      </h5>
      <p>{group.bundle_group_description}</p>
      <ListGroup>
        {group.products.map(product => {
          return (
            <ListGroupItem
              key={product.bundle_group_product_id}
              onClick={() => handleRadioSelect(group, product, `radio_bundle_group_${group.bundle_group_id}`)}
            >
              <FormGroup check>
                <Label check>
                  <Input type="radio" className="custom-radio" checked={checked === product.bundle_group_product_id} />{' '}
                  <span>
                    {product.product_name} (Qty: {product.product_quantity})
                  </span>
                </Label>
              </FormGroup>
            </ListGroupItem>
          )
        })}
      </ListGroup>
    </>
  )
}
const ConnectedRadioGroup = connect(null, { setBundleProduct })(RadioGroup)

// render appropriate group according to type
const resolveGroup = (group, i) => {
  switch (group.bundle_group_type) {
    case 'simple_group':
      return <ConnectedSimpleGroup group={group} index={i} />
    case 'radio_group':
      return <ConnectedRadioGroup group={group} index={i} />
    case 'select_group':
      return <ConnectedSelectGroup group={group} index={i} />
    default:
      break
  }
}

const BundleGroups = props => {
  return props.product.bundle.bundleGroups.map((group, i) => {
    return (
      <div class="description-container" key={group.bundle_group_id}>
        {resolveGroup(group, i)}
      </div>
    )
  })
}

export default BundleGroups
