const initialState = {
  gift_card_credit: {
    remaining: 0
  },
  credit_note: {
    remaining: 0
  },
  coop_credit: {
    remaining: 0,
    percentage: 0
  },
  lms_points: {
    remaining: 0
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_USER_CREDIT':
      return action.payload
    case 'CLEAR_USER_CREDIT':
      return initialState
    default:
      return state
  }
}
