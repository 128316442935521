// import { version } from '../package.json'

export const STORE_APP_VERSION = require('../package.json').version

let Auth0Credentials = {}
const credentialsString = process.env.REACT_APP_AUTH0_CREDENTIALS
if (!credentialsString) {
  console.error('Auth0 credentials not found')
}
Auth0Credentials = JSON.parse(process.env.REACT_APP_AUTH0_CREDENTIALS)
export const REACT_APP_STORE_AUTH0_DOMAIN = Auth0Credentials.STORE_AUTH0_DOMAIN
export const REACT_APP_STORE_AUTH0_CLIENT_ID = Auth0Credentials.STORE_AUTH0_CLIENT_ID
