// resolve which page should be the home page for given location/pages
export const resolveHomePage = (pages, locationId) => {
  // default home page will not be location specific (no location_id)
  const defaultHomePage = pages.find(page => page.page_type === 'Home' && page.location_id === null)
  const locationHomePage = pages.find(
    page => page.page_type === 'Home' && page.location_id === locationId && page.visible === true
  )
  if (locationHomePage) {
    return locationHomePage
  } else if (defaultHomePage) {
    return defaultHomePage
  } else {
    console.error('No home page found for location ' + locationId)
    return {}
  }
}
