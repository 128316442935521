import React, { useState } from 'react'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

const FaqBlock = props => {
  const [expanded, setExpanded] = useState(false)

  // define toggle  function for accordion
  const handleChange = panel => (event, expanded) => {
    setExpanded(expanded ? panel : false)
  }

  return (
    <div>
      <div className="iron-sec-heading-wrap text-center mb-40">
        <div className="heading-title">
          <h2>{props.content.title}</h2>
        </div>
        {props.content.child_content.summary === 'null' ? '' : props.content.child_content.summary}
      </div>
      {props.content.child_content.items.map(item => {
        return (
          <ExpansionPanel
            expanded={expanded == 'panel' + item.id}
            onChange={handleChange('panel' + item.id)}
            className="iron-accordion-wrap"
          >
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} className="iron-accordion-title">
              <h5 className="mb-0">{item.question}</h5>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className="iron-accordion-desc">
              <p>{item.answer}</p>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        )
      })}
    </div>
  )
}

export default FaqBlock
