import { connect } from 'react-redux'
import { clearCart } from '../../actions'
import { Button } from 'reactstrap'
import { dispatchSweetAlert } from '../../actions'
import { Trash2 } from 'react-feather'

const ClearCartButton = props => {
  const handleClick = () => {
    // show sweetalert for confirmation,
    // passing in clear cart function callback

    const propsForSweetalert = {
      onConfirm: () => props.clearCart(props.currentLocation.id),
      confirmBtnText: 'Yes, clear my cart',
      title: 'Remove all items from your cart?',
      alertMessage: 'You can not undo this action.',
      warning: true,
      alertType: 'warning',
      confirmBtnCssClass: 'mf-primary-btn alert-width-btn',
      cancelBtnCssClass: 'mf-outline-btn alert-width-btn'
    }

    props.dispatchSweetAlert(propsForSweetalert)
  }

  return (
    <div className="w-100 m-0 text-right">
      <sub
        className="red-text"
        style={{ textDecoration: 'underline', cursor: 'pointer' }}
        onClick={handleClick}
        disabled={!!props.errorMessage}
      >
        Clear Cart
      </sub>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    currentLocation: state.currentLocation
  }
}

export default connect(mapStateToProps, { clearCart, dispatchSweetAlert })(ClearCartButton)
